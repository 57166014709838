import React from 'react';
import { Link } from 'gatsby';
import { Banner } from 'components';
import { FOOTER_BANNER_ATTRIBUTES } from 'config';
import SVGScrollArrowRight from '../../../public/svg/scroll-arrow-right.svg';

const FooterBanner = () => (
  <Link to={FOOTER_BANNER_ATTRIBUTES.link} style={{ textDecoration: 'none' }}>
    <Banner
      backgroundImage={FOOTER_BANNER_ATTRIBUTES.backgroundImage}
      modifier={`c-banner--sm ${FOOTER_BANNER_ATTRIBUTES.modifier.join(' ')}`}
    >
      <div className="c-heading c-heading--h1">{FOOTER_BANNER_ATTRIBUTES.title}</div>
      <p>{FOOTER_BANNER_ATTRIBUTES.subtitle}</p>
      <div
        className="mb-2"
        dangerouslySetInnerHTML={{ __html: FOOTER_BANNER_ATTRIBUTES.customElements }}
      />
      <SVGScrollArrowRight />
    </Banner>
  </Link>
);

export default FooterBanner;
