/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { Modal } from 'components';
import moment from 'moment';
// import Calendar from 'react-calendar';
import DayPicker from 'react-day-picker';
import { ClipLoader } from 'react-spinners';
// import 'react-calendar/dist/Calendar.css';
import 'react-day-picker/lib/style.css';
import {
  PAGES,
} from 'config';
import {
  mapStateToProps,
} from 'utilities';
import { CHECKING_AVAILABILITY } from 'actions/booking';

class CalendarModal extends PureComponent {

  constructor() {
    super();

    this.onDateSelected = this.onDateSelected.bind(this);
    this.onCheckAvailabilityClick = this.onCheckAvailabilityClick.bind(this);
    this.state = {
      selectedDate: new Date(),
      checkingAvailability: false,
      departure: null,
      available: null,
      message: '',
    };
  }

  onDateSelected(date) {
    this.setState({ selectedDate: date });
  }

  onCheckAvailabilityClick() {
    const { tourId } = this.props;
    const { selectedDate } = this.state;

    this.setState({
      checkingAvailability: true,
    }, async () => {
      const DEPARTURE = await CHECKING_AVAILABILITY(
        tourId,
        moment(selectedDate).format('D/MM/YYYY'),
      );
      if (DEPARTURE && DEPARTURE.success) {
        if (DEPARTURE.data['available']) {
          this.setState({
            departure: DEPARTURE.data['departure'],
            checkingAvailability: false,
            available: 'available',
            message: `Done ! ${DEPARTURE.data['message']}`
          })
          window.location.href = `${PAGES.BOOKINGS.PATH}?departureId=${DEPARTURE.data['departure']._id}`;
        } else {
          this.setState({
            checkingAvailability: false,
            available: 'unavailable',
            message: `Sorry ! ${DEPARTURE.data['message']}`
          })
        }
      }
    });
  }

  render() {
    const {
      open, handleClose, inactiveDays, tourSlug,
    } = this.props;

    const {
      checkingAvailability,
      departure,
      selectedDate,
      message,
      available,
    } = this.state;

    const value = selectedDate;

    const highlighted_date = [];

    const birthdayStyle = `.DayPicker-Day--highlighted {
      background-color: green;
      color: white;
    }`;

    const inactive_range = inactiveDays["date_range"];

    inactive_range.map(item => (
      highlighted_date.push({
        from: new Date(item["from"]),
        to: new Date(item["to"]),
      })
    ))

    highlighted_date.push({
      daysOfWeek: inactiveDays["offline_days"]
    })

    highlighted_date.push({
      before: new Date(inactiveDays["before_date"])
    })

    const modifiers = {
      // highlighted: highlighted_date,
      disabled: highlighted_date,
    };

    return (
      <Modal
        open={open}
        closeHandler={handleClose}
      >
        <div className="c-calendar-modal">
          <style>{birthdayStyle}</style>
          <DayPicker
            onDayClick={this.onDateSelected}
            selectedDays={value}
            modifiers={ modifiers }
            // disabledDays={ modifiers }
          />
          {checkingAvailability && (
            <div className="c-calendar-modal__spinner">
              <div> 
                We're creating departure for you ...
              </div>
              <ClipLoader
                sizeUnit="px"
                size={50}
                color="#123abc"
              />
            </div>
          )}
          <div className="center">
            Select your travel date above.
          </div>
          {tourSlug === 'the-sounds' && (
            <div className="pink-center"> This tour departs from Nov - April only </div>
          )}
          {tourSlug === 'ride-the-rails' && (
            <div className="pink-center"> This tour departs from Oct - May only </div>
          )}
          {tourSlug === 'ride-the-rails-south' && (
            <div className="pink-center"> This tour departs from Oct - May only </div>
          )}
          <div className="green-center">
            Green means 100% guaranteed!
          </div>
          {!departure && !checkingAvailability && (
            <div>
              <button
                type="button"
                className="c-button c-button--primary c-button--block c-side-box__book-btn c-calendar-modal__button"
                onClick={this.onCheckAvailabilityClick}
              >
                Book
              </button>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

CalendarModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  tourId: PropTypes.string.isRequired,
  tourSlug: PropTypes.string.isRequired,
  inactiveDays: PropTypes.any.isRequired,
};

export default connect(mapStateToProps)(CalendarModal);
