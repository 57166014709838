import React from 'react';
import PropTypes from 'prop-types';
// import Masonry from 'react-masonry-component';
import { CrowdRiffGallery } from 'react-crowdriff-gallery';

import { SOCIAL } from 'config';
// import { InstagramCard } from 'components';


const InstagramList = ({
  title, subTitle, hash, modifier,
}) => (
  <section className={`l-instagram-list ${modifier}`}>
    <div className="l-container">
      <div className="l-instagram-list__header">
        <a
          className="l-instagram-list__follow-link"
          href={SOCIAL.INSTAGRAM.URL}
          target="_blank"
          rel="noreferrer noopener"
        >
          Follow us
          <span className="c-icon">
            {SOCIAL.INSTAGRAM.ICON}
          </span>
        </a>

        <h2 className="c-heading c-heading--h2">
          {title}
        </h2>

        {subTitle && (
          <p>
            <div dangerouslySetInnerHTML={{ __html: subTitle }} />
          </p>
        )}
      </div>

      {/* <Masonry
        className="l-instagram-list__grid"
        elementType="div"
        disableImagesLoaded={false}
        updateOnEachImageLoad={false}
        options={{
          gutter: GRID_GUTTER_WIDTH,
          transitionDuration: 0,
        }}
      >
        {list.map(item => (
          <InstagramCard
            key={item}
            size={item}
          />
        ))}
      </Masonry> */}
      <div className="l-instagram-list__wrapper">
        <CrowdRiffGallery hash={hash} />
      </div>
      <div className="l-instagram-list__footer">
        <a
          href={SOCIAL.INSTAGRAM.URL}
          target="_blank"
          className="c-button c-button--border c-button--wide"
          rel="noopener noreferrer"
        >
          View more on instagram
        </a>
      </div>
    </div>
  </section>
);

InstagramList.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  hash: PropTypes.string,
  modifier: PropTypes.string,
};

InstagramList.defaultProps = {
  title: 'Our latest #HakaTours adventures',
  subTitle: '',
  hash: '',
  modifier: '',
};

export default InstagramList;
