import React from 'react';
import PropTypes from 'prop-types';

const PromoBanner = ({
  children,
  backgroundImage,
  modifier,
}) => (
  <section
    className={`c-promo-banner ${modifier}`}
    style={{ backgroundImage: `url('${backgroundImage}')` }}
  >
    <div className="l-container">
      <div className="c-promo-banner__inner">
        {children}
      </div>
    </div>
  </section>
);

PromoBanner.propTypes = {
  children: PropTypes.node,
  backgroundImage: PropTypes.string,
  modifier: PropTypes.string,
};

PromoBanner.defaultProps = {
  children: null,
  backgroundImage: '',
  modifier: '',
};

export default PromoBanner;
