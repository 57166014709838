import React from 'react';
import PropTypes from 'prop-types';

import SVGCross from '../../../public/svg/cross.svg';

const SummaryList = ({ title, items }) => (
  <div className="c-summary-list">
    {
      title ? (
        <h5 className="c-summary-list__title">
          {title}
        </h5>
      ) : ''
    }
    <ul className="c-summary-list__list u-reset-list">
      {
        items.sort((a, b) => a.id - b.id).map(item => (
          <li key={item.id}>
            {
              item.removeBtnCallback
                ? (
                  <button onClick={item.removeBtnCallback} type="button" className="c-summary-list__remove-btn u-reset-btn">
                    <span className="u-sr-only">Remove</span>
                    <SVGCross />
                  </button>
                ) : ''
            }
            <h5 className="c-summary-list__item-title">
              {item.title}
            </h5>
            <h6 className="c-summary-list__item-content c-heading c-heading--h5">
              {item.content}
            </h6>
          </li>
        ))
      }
    </ul>
  </div>
);

SummaryList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array.isRequired,
};

SummaryList.defaultProps = {
  title: '',
};

export default SummaryList;
