import React from 'react';
import moment from 'moment';

import { PAGES, CONTACT } from 'config';

const BasicFooter = () => (
  <footer className="c-basic-footer">
    <div className="l-container">
      <p className="c-basic-footer__help u-hide-screen-large-up">Need help? Call&nbsp; <br />
        <a href={`tel:${CONTACT.PHONE}`}>{ CONTACT.PHONE }</a>
        &nbsp;or&nbsp;
        <button
          type="button"
          className="u-reset-btn"
          onClick={(e) => {
            e.preventDefault();
            // This interacts a global JS chat widget: https://www.jivochat.com/api/
            // eslint-disable-next-line
            if (jivo_api && typeof jivo_api.open === 'function') {
              jivo_api.open(); // eslint-disable-line
            }
          }}
        >Live Chat
        </button>
      </p>
      <ul>
        <li>
          {`© ${moment().year()} Copyright Haka Tours`}
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={PAGES.PRIVACY.PATH}
          >
            {PAGES.PRIVACY.TITLE}
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={PAGES.TERMS.PATH}
          >
            {PAGES.TERMS.TITLE}
          </a>
        </li>
        <li>
          <a
            href="https://www.littlegiant.co.nz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Solution by Little Giant
          </a>
        </li>
      </ul>
    </div>
  </footer>
);

export default BasicFooter;
