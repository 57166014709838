import React, { PureComponent } from 'react';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { ClipLoader } from 'react-spinners';

import { PAGES } from 'config';
import { mapStateToProps, RESPONSE_ERROR_FORMATTER } from 'utilities';
import { FormInput, withFormGroup } from 'components';
import {
  CREATE_USER,
  SIGN_IN_USER,
  GET_USER,
  SIGN_IN_USER_FACEBOOK,
} from 'actions/user';

class SignUpForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      optInNewsLetterSelected: false,
      loading: false,
      redirectURL: PAGES.USER.PATH, // default redirect
    };

    // get redirect URL if exists
    if (typeof window !== 'undefined') {
      const PARSED = queryString.parse(window.location.search);
      if (PARSED.return) {
        this.state.redirectURL = PARSED.return;
      }
    }
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFacebookRegister = this.handleFacebookRegister.bind(this);
    this.handleSignInLink = this.handleSignInLink.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
  }

  onOptionChange(e) {
    const CHECKED = e.currentTarget.checked;
    this.setState({
      optInNewsLetterSelected: CHECKED, // always set to selected
    });
  }

  /**
   * Handle sign up form submit event
   * @param e
   */
  handleFormSubmit(e) {
    e.preventDefault();
    const FORM = e.currentTarget;
    if (FORM instanceof HTMLFormElement === false || !FORM.checkValidity()) return;
    const FORM_DATA = new FormData(FORM);
    this.createUser(FORM_DATA);
  }

  /**
   * Handle successful sign in
   * @param signIn
   */
  handleSuccess(signIn) {
    const { redirectURL } = this.state;
    const { successHook, dispatch } = this.props;
    (async () => {
      const GET = await GET_USER(dispatch, signIn.data.session.token, signIn.data.session.email);
      if (GET && GET.success) {
        dispatch({
          type: 'MESSAGES_ADD',
          payload: {
            id: 'make-payment',
            type: 'success',
            content: 'Your registration is successful',
          },
        });
        setTimeout(() => {
          dispatch({
            type: 'MESSAGES_REMOVE',
            payload: 'make-payment',
          });
        }, 7000);
        if (successHook && typeof successHook === 'function') {
          successHook();
        } else {
          navigate(redirectURL);
        }
      } else {
        this.setState({
          errors: RESPONSE_ERROR_FORMATTER(GET.data.errors),
        });
      }
    })();
  }

  handleSignInLink(e) {
    e.preventDefault();
    const { redirectURL } = this.state;
    const { signInHook } = this.props;
    if (signInHook && typeof signInHook === 'function') {
      signInHook();
      // prevent default redirect from being passed around
    } else if (redirectURL !== PAGES.USER.PATH) {
      navigate(`${PAGES.SIGN_IN.PATH}?return=${encodeURIComponent(redirectURL)}`);
    } else {
      navigate(PAGES.SIGN_IN.PATH);
    }
  }

  /**
   * Handle register Facebook
   * @param e
   */
  handleFacebookRegister(e) {
    e.preventDefault();
    const { dispatch, user } = this.props;
    if (!FB) return;
    // 1. Login with FB
    FB.login((response) => {
      if (response.authResponse) {
        this.setState({
          loading: true,
        });
        // 2. Request FB data
        FB.api('/me?fields=id,email,first_name,last_name', async (FBuser) => {
          // 3. Sign in user with cred (and save to store)
          const SIGN_IN = await SIGN_IN_USER_FACEBOOK(dispatch, response.authResponse.accessToken, FBuser.email, FBuser.first_name, user.session);
          if (!SIGN_IN || !SIGN_IN.success) {
            this.setState({
              errors: RESPONSE_ERROR_FORMATTER(SIGN_IN.data.errors),
            });
            return;
          }

          // Success!
          this.handleSuccess(SIGN_IN);
        });
      }
    }, { scope: 'email' });
  }

  /**
   * Create a new user
   * @param formData
   */
  createUser(formData) {
    const { dispatch, user } = this.props;
    const { optInNewsLetterSelected } = this.state;
    (async () => {
      // 1. Create user
      const CREATE = await CREATE_USER(
        formData.get('FirstName'),
        formData.get('Email'),
        formData.get('Password'),
        optInNewsLetterSelected,
        user.session,
      );
      if (!CREATE || !CREATE.success) {
        this.setState({
          errors: RESPONSE_ERROR_FORMATTER(CREATE.data.errors),
        });
        return;
      }

      // 2. Sign in user
      const SIGN_IN = await SIGN_IN_USER(dispatch, formData.get('Email'), formData.get('Password'));
      if (!SIGN_IN || !SIGN_IN.success) {
        this.setState({
          errors: RESPONSE_ERROR_FORMATTER(SIGN_IN.data.errors),
        });
        return;
      }

      // Success!
      this.handleSuccess(SIGN_IN);
    })();
  }

  render() {
    const { errors, optInNewsLetterSelected, loading } = this.state;
    const Input = withFormGroup(FormInput);
    return (
      <div className="l-sign-up-form">
        <div className="l-container l-container--small">
          <div className="l-sign-up-form__header">
            <h1 className="c-heading c-heading--h2">Create a new account</h1>
            <p>Create an account to book a tour or save activities to your favourites</p>
          </div>
          <div className="l-sign-up-form__wrapper">
            <form className="l-form" action="" onSubmit={this.handleFormSubmit}>
              <ul className="l-form__errors">
                {errors.map(error => (
                  <li key={error} className="u-text--center">
                    {error}
                  </li>
                ))}
              </ul>
              <div className="l-form__contents">
                <Input
                  type="text"
                  name="FirstName"
                  id="InputFirstName"
                  placeholder="First name"
                  label="First name"
                  srOnly
                  required
                />
                <Input
                  type="email"
                  name="Email"
                  id="InputEmail"
                  placeholder="Email"
                  label="Email"
                  srOnly
                  required
                />
                <Input
                  type="password"
                  name="Password"
                  id="InputPassword"
                  placeholder="Password"
                  label="Password"
                  minLength={8}
                  srOnly
                  required
                />
                <div className="l-form-group l-form-group--checkbox">
                  <FormInput
                    type="checkbox"
                    id="InputOptInNewsLetter"
                    name="OptInNewsLetter"
                    modifier="c-form-control--checkbox"
                    onChange={this.onOptionChange}
                    attrs={{
                      checked: optInNewsLetterSelected,
                    }}
                  />
                  <label htmlFor="OptIn" className="l-form-group__label">
                    Sign up to get the latest Haka news and offers
                  </label>
                </div>
              </div>
              <div className="l-form__actions">
                <button
                  className="c-button c-button--wide c-button--primary"
                  type="submit"
                >
                  Register
                </button>
              </div>
            </form>
            <h5 className="l-sign-up-form__or">OR</h5>
            {loading && (
              <div className="loading-screen">
                <ClipLoader
                  sizeUnit="px"
                  size={50}
                  color="#123abc"
                />
              </div>
            )}
            {!loading && (
              <button
                type="button"
                className="c-button c-button--wide c-button--facebook"
                onClick={this.handleFacebookRegister}
              >
                Register With Facebook
              </button>
            )}
            <hr />
            <h3 className="c-heading c-heading--h3 u-text--center">Already have an account?</h3>
            <button
              type="button"
              onClick={this.handleSignInLink}
              className="c-button c-button--wide c-button--border l-sign-up-form__sign-in-link"
            >
              Sign In
            </button>
          </div>
        </div>
      </div>
    );
  }
}

SignUpForm.propTypes = {
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  signInHook: PropTypes.func,
  successHook: PropTypes.func,
};

SignUpForm.defaultProps = {
  signInHook: null,
  successHook: null,
};

export default connect(mapStateToProps)(SignUpForm);
