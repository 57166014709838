export default [
  {
    id: 'Mr',
    value: 'Mr',
    name: 'Mr',
  },
  {
    id: 'Ms',
    value: 'Ms',
    name: 'Ms',
  },
  {
    id: 'Mrs',
    value: 'Mrs',
    name: 'Mrs',
  },
  {
    id: 'Miss',
    value: 'Miss',
    name: 'Miss',
  },
  {
    id: 'Dr',
    value: 'Dr',
    name: 'Dr',
  },
];
