import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link, StaticQuery } from 'gatsby';

import { PAGES } from 'config';
import { FORMAT_GRAPH_DATA } from 'utilities';
import {
  ActivityCard,
  Breadcrumbs,
  FormSelect,
  withFormGroup,
} from 'components';
import { Element } from 'react-scroll/modules';


const SORT_OPTIONS = {
  POPULAR: {
    title: 'Popular',
    value: 'POPULAR',
  },
  PRICE_HIGH: {
    title: 'Price: High-low',
    value: 'PRICE_HIGH',
  },
  PRICE_LOW: {
    title: 'Price: Low-high',
    value: 'PRICE_LOW',
  },
  DURATION_LONGEST: {
    title: 'Longest duration',
    value: 'DURATION_LONGEST',
  },
  DURATION_SHORTEST: {
    title: 'Shortest duration',
    value: 'DURATION_SHORTEST',
  },
};

const FIELDS = {
  CATEGORY: {
    ID: 'TourCategory',
    OPTIONS: [],
  },
  LOCATION: {
    ID: 'TourLocation',
    OPTIONS: [],
  },
  SORT: {
    ID: 'TourSort',
    OPTIONS: Object.values(SORT_OPTIONS).map((option, i) => ({
      ...option,
      id: i,
      title: `Sort by: ${option.title}`,
    })),
  },
};

class ActivityList extends PureComponent {
  /**
   * Initial state values
   */
  static values() {
    const {
      CATEGORY, LOCATION, SORT,
    } = FIELDS;

    return {
      [CATEGORY.ID]: '',
      [LOCATION.ID]: '',
      [SORT.ID]: SORT_OPTIONS.POPULAR.value,
    };
  }


  /**
   * Constructor
   */
  constructor() {
    super();

    this.onChange = this.onChange.bind(this);

    this.state = {
      values: this.constructor.values(),
    };
  }


  /**
   * On change event
   */
  onChange(value) {
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        ...value,
      },
    }));
  }


  /**
   * Sort the list of tours
   *
   * @param list
   */
  sortList(list) {
    const { values } = this.state;
    const { SORT } = FIELDS;
    const {
      POPULAR,
      PRICE_HIGH,
      PRICE_LOW,
      DURATION_LONGEST,
      DURATION_SHORTEST,
    } = SORT_OPTIONS;

    list.sort((a, b) => {
      switch (values[SORT.ID]) {
        case POPULAR.value: {
          return a.order - b.order;
        }
        case PRICE_HIGH.value: {
          return b.options[0].price - a.options[0].price;
        }
        case PRICE_LOW.value: {
          return a.options[0].price - b.options[0].price;
        }
        case DURATION_LONGEST.value: {
          return b.duration - a.duration;
        }
        case DURATION_SHORTEST.value: {
          return a.duration - b.duration;
        }
        default: {
          return a.order - b.order;
        }
      }
    });
  }


  /**
   * Filter the list of tours
   */
  filterList(destinations) {
    const { list, showFilters } = this.props;
    const { values } = this.state;
    const {
      CATEGORY,
      LOCATION,
      SORT,
    } = FIELDS;

    let filteredList = list;

    if (showFilters) {
      if (values[CATEGORY.ID]) {
        filteredList = filteredList.filter(item => item.addon_category_ids.includes(values[CATEGORY.ID]));
      }

      if (values[LOCATION.ID]) {
        const destination = destinations.find(item => item.api_id === values[LOCATION.ID]);

        if (destination) {
          filteredList = filteredList.filter(item => destination.addon_ids.includes(item.api_id));
        }
      }

      if (values[SORT.ID]) {
        this.sortList(filteredList);
      }
    }

    return filteredList;
  }


  /**
   * Render
   */
  render() {
    const { values } = this.state;
    const Select = withFormGroup(FormSelect);
    const {
      title,
      subTitle,
      limit,
      breadcrumbs,
      showFilters,
      showFooter,
    } = this.props;

    const {
      CATEGORY,
      LOCATION,
      SORT,
    } = FIELDS;

    return (
      <StaticQuery
        query={graphql`
          query {
            allActivityCategories {
              edges {
                node {
                  id
                  api_id
                  name
                }
              }
            }

            allDestinationPages {
              edges {
                node {
                  id
                  api_id
                  name
                  addon_ids
                }
              }
            }
          }
        `}
        render={(data) => {
          const destinations = FORMAT_GRAPH_DATA(data.allDestinationPages);

          const list = this.filterList(destinations);

          if (limit && limit < list.length) {
            list.length = limit; // eslint-disable-line
          }

          return (
            <Element
              name={ActivityList.name}
              className="l-activity-list"
            >
              <div className="l-container">
                <Breadcrumbs list={breadcrumbs} />

                {title && (
                  <div className="l-activity-list__header">
                    <h1 className="c-heading c-heading--h2">
                      {title}
                    </h1>

                    {subTitle && (
                      <p>
                        {subTitle}
                      </p>
                    )}
                  </div>
                )}

                {showFilters && (
                  <div className="l-tour-list__filters">
                    <div className="l-tour-list__filters-grid">
                      <h3 className="c-heading c-heading--h3">Filter all activities</h3>

                      <Select
                        label="Types"
                        placeholder="All types"
                        id={CATEGORY.ID}
                        name={CATEGORY.ID}
                        options={FORMAT_GRAPH_DATA(data.allActivityCategories)}
                        value={values[CATEGORY.ID]}
                        onChange={this.onChange}
                        srOnly
                      />

                      <Select
                        label="Locations"
                        placeholder="All locations"
                        id={LOCATION.ID}
                        name={LOCATION.ID}
                        options={destinations}
                        value={values[LOCATION.ID]}
                        onChange={this.onChange}
                        srOnly
                      />

                      <Select
                        label="Sort by"
                        id={SORT.ID}
                        name={SORT.ID}
                        options={FIELDS.SORT.OPTIONS}
                        value={values[SORT.ID]}
                        onChange={this.onChange}
                        srOnly
                      />
                    </div>
                  </div>
                )}

                {!list.length && (
                  <h4 className="c-heading c-heading--h4 u-text--center">No matching activities</h4>
                )}

                <div className="l-grid l-grid--4">
                  {list.map(item => (
                    <ActivityCard
                      key={item.id}
                      data={item}
                    />
                  ))}
                </div>

                {showFooter && (
                  <div className="l-activity-list__footer">
                    <Link
                      to={PAGES.ACTIVITIES.PATH}
                      className="c-button c-button--primary c-button--wide"
                    >
                      View all activities
                    </Link>
                  </div>
                )}
              </div>
            </Element>
          );
        }}
      />
    );
  }
}

ActivityList.propTypes = {
  list: PropTypes.array.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  limit: PropTypes.number,
  breadcrumbs: PropTypes.array,
  showFilters: PropTypes.bool,
  showFooter: PropTypes.bool,
};

ActivityList.defaultProps = {
  title: null,
  subTitle: null,
  limit: null,
  breadcrumbs: null,
  showFilters: false,
  showFooter: false,
};

export default ActivityList;
