import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { STATES } from 'config';
import SVGArrow from '../../../public/svg/sub-nav-arrow.svg';


const SubNavBasic = ({
  list,
  title,
  children,
  isActive,
  onBackClick,
  onMenuClick,
}) => {
  if (!list.length) return null;

  return (
    <div className={`c-sub-nav-basic ${isActive ? STATES.ACTIVE : ''}`}>
      <button
        type="button"
        className="c-sub-nav__back"
        onClick={onBackClick}
      >
        <SVGArrow />
        Back
      </button>

      {title && (
        <div className="c-sub-nav__title">
          {title}
        </div>
      )}

      <ul className="c-sub-nav-basic__list">
        {list.map(item => (
          <li
            key={item.id}
            className="c-sub-nav-basic__item"
          >
            <Link
              to={`${item.absolute_slug}`}
              className="c-sub-nav-basic__link"
              onClick={onMenuClick}
            >
              {item.name}
            </Link>
          </li>
        ))}
        {children}
      </ul>
    </div>
  );
};

SubNavBasic.propTypes = {
  list: PropTypes.array.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  isActive: PropTypes.bool,
  onBackClick: PropTypes.func,
  onMenuClick: PropTypes.func,
};

SubNavBasic.defaultProps = {
  title: null,
  children: null,
  isActive: false,
  onBackClick: () => {},
  onMenuClick: () => {},
};

export default SubNavBasic;
