import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import ModalVideo from 'react-modal-video';

import { Slide } from 'components';


const GALLERY_OPTIONS = {
  containerClass: 'c-thumbnail-gallery__gallery',
  speed: 500,
  roundLengths: true,
  simulateTouch: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'fraction',
    renderFraction: (currentClass, totalClass) => `
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
        <g fill="none" fill-rule="evenodd" stroke="#4A4A4A">
          <rect width="16.5" height="11.5909" x=".75" y="3.2045" stroke-width="1.5" rx="2" />
          <circle cx="7.3636" cy="9" r="4.0909" stroke-width="1.5" />
          <path d="M12.7727 5.4091h2.2727v1h-2.2727z" />
          <path stroke-width="1.5" d="M6.4773.75h5.0455v2.5909H6.4773z" />
        </g>
      </svg>
      <span class="${currentClass}"></span>
      <span>/</span>
      <span class="${totalClass}"></span>
    `,
  },
};

const THUMBNAIL_OPTIONS = {
  containerClass: 'c-thumbnail-gallery__thumbnails',
  centeredSlides: true,
  slidesPerView: 'auto',
  speed: 500,
  touchRatio: 0.2,
  simulateTouch: true,
  virtualTranslate: true,
  roundLengths: true,
  slideToClickedSlide: true,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
};


class Gallery extends Component {
  /**
   * Constructor
   */
  constructor() {
    super();

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.galleryRef = this.galleryRef.bind(this);
    this.thumbnailRef = this.thumbnailRef.bind(this);

    this.state = {
      channel: '',
      videoId: '',
      isOpen: false,
      gallerySwiper: null,
      thumbnailSwiper: null,
    };
  }


  /**
   * Lifecycle methods
   */
  componentWillUpdate(nextProps, nextState) {
    const { showThumbnails } = nextProps;
    const { gallerySwiper, thumbnailSwiper } = nextState;

    if (
      gallerySwiper
      && thumbnailSwiper
      && showThumbnails
      && gallerySwiper.controller
      && thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;
    }
  }


  /**
   * Open the video modal
   */
  openModal(channel, videoId) {
    this.setState({
      channel,
      videoId,
      isOpen: true,
    });
  }


  /**
   * Close the video modal
   */
  closeModal() {
    this.setState({ isOpen: false });
  }


  /**
   * Gallery slider ref
   */
  galleryRef(ref) {
    if (ref) {
      this.setState({ gallerySwiper: ref.swiper });
    }
  }


  /**
   * Thumbnail slider ref
   */
  thumbnailRef(ref) {
    if (ref) {
      this.setState({ thumbnailSwiper: ref.swiper });
    }
  }


  /**
   * Render
   */
  render() {
    const { channel, videoId, isOpen } = this.state;
    const {
      slides,
      category,
      showThumbnails,
      showPagination,
      linkTo,
    } = this.props;

    if (!slides || !slides.length) return null;

    if (slides.length > 10) {
      slides.length = 10; // eslint-disable-line
    }

    if (!showPagination) {
      GALLERY_OPTIONS.pagination = {};
    }

    const slideArray = slides.filter(item => item.imageUrl || item.videoUrl);

    return (
      <div className="c-thumbnail-gallery">
        <div className={`${GALLERY_OPTIONS.containerClass}-wrapper`}>
          <Swiper
            {...GALLERY_OPTIONS}
            navigation={slideArray.length === 1 ? {} : GALLERY_OPTIONS.navigation}
            ref={this.galleryRef}
            rebuildOnUpdate={!showThumbnails}
          >
            {slideArray.map(slide => (
              <Slide
                key={`gallery-${slide.id}`}
                title={slide.title}
                imageUrl={slide.imageUrl}
                videoUrl={slide.videoUrl}
                onPlayClick={this.openModal}
                linkTo={linkTo}
              />
            ))}
          </Swiper>

          {category && (
            <div
              className="c-tour-card__category"
              style={{ backgroundColor: category.background_color }}
            >
              <div
                className="c-tour-card__category-angle"
                style={{ borderLeftColor: category.background_color }}
              />
              {category.category_icon && (
                <div
                  className="c-tour-card__category-icon"
                  dangerouslySetInnerHTML={{ __html: category.category_icon }}
                />
              )}
              <span>
                {category.name}
              </span>
            </div>
          )}
        </div>

        {showThumbnails && (
          <Swiper
            {...THUMBNAIL_OPTIONS}
            ref={this.thumbnailRef}
            rebuildOnUpdate={!showThumbnails}
          >
            {slideArray.map(slide => (
              <Slide
                key={`thumbnail-${slide.id}`}
                title={slide.title}
                imageUrl={slide.thumbnailUrl}
                videoUrl={slide.videoUrl}
                thumbnail
              />
            ))}
          </Swiper>
        )}

        <ModalVideo
          channel={channel}
          videoId={videoId}
          isOpen={isOpen}
          onClose={this.closeModal}
        />
      </div>
    );
  }
}

Gallery.propTypes = {
  slides: PropTypes.array,
  category: PropTypes.object,
  showThumbnails: PropTypes.bool,
  showPagination: PropTypes.bool,
  linkTo: PropTypes.string,
};

Gallery.defaultProps = {
  slides: null,
  category: null,
  linkTo: null,
  showThumbnails: false,
  showPagination: false,
};

export default Gallery;
