import React from 'react';
import PropTypes from 'prop-types';

const AlertBanner = ({ messages }) => (
  <div className="c-alert-banner">
    <ul className="c-alert-banner__messages u-reset-list">
      {
        messages.map(message => (
          <li
            className={`c-alert-banner__message c-alert-banner__message--${message.type || ''}`}
            key={message.id}
          >
            <div
              className="c-alert-banner__inner-text"
              dangerouslySetInnerHTML={{ __html: message.content }}
            />
          </li>
        ))
      }
    </ul>
  </div>
);

AlertBanner.propTypes = {
  messages: PropTypes.array.isRequired,
};

export default AlertBanner;
