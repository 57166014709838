import React from 'react';
import PropTypes from 'prop-types';

import { BlogCard } from 'components';


const BlogList = ({ title, list }) => {
  if (!list || !list.length) return null;

  return (
    <section className="l-blog-list">
      <div className="l-container">
        <div className="l-blog-list__header">
          <h2 className="c-heading c-heading--h2">
            {title}
          </h2>
        </div>

        <div className="l-grid l-grid--2">
          {list.map(item => (
            <BlogCard
              key={item}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

BlogList.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array,
};

BlogList.defaultProps = {
  list: null,
};

export default BlogList;
