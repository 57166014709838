import React, { PureComponent } from 'react';
import { navigate } from 'gatsby';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const LOCATION_OPTIONS = [
  { value: 'all-new-zealand', label: 'All New Zealand' },
  { value: 'north-island', label: 'North Island' },
  { value: 'south-island', label: 'South Island' },
];

const customStyles = {
  control: provided => ({
    ...provided,
    height: '48px',
    width: '400px',
    border: '1px solid #c2c4c7',
    borderRadius: 0,
    padding: '0 15px',
    boxShadow: 'unset',
    textAlign: 'left',
    '@media only screen and (max-width: 992px)': {
      ...provided['@media only screen and (max-width: 992px)'],
      width: '100%',
    },
  }),

  menuList: provided => ({
    ...provided,
    textAlign: 'left',
  }),
};

class HomeSearchBanner extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocation: LOCATION_OPTIONS[0],
      selectedDateRangeFrom: null,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onSelectLocation = this.onSelectLocation.bind(this);
    this.onDateRangeFromChanged = this.onDateRangeFromChanged.bind(this);
  }

  onSelectLocation(e) {
    this.setState({ selectedLocation: e });
  }

  onDateRangeFromChanged(e) {
    this.setState({ selectedDateRangeFrom: e });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const { selectedLocation, selectedDateRangeFrom } = this.state;
    const location = `location=${selectedLocation.value}`;
    const dateRangeFrom = selectedDateRangeFrom ? `&date_range_from=${moment(selectedDateRangeFrom).format('MM/DD/yyyy')}` : '';
    const path = `/tours-search?${location}${dateRangeFrom}`;
    navigate(path);
  }

  render() {
    const { selectedLocation, selectedDateRangeFrom } = this.state;

    return (
      <section className="l-home-search-banner">
        <div className="l-container">
          <div className="l-home-banner__inner">
            <h2 className="c-heading c-heading--h1">
              Welcome To Haka Tours
            </h2>
            <form className="l-search-tours-form" onSubmit={this.handleFormSubmit}>
              <div className="l-form-group input-location">
                <Select
                  styles={customStyles}
                  options={LOCATION_OPTIONS}
                  onChange={this.onSelectLocation}
                  defaultValue={selectedLocation}
                />
              </div>
              <div className="l-form-group input-date">
                <DatePicker
                  selected={selectedDateRangeFrom}
                  onChange={this.onDateRangeFromChanged}
                  placeholderText="Anytime"
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="l-form-group">
                <button className="c-button c-button--primary search-btn" type="submit">Search</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

export default HomeSearchBanner;
