import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

const BookingHeader = ({ title, subTitle, hasBackButton }) => (
  <header className="l-booking-header">
    { hasBackButton && <button type="button" onClick={() => navigate(-1)} className="l-booking-header__link">&#60; Back</button> }
    <h1 className="c-heading c-heading--h1 l-booking-header__title">{title}</h1>
    <p className="l-booking-header__sub-title">{subTitle}</p>
  </header>
);

BookingHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  hasBackButton: PropTypes.bool,
};

BookingHeader.defaultProps = {
  hasBackButton: false,
  subTitle: '',
};

export default BookingHeader;
