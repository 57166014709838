import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery, Link } from 'gatsby';
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import {
  numberToCurrency,
  minMaxOptionPrices,
  getImageUrl,
  mapStateToProps,
} from 'utilities';

import { Favourite } from 'containers';

class ActivityCard extends PureComponent {
  render() {
    const {
      data, user, showFavourite, showPrice, showLocation,
    } = this.props;
    const optionPrices = minMaxOptionPrices(data.options);
    const FAVED = user.favorites.activities.includes(data.api_id);

    return (
      <StaticQuery
        query={graphql`
          query {
            allActivityImages {
              edges {
                node {
                  api_id
                  imgix_url
                }
              }
            }
          }
        `}
        render={({ allActivityImages }) => {
          const imageUrl = getImageUrl({
            id: data.sliding_first_picture_id,
            images: allActivityImages.edges,
            width: 280,
            height: 175,
          });

          return (
            <div className={`c-activity-card ${FAVED ? 'is-faved' : ''}`}>
              <div className="c-activity-card__image">
                <Link to={`${data.absolute_slug}/`}>
                  {data.sliding_first_picture_id && (
                    <LazyLoadImage
                      src={imageUrl}
                      effect="blur"
                      wrapperClassName="lazy-img-wrapper"
                      alt={data.name}
                    />
                  )}
                  {showPrice && Array.isArray(data.options) && data.options.length ? (
                    <div className="c-activity-card__price">
                      {optionPrices.min < optionPrices.max && <p className="from">From</p>}
                      {numberToCurrency(optionPrices.min)}
                    </div>
                  ) : null}
                </Link>
                {showFavourite && (
                  <Favourite addOnID={data.api_id} buttonStyle="small" />
                )}
              </div>
              <div className="c-activity-card__content">
                <Link to={`${data.absolute_slug}/`}>
                  {showLocation && (
                    <span className="c-activity-card__location">
                      {data.destination_name}
                    </span>
                  )}
                  <h5 className="c-heading c-heading--h5">
                    {data.name}
                  </h5>
                </Link>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

ActivityCard.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  showFavourite: PropTypes.bool,
  showPrice: PropTypes.bool,
  showLocation: PropTypes.bool,
};

ActivityCard.defaultProps = {
  showFavourite: true,
  showPrice: true,
  showLocation: true,
};

export default connect(mapStateToProps)(ActivityCard);
