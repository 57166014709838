/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { graphql, StaticQuery } from 'gatsby';

import { Modal } from 'components';
import { TourList } from 'layouts';
import {
  getImageUrl, mapStateToProps, FORMAT_GRAPH_DATA,
} from 'utilities';

const showdown = require('showdown');

const converter = new showdown.Converter();

class IncludedActivityModal extends PureComponent {
  /**
   * Get gallery slides
   * @returns array
   */
  getGallerySlides(activity) {
    const { images } = this.props;

    const slideIDs = [
      activity.sliding_first_picture_id,
    ];

    return slideIDs
      .filter(id => id)
      .map(id => ({
        id,
        imageUrl: getImageUrl({
          id,
          images,
          width: 762,
          height: 468,
        }),
      }));
  }

  render() {
    const {
      user, activity, open, handleClose, showFavorite, showLocation,
    } = this.props;

    // Favourites
    const FAVED = user.favorites.activities.includes(activity.api_id);

    return (
      <StaticQuery
        query={graphql`
          query {
            allActivityImages {
              edges {
                node {
                  api_id
                  imgix_url
                }
              }
            }

            allTourPages(
              sort: {fields: [order], order: ASC},
              filter: {
                        hidden_category: { eq: false },
                      }
            ) {
              edges {
                node {
                  id
                  api_id
                  name
                  slug
                  number_of_days
                  starting_price
                  destination_ids
                  category_ids
                  short_description_markdown
                  parent_name
                  absolute_slug
                  picture_id
                  small_map_id
                  sliding_first_picture_id
                  sliding_second_picture_id
                  sliding_third_picture_id
                  sliding_fourth_picture_id
                  sliding_fifth_picture_id
                  sliding_sixth_picture_id
                  sliding_seventh_picture_id
                  sliding_eighth_picture_id
                  sliding_ninth_picture_id
                  sliding_tenth_picture_id
                  background_color
                  parent_category_icon {
                    id
                    svg_text
                  }
                }
              }
            }
          }
        `}

        render={({ allActivityImages, allTourPages }) => {
          const imageUrl = getImageUrl({
            id: activity.sliding_first_picture_id,
            images: allActivityImages.edges,
            width: 762,
            height: 468,
          });

          const tourList = FORMAT_GRAPH_DATA(allTourPages).filter(item => activity.tour_ids.includes(item.api_id));

          return (
            <Modal
              open={open}
              closeHandler={handleClose}
            >
              <div className="c-activity-modal">
              <div className="c-thumbnail-gallery">
                <img src={imageUrl} style={{'width': '100%'}}/>
              </div>
                <div className="c-activity-modal__main l-activity-page__main">
                  <div className="l-activity-page__main-text">
                    <h2 className="c-heading c-heading--h2">
                      {activity.name}
                    </h2>

                    {showLocation && (
                      <p className="l-activity-page__main-location">
                        {activity.location} | {activity.category}
                      </p>
                    )}

                    <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(activity.description_markdown) }} />

                    {!!tourList.length && (
                      <TourList
                        heading={<h2 className="c-heading c-heading--h2">TOURS YOU CAN EXPERIENCE THIS ACTIVITY ON</h2>}
                        list={tourList}
                        modifier="u-background--white"
                        hideFilters
                      />
                    )}
                  </div>
                </div>
              </div>
            </Modal>
          )
        }}
      />
    );
  }
}

IncludedActivityModal.propTypes = {
  user: PropTypes.any.isRequired,
  activity: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  showFavorite: PropTypes.bool,
  showLocation: PropTypes.bool,
};

IncludedActivityModal.defaultProps = {
  showFavorite: true,
  showLocation: true,
};

export default connect(mapStateToProps)(IncludedActivityModal);
