import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps } from 'utilities';
import { Modal } from 'components';

class SnowGearHireModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      traveller: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    const { traveller } = nextProps;

    this.setState({
      traveller,
    });
  }

  onSnowSkiSizeInputChange(value) {
    this.setState(prevState => ({
      traveller: {
        ...prevState.traveller,
        snow_ski_size: value,
      },
    }));
  }

  onSnowSkiHeightInputChange(value) {
    this.setState(prevState => ({
      traveller: {
        ...prevState.traveller,
        snow_ski_height: value,
      },
    }));
  }

  onSnowSkiWeightInputChange(value) {
    this.setState(prevState => ({
      traveller: {
        ...prevState.traveller,
        snow_ski_weight: value,
      },
    }));
  }

  onStanceInputChange(value) {
    this.setState(prevState => ({
      traveller: {
        ...prevState.traveller,
        stance: value,
      },
    }));
  }

  onSnowBootSizeInputChange(value) {
    this.setState(prevState => ({
      traveller: {
        ...prevState.traveller,
        snow_boot_size: value,
      },
    }));
  }

  onSnowClothSizeInputChange(value) {
    this.setState(prevState => ({
      traveller: {
        ...prevState.traveller,
        snow_cloth_size: value,
      },
    }));
  }

  /**
     * When a traveller additional infomation update
     * @param e
     */
  async onTravellerUpdate() {
    const {
      update,
      activity,
      option,
      formType,
    } = this.props;

    const { traveller } = this.state;

    const DATA = {
      addOnID: activity.api_id,
      optionID: option.id,
      travellerID: traveller.id,
      traveller: formType === 'gear' ? {
        id: traveller.id,
        snow_ski_size: traveller.snow_ski_size,
        snow_ski_height: traveller.snow_ski_height,
        snow_ski_weight: traveller.snow_ski_weight,
        snow_boot_size: traveller.snow_boot_size,
        stance: traveller.stance,
      } : {
        id: traveller.id,
        snow_cloth_size: traveller.snow_cloth_size,
      },
    };

    update({
      data: DATA,
    });
  }

  render() {
    const {
      open,
      handleClose,
      formType,
    } = this.props;

    const { traveller } = this.state;

    return (
      <Modal
        open={open}
        closeHandler={handleClose}
      >
        <div className="c-snow-gear-hire-modal">
          { traveller
            && (
              <form action="">
                <div className="l-form__contents">
                  { formType === 'gear'
                    ? (
                      <React.Fragment>
                        <div className="l-form-group">
                          <label htmlFor={`${traveller.id}_snow_ski_size`} className="l-form-group__label">
                            Ski/board size
                          </label>
                          <input
                            id={`${traveller.id}_snow_ski_size`}
                            name="snow_ski_size"
                            type="text"
                            className="c-form-control c-form-control--input"
                            value={traveller.snow_ski_size || ''}
                            onChange={e => this.onSnowSkiSizeInputChange(e.target.value)}
                          />
                        </div>
                        <div className="l-form-group">
                          <label htmlFor={`${traveller.id}_snow_ski_height`} className="l-form-group__label">
                            Height
                          </label>
                          <input
                            id={`${traveller.id}_snow_ski_height`}
                            name="snow_ski_height"
                            type="text"
                            className="c-form-control c-form-control--input"
                            value={traveller.snow_ski_height || ''}
                            onChange={e => this.onSnowSkiHeightInputChange(e.target.value)}
                          />
                        </div>
                        <div className="l-form-group">
                          <label htmlFor={`${traveller.id}_snow_ski_weight`} className="l-form-group__label">
                            Weight
                          </label>
                          <input
                            id={`${traveller.id}_snow_ski_weight`}
                            name="snow_ski_weight"
                            type="text"
                            className="c-form-control c-form-control--input"
                            value={traveller.snow_ski_weight || ''}
                            onChange={e => this.onSnowSkiWeightInputChange(e.target.value)}
                          />
                        </div>
                        <div className="l-form-group">
                          <label htmlFor={`${traveller.id}_stance`} className="l-form-group__label">
                            Stance (if snowboarding)
                          </label>
                          <input
                            id={`${traveller.id}_stance`}
                            name="stance"
                            type="text"
                            className="c-form-control c-form-control--input"
                            value={traveller.stance || ''}
                            onChange={e => this.onStanceInputChange(e.target.value)}
                          />
                        </div>
                        <div className="l-form-group">
                          <label htmlFor={`${traveller.id}_snow_boot_size`} className="l-form-group__label">
                            Boot size
                          </label>
                          <input
                            id={`${traveller.id}_snow_boot_size`}
                            name="snow_boot_size"
                            type="text"
                            className="c-form-control c-form-control--input"
                            value={traveller.snow_boot_size || ''}
                            onChange={e => this.onSnowBootSizeInputChange(e.target.value)}
                          />
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="l-form-group">
                          <label htmlFor={`${traveller.id}_snow_cloth_size`} className="l-form-group__label">
                            Please provide your clothing size:
                          </label>
                          <textarea
                            id={`${traveller.id}_snow_cloth_size`}
                            name="snow_cloth_size"
                            className="c-form-control c-form-control--textarea"
                            value={traveller.snow_cloth_size || ''}
                            onChange={e => this.onSnowClothSizeInputChange(e.target.value)}
                          />
                        </div>
                      </React.Fragment>
                    )
                  }
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      this.onTravellerUpdate();
                    }}
                    type="button"
                    value="Save"
                    className="c-button c-button--border"
                  >Save
                  </button>
                </div>
              </form>
            )
          }
        </div>
      </Modal>
    );
  }
}

SnowGearHireModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  activity: PropTypes.object.isRequired,
  option: PropTypes.object.isRequired,
  traveller: PropTypes.object.isRequired,
  formType: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(SnowGearHireModal);
