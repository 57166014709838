import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import {
  PAGES,
  PRIVATE_TOURS_URL,
  // GERMAN_TOUR_URL,
  STATES,
} from 'config';
import { isTabletScreen } from 'utilities';
import { TourSubNavL2 } from 'components';
import SVGArrow from '../../../public/svg/sub-nav-arrow.svg';

const BODY_ACTIVE_CLASS = 's-mob-nav-active';

class TourSubNav extends PureComponent {
  constructor() {
    super();

    this.state = {
      subNav: {},
    };

    this.onSubNavClick = this.onSubNavClick.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
  }


  onSubNavClick(e, id) {
    if (isTabletScreen(window)) e.preventDefault();

    this.setState(prevState => ({
      subNav: {
        ...prevState.subNav,
        [id]: !prevState.subNav[id],
      },
    }));
  }

  onBackClick() {
    this.setState({
      subNav: {},
    });
  }

  onMenuClick() {
    window.requestAnimationFrame(() => document.body.classList.remove(BODY_ACTIVE_CLASS));
    this.removeDocumentTop();
  }

  removeDocumentTop() {
    document.body.style.top = '0';
    window.requestAnimationFrame(() => window.scrollTo(0, this.offsetY));
  }

  render() {
    const { subNav } = this.state;
    const {
      list,
      isActive,
      onBackClick,
      rate,
    } = this.props;

    if (!list.length) return null;

    return (
      <div className={`c-sub-nav c-sub-nav--tours ${isActive ? STATES.ACTIVE : ''}`}>
        <button
          type="button"
          className="c-sub-nav__back"
          onClick={onBackClick}
        >
          <SVGArrow />
          Back
        </button>

        <ul className="c-sub-nav__list">
          <li className="c-sub-nav__item">
            <Link
              to={PAGES.TOURS.PATH}
              className="c-sub-nav__link"
            >
              View all tours
            </Link>
          </li>
          {list.map(item => (
            <li
              key={item.id}
              className="c-sub-nav__item"
              style={{ backgroundColor: item.background_color }}
            >
              <div className="c-nav__main">
                <Link
                  to={`${item.absolute_slug}/`}
                  className="c-sub-nav__link"
                  onClick={this.onMenuClick}
                >
                  <div
                    className="c-sub-nav__link-bar"
                    style={{ backgroundColor: item.background_color }}
                  />
                  <div
                    className="c-sub-nav__link-icon"
                    dangerouslySetInnerHTML={{ __html: item.category_icon && item.category_icon.svg_text }}
                  />
                  {item.full_name}
                </Link>
                <button
                  type="button"
                  className="c-sub-nav__link-arrow"
                  onClick={e => this.onSubNavClick(e, item.id)}
                >
                  <SVGArrow />
                </button>
              </div>
              <TourSubNavL2
                title={item.name}
                list={item.tours.filter(tour => tour.is_german_tour === false)}
                isActive={!!subNav[item.id]}
                onBackClick={this.onBackClick}
                onMenuClick={this.onMenuClick}
                rate={rate}
              />
            </li>
          ))}

          <li className="c-sub-nav__item">
            <a
              href={PRIVATE_TOURS_URL}
              target="_blank"
              className="c-sub-nav__link"
              rel="noopener noreferrer"
            >
              <div className="c-sub-nav__link-bar" />
              <img className="c-sub-nav__link-icon" src="/svg/private.svg" alt="" />
              Private Tours
              <button
                type="button"
                className="c-sub-nav__link-arrow"
              >
                <SVGArrow />
              </button>
            </a>
          </li>

          {/* <li className="c-sub-nav__item">
            <a
              href={GERMAN_TOUR_URL}
              target="_blank"
              className="c-sub-nav__link"
              rel="noopener noreferrer"
            >
              <div className="c-sub-nav__link-bar" />
              <img className="c-sub-nav__link-icon" src="/svg/german-flag.svg" style={{ padding: '5px' }} alt="" />
              German-Speaking Tours
            </a>
          </li> */}
        </ul>
      </div>
    );
  }
}

TourSubNav.propTypes = {
  list: PropTypes.array.isRequired,
  isActive: PropTypes.bool,
  onBackClick: PropTypes.func,
  rate: PropTypes.number,
};

TourSubNav.defaultProps = {
  isActive: false,
  onBackClick: () => {},
  rate: 0,
};

export default TourSubNav;
