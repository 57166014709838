import React, { PureComponent } from 'react';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { ClipLoader } from 'react-spinners';

import { PAGES } from 'config';
import { FormInput, withFormGroup } from 'components';
import { SIGN_IN_USER, GET_USER, SIGN_IN_USER_FACEBOOK } from 'actions/user';
import { mapStateToProps, RESPONSE_ERROR_FORMATTER } from 'utilities';

class SignInForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      redirectURL: PAGES.USER.PATH,
      loading: false,
    };
    // get redirect URL if exists
    if (typeof window !== 'undefined') {
      const PARSED = queryString.parse(window.location.search);
      if (PARSED.return) {
        this.state.redirectURL = PARSED.return;
      }
    }
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFacebookSignIn = this.handleFacebookSignIn.bind(this);
    this.handleSignUpLink = this.handleSignUpLink.bind(this);
  }

  /**
   * Handle sign with Facebook
   * @param e
   */
  handleFacebookSignIn(e) {
    e.preventDefault();
    const { dispatch, user } = this.props;
    if (!FB) return;
    // 1. Login with FB
    FB.login((response) => {
      if (response.authResponse) {
        this.setState({
          loading: true,
        });
        // 2. Request FB data
        FB.api('/me?fields=id,email,first_name,last_name', async (FBuser) => {
          // 3. Sign in user with cred (and save to store)
          const SIGN_IN = await SIGN_IN_USER_FACEBOOK(dispatch, response.authResponse.accessToken, FBuser.email, FBuser.first_name, user.session);
          if (!SIGN_IN || !SIGN_IN.success) {
            this.setState({
              errors: RESPONSE_ERROR_FORMATTER(SIGN_IN.data.errors),
            });
            return;
          }
          this.handleSuccess(SIGN_IN);
        });
      }
    }, { scope: 'email' });
  }

  /**
   * Handle form submit
   * @param e
   */
  handleFormSubmit(e) {
    e.preventDefault();
    const { dispatch, user } = this.props;
    const FORM = e.currentTarget;
    if (FORM instanceof HTMLFormElement === false || !FORM.checkValidity()) return;
    const FORM_DATA = new FormData(FORM);

    (async () => {
      // 1. sign in user
      const SIGN_IN = await SIGN_IN_USER(dispatch, FORM_DATA.get('Email'), FORM_DATA.get('Password'), user.session);
      if (!SIGN_IN || !SIGN_IN.success) {
        this.setState({
          errors: RESPONSE_ERROR_FORMATTER(SIGN_IN.data.errors),
        });
        return;
      }
      this.handleSuccess(SIGN_IN);
    })();
  }

  /**
   * Handle successful sign in
   * @param signIn
   */
  handleSuccess(signIn) {
    const { redirectURL } = this.state;
    const { successHook, dispatch } = this.props;
    (async () => {
      const GET = await GET_USER(dispatch, signIn.data.session.token, signIn.data.session.email);
      if (GET && GET.success) {
        dispatch({
          type: 'MESSAGES_ADD',
          payload: {
            id: 'make-payment',
            type: 'success',
            content: 'Your login is successful',
          },
        });
        setTimeout(() => {
          dispatch({
            type: 'MESSAGES_REMOVE',
            payload: 'make-payment',
          });
        }, 7000);
        if (successHook && typeof successHook === 'function') {
          successHook();
        } else {
          navigate(redirectURL);
        }
      } else {
        this.setState({
          errors: RESPONSE_ERROR_FORMATTER(GET.data.errors),
        });
      }
    })();
  }

  handleSignUpLink(e) {
    e.preventDefault();
    const { redirectURL } = this.state;
    const { signUpHook } = this.props;
    if (signUpHook && typeof signUpHook === 'function') {
      signUpHook();
      // prevents passing around the default redirect
    } else if (redirectURL !== PAGES.USER.PATH) {
      navigate(`${PAGES.SIGN_UP.PATH}?return=${encodeURIComponent(redirectURL)}`);
    } else {
      navigate(PAGES.SIGN_UP.PATH);
    }
  }

  render() {
    const { errors, loading } = this.state;
    const { title } = this.props;
    const Input = withFormGroup(FormInput);
    return (
      <div className="l-sign-in-form">
        <div className="l-container l-container--small">
          <div className="l-sign-in-form__header">
            <h1 className="c-heading c-heading--h2">{ title }</h1>
            <p>Sign in to save favourites, download brochures, book tours and activities</p>
          </div>
          <div className="l-sign-in-form__wrapper">
            <form action="" onSubmit={this.handleFormSubmit}>
              <ul className="l-form__errors">
                {errors.map(error => (
                  <li key={error} className="u-text--center">
                    {error}
                  </li>
                ))}
              </ul>
              <div className="l-form__content">
                <Input
                  type="email"
                  name="Email"
                  id="InputEmail"
                  label="Email"
                  placeholder="Email"
                  srOnly
                  required
                />
                <Input
                  type="password"
                  label="Password"
                  name="Password"
                  id="InputPassword"
                  placeholder="Password"
                  srOnly
                  required
                />
                <div className="l-form__actions">
                  <button
                    className="c-button c-button--wide c-button--primary"
                    type="submit"
                  >
                    Sign In
                  </button>
                  <Link
                    to={PAGES.RESET_PASSWORD.PATH}
                    className="l-sign-in-form__forgot-pw-link"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>
            </form>
            <h5 className="l-sign-in-form__or">OR</h5>
            {loading && (
              <div className="loading-screen">
                <ClipLoader
                  sizeUnit="px"
                  size={50}
                  color="#123abc"
                />
              </div>
            )}
            {!loading && (
              <button
                onClick={this.handleFacebookSignIn}
                type="button"
                className="c-button c-button--wide c-button--facebook"
              >
                Sign In With Facebook
              </button>
            )}
            <hr />
            <h3 className="c-heading c-heading--h3 u-text--center">Don‘t have an account?</h3>
            <button
              type="button"
              onClick={this.handleSignUpLink}
              className="c-button c-button--wide c-button--border l-sign-in-form__sign-up-link"
            >
              Create a new Account
            </button>
          </div>
        </div>
      </div>
    );
  }
}

SignInForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  signUpHook: PropTypes.func,
  successHook: PropTypes.func,
  title: PropTypes.string,
};

SignInForm.defaultProps = {
  title: 'Sign In',
  signUpHook: null,
  successHook: null,
};

export default connect(mapStateToProps)(SignInForm);
