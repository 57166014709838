import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { STATES } from 'config';
import { mapStateToProps, isTabletScreen } from 'utilities';
import {
  Nav, SiteLogo, MessageBanner, AccountNav, BookingNav,
} from 'components';


const BODY_ACTIVE_CLASS = 's-mob-nav-active';


class Header extends PureComponent {
  constructor() {
    super();

    this.scrollEvent = this.scrollEvent.bind(this);
    this.onHamburgerClick = this.onHamburgerClick.bind(this);
    this.onViewportChange = this.onViewportChange.bind(this);

    this.state = {
      isHeaderActive: false,
      isMobNavActive: false,
      viewportChange: false,
    };
  }


  componentDidMount() {
    window.addEventListener('scroll', this.scrollEvent, { passive: true });
    this.scrollEvent();

    if ('onresize' in window) {
      window.addEventListener('resize', this.onViewportChange, false);
    }

    if ('onorientationchange' in window) {
      window.addEventListener('orientationchange', this.onViewportChange, false);
    }
  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollEvent);
    window.removeEventListener('onresize', this.onViewportChange, false);
    window.removeEventListener('orientationchange', this.onViewportChange, false);
  }


  /**
   * On hamburger menu click
   */
  onHamburgerClick() {
    this.setState(prevState => ({
      isMobNavActive: !prevState.isMobNavActive,
    }), () => {
      const { isMobNavActive } = this.state;

      if (isMobNavActive) {
        this.setDocumentTop();
        document.body.classList.add(BODY_ACTIVE_CLASS);
      } else {
        window.requestAnimationFrame(() => document.body.classList.remove(BODY_ACTIVE_CLASS));
        this.removeDocumentTop();
      }
    });
  }


  /**
   * On viewport size change
   */
  onViewportChange() {
    this.setState(prevState => ({
      viewportChange: !prevState.viewportChange,
    }));
  }


  /**
   * If the window has scrolled, set the top of the body to what the window had scrolled since the body will be set to "fixed".
   */
  setDocumentTop() {
    this.offsetY = window.pageYOffset;
    if (this.offsetY > 0) {
      document.body.style.top = `-${this.offsetY}px`;
    }
  }


  /**
   * Remove the top style from the document and scroll the window to it’s previous position.
   */
  removeDocumentTop() {
    document.body.style.top = '0';
    window.requestAnimationFrame(() => window.scrollTo(0, this.offsetY));
  }


  /**
   * Scroll event
   */
  scrollEvent() {
    let isHeaderActive = false;

    if (window.pageYOffset > 0) {
      isHeaderActive = true;
    }

    this.setState({ isHeaderActive });
  }


  /**
   * Render
   */
  render() {
    const { isHeaderActive } = this.state;

    const {
      forceActive, messages, dispatch, useBookingNav,
    } = this.props;

    return (
      <header className={`l-header ${isHeaderActive || forceActive ? STATES.ACTIVE : ''}`}>
        <MessageBanner messages={messages.messages} dispatch={dispatch} />

        <div className="l-header__inner">
          <SiteLogo />

          <Nav />

          {useBookingNav ? <span className="u-hide-screen-large"><BookingNav /></span> : null}

          {typeof window !== 'undefined' && !isTabletScreen(window) && (
            <AccountNav />
          )}

          <button
            type="button"
            className="c-nav__trigger"
            onClick={this.onHamburgerClick}
          >
            <span className="c-nav__trigger-inner" />
            <span className="u-sr-only">Menu</span>
          </button>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  forceActive: PropTypes.bool,
  useBookingNav: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
};

Header.defaultProps = {
  forceActive: false,
  useBookingNav: false,
};

export default connect(mapStateToProps)(Header);
