import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { numberToCurrency } from 'utilities';

import {
  FormInput,
  ActivityCard,
  ActivityModal,
  SnowGearHireModal,
} from 'components';

class BookingEssential extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      snowGearHireModalOpen: false,
      selectedTraveller: false,
      selectedOption: false,
      selected: this.anyOptionSelected(),
    };
    this.onOptionChange = this.onOptionChange.bind(this);
    this.onTravellerChange = this.onTravellerChange.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleSnowGearHireModalOpen = this.handleSnowGearHireModalOpen.bind(this);
    this.handleSnowGearHireModalClose = this.handleSnowGearHireModalClose.bind(this);
  }

  componentWillReceiveProps() {
    const { selectedTraveller } = this.state;
    const { travellers } = this.props;

    if (selectedTraveller) {
      const currentTraveller = travellers.filter(traveller => traveller.id === selectedTraveller.id);

      this.setState({ selectedTraveller: currentTraveller[0] });
    }
  }

  /**
   * On option selected
   * @param e
   */
  onOptionChange(e) {
    const {
      activity,
      travellers,
      update,
    } = this.props;
    const CHECKED = e.currentTarget.checked;
    const VALUE = e.currentTarget.value; // option ID
    this.setState({
      selected: CHECKED, // always set to selected
    });

    const UPDATE_DATA = [];
    travellers.forEach((traveller) => {
      UPDATE_DATA.push({
        data: {
          addOnID: activity.api_id,
          optionID: VALUE,
          travellerID: traveller.id,
        },
        destroy: !CHECKED,
      });
    });
    // bulk update add-ons
    update(UPDATE_DATA);
  }

  /**
   * When a traveller option changes
   * @param e
   * @param optionID
   */
  onTravellerChange(e, optionID) {
    const { activity, update } = this.props;
    const CHECKED = e.currentTarget.checked;
    const TRAVELLER_ID = e.currentTarget.value;
    const DATA = {
      addOnID: activity.api_id,
      optionID,
      travellerID: TRAVELLER_ID,
    };

    if (CHECKED) {
      update([{
        data: DATA,
        destroy: false,
      }]);
    } else {
      update([{
        data: DATA,
        destroy: true,
      }]);
    }
  }

  /**
   * Is any option selected for this activity + day
   * @returns {*}
   */
  anyOptionSelected() {
    const {
      activity,
      bookingAddOns,
    } = this.props;
    const MATCHES = bookingAddOns.find(addOn => addOn.db_addon_id === activity.api_id
      && !addOn._destroy); // eslint-disable-line
    return !!MATCHES;
  }

  /**
   * Find traveller and option in booking add-ons
   * @param traveller
   * @param optionID
   * @param dayID
   * @returns {boolean}
   */
  travellerSelected(traveller, optionID) {
    const { bookingAddOns } = this.props;
    const MATCH = bookingAddOns.find(addOn => addOn.traveller_id === traveller.id
      && !addOn._destroy // eslint-disable-line
      && addOn.option_id === optionID);
    return !!MATCH;
  }

  /**
   * Set traveller option to disabled to prevent user selecting more than
   * one add-on per day
   * @param traveller
   * @param optionID
   * @param dayID
   * @returns {boolean}
   */
  // travellerDisabled(traveller, optionID, dayID) {
  //   const { bookingAddOns } = this.props;
  //   const SAME_DAY_BOOKING = bookingAddOns
  //     .find(addOn => !addOn._destroy // eslint-disable-line
  //       && addOn.day_id === dayID
  //       && addOn.option_id !== optionID
  //       && addOn.traveller_id === traveller.id);
  //   return !!SAME_DAY_BOOKING;
  // }

  handleModalOpen() {
    this.setState({
      modalOpen: true,
    });
  }

  handleModalClose() {
    this.setState({
      modalOpen: false,
    });
  }

  handleSnowGearHireModalOpen(traveller, option) {
    this.setState({
      selectedOption: option,
      selectedTraveller: traveller,
      snowGearHireModalOpen: true,
    });
  }

  handleSnowGearHireModalClose() {
    this.setState({
      selectedOption: false,
      selectedTraveller: false,
      snowGearHireModalOpen: false,
    });
  }

  render() {
    const {
      activity, travellers, images, updateTraveller, isDisabled,
    } = this.props;

    const {
      selected,
      modalOpen,
      snowGearHireModalOpen,
      selectedTraveller,
      selectedOption,
    } = this.state;

    const isSkiSnowboardHire = activity.name === 'Ski / Snowboard Hire';
    const isSkiClothingHire = activity.name === 'Ski Clothing Hire';
    const formType = isSkiClothingHire ? 'cloth' : 'gear';

    return (
      <div className="c-booking-activity">
        {/* eslint-disable-next-line */}
        <div onClick={(e) => {
          e.preventDefault();
          this.handleModalOpen();
        }}
        >
          <ActivityCard
            showFavourite={false}
            showLocation={false}
            data={{
              ...activity,
            }}
          />
        </div>
        <SnowGearHireModal
          open={snowGearHireModalOpen}
          handleClose={this.handleSnowGearHireModalClose}
          traveller={selectedTraveller}
          update={updateTraveller}
          activity={activity}
          option={selectedOption}
          formType={formType}
        />
        <ActivityModal showLocation={false} showFavorite={false} activity={activity} images={images} open={modalOpen} handleClose={this.handleModalClose} />
        <form action="">
          <div className={`l-form-option-list ${selected ? 'is-selected' : ''}`}>
            {activity.options.filter(option => option.hidden === false).filter(option => option.archived === false).map(option => (
              <div className="l-form-option-list__group" key={option.api_id}>
                <div className="l-form-option-list__option">
                  <FormInput
                    id={`${option.api_id}`}
                    name={option.name}
                    type="checkbox"
                    modifier="c-form-control--checkbox"
                    value={option.api_id}
                    onChange={this.onOptionChange}
                    attrs={{
                      defaultChecked: selected,
                      disabled: isDisabled,
                    }}
                  />
                  <label
                    htmlFor={`${option.api_id}`}
                  >
                    {option.name} - {numberToCurrency(option.price)}
                  </label>
                </div>
                <div className="l-form-option-list__sub-options">
                  {travellers.map(traveller => (
                    <div className="l-form-option-list__sub-option" key={traveller.id}>
                      <FormInput
                        id={`${option.api_id}-${traveller.id}`}
                        name={traveller.id}
                        type="checkbox"
                        disabled="true"
                        modifier="c-form-control--checkbox"
                        value={traveller.id}
                        attrs={{
                          checked: this.travellerSelected(traveller, option.api_id),
                        }}
                        onChange={(e) => {
                          this.onTravellerChange(e, option.api_id);
                        }}
                      />
                      <span>
                        <label
                          htmlFor={`${option.api_id}-${traveller.id}`}
                        >
                          {traveller.first_name} {traveller.last_name}
                        </label>
                        {this.travellerSelected(traveller, option.api_id) && (isSkiClothingHire || isSkiSnowboardHire)
                          && (
                            <button
                              type="button"
                              onClick={() => { this.handleSnowGearHireModalOpen(traveller, option); }}
                              className="addon-additional-btn"
                            >
                              Add info
                            </button>
                          )
                        }
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </form>
      </div>
    );
  }
}

BookingEssential.propTypes = {
  activity: PropTypes.object.isRequired,
  images: PropTypes.any.isRequired,
  travellers: PropTypes.array.isRequired,
  update: PropTypes.func.isRequired,
  updateTraveller: PropTypes.func.isRequired,
  bookingAddOns: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool,
};

BookingEssential.defaultProps = {
  isDisabled: false,
};

export default BookingEssential;
