import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { PAGES } from 'config';
import { PersonCard } from 'components';


const GuideList = ({ title, list, limit }) => {
  if (!list.length) return null;

  if (limit && limit < list.length) {
    list.length = limit; // eslint-disable-line
  }

  return (
    <section className="l-guide-list">
      <div className="l-container">
        <div className="l-guide-list__header">
          <h2 className="c-heading c-heading--h2">
            {title}
          </h2>
          <p>
            Our team of dedicated adventurers are ready to give you the best tour of New Zealand! They’re experienced,
            energetic and they love sharing their knowledge and stories about New Zealand with you. Here are a few members of our (whanau) family.
          </p>
        </div>

        <div className="l-grid l-grid--3">
          {list.map(item => (
            <PersonCard
              key={item.id}
              person={item}
            />
          ))}
        </div>

        <div className="l-guide-list__footer">
          <Link
            to={PAGES.ABOUT_PEOPLE.PATH}
            className="c-button c-button--border c-button--wide"
            role="button"
          >
            View all guides
          </Link>
        </div>
      </div>
    </section>
  );
};

GuideList.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  limit: PropTypes.number,
};

GuideList.defaultProps = {
  limit: null,
};

export default GuideList;
