import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ADD_ADDON_FAV, GET_USER, REMOVE_ADDON_FAV } from 'actions/user';
import { mapStateToProps } from 'utilities';
import { Modal } from 'components';
import { SignInForm, SignUpForm } from 'layouts';

import SVGHeart from '../../../public/svg/heart-filled.svg';

class Favourite extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      currentForm: 'sign-in',
    };
    this.handleFav = this.handleFav.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleFav(e) {
    e.preventDefault();
    const { user, onFavHook } = this.props;
    if (onFavHook) {
      onFavHook(user.authenticated);
    }
    if (user.authenticated) {
      this.save();
    } else {
      this.setState({
        modalOpen: true,
      });
    }
  }

  handleClose() {
    this.setState({
      modalOpen: false,
    });
  }

  /**
   * Save fav
   */
  save() {
    const { user, addOnID, dispatch } = this.props;
    const FAVED = user.favorites.activities.includes(addOnID);
    (async () => {
      const OPP = FAVED
        ? await REMOVE_ADDON_FAV(user.details.id, addOnID, user.session.email, user.session.token)
        : await ADD_ADDON_FAV(user.details.id, addOnID, user.session.email, user.session.token);

      if (OPP && OPP.success) {
        await GET_USER(dispatch, user.session.token, user.session.email);
      }
      this.setState({
        modalOpen: false,
      });
    })();
  }

  render() {
    const { modalOpen, currentForm } = this.state;
    const { user, addOnID, buttonStyle } = this.props;
    const FAVED = user.favorites.activities.includes(addOnID);
    return (
      <>
        <button
          type="button"
          className={`c-fav-btn c-fav-btn--${buttonStyle} ${FAVED ? 'is-faved' : ''}`}
          onClick={this.handleFav}
        >
          {
            buttonStyle === 'small'
              ? (
                <>
                  <SVGHeart />
                </>
              )
              : (
                <>
                  {FAVED ? 'Favourited' : 'Add to favourites'}
                  <SVGHeart />
                </>
              )
          }
        </button>
        <Modal open={modalOpen} closeHandler={this.handleClose}>
          <div className="c-fav-modal">
            {
              modalOpen
                ? (
                  <>
                    {
                    currentForm === 'sign-in'
                      ? (
                        <SignInForm
                          title="Sign in to save favourite activities"
                          signUpHook={() => {
                            this.setState({
                              currentForm: 'sign-up',
                            });
                          }}
                          successHook={() => {
                            this.save();
                          }}
                        />
                      )
                      : (
                        <SignUpForm
                          signInHook={() => {
                            this.setState({
                              currentForm: 'sign-in',
                            });
                          }}
                          successHook={() => {
                            this.save();
                          }}
                        />
                      )
                  }
                  </>
                ) : null
            }
          </div>
        </Modal>
      </>
    );
  }
}

Favourite.propTypes = {
  addOnID: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onFavHook: PropTypes.func,
  buttonStyle: PropTypes.string,
};

Favourite.defaultProps = {
  buttonStyle: 'big',
  onFavHook: null,
};

export default connect(mapStateToProps)(Favourite);
