/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { FaqAccordion, Gallery, Modal } from 'components';
import { Favourite } from 'containers';
import {
  getImageUrl, mapStateToProps, minMaxOptionPrices, minMaxSeasonalPrices, numberToCurrency, filterSeasonalPricing
} from 'utilities';

class ActivityModal extends PureComponent {
  /**
   * Get gallery slides
   * @returns array
   */
  getGallerySlides(activity) {
    const { images } = this.props;

    const slideIDs = [
      activity.sliding_first_picture_id,
      activity.sliding_second_picture_id,
      activity.sliding_third_picture_id,
      activity.sliding_fourth_picture_id,
      activity.sliding_fifth_picture_id,
    ];

    return slideIDs
      .filter(id => id)
      .map(id => ({
        id,
        imageUrl: getImageUrl({
          id,
          images,
          width: 762,
          height: 468,
        }),
      }));
  }

  getOptionPrice(option) {
    if (option.seasonal_pricings && option.seasonal_pricings.length > 0) {
      const filterSeasonalPricings = filterSeasonalPricing(option.seasonal_pricings)
      const { min, max } = minMaxSeasonalPrices(filterSeasonalPricings);
      if (max == min) {
        return `$${min}`;
      } else {
        return `$${min} - $${max}`;
      }
    } else {
      return `$${option.price}`;
    }
  }

  render() {
    const {
      user, activity, open, handleClose, showFavorite, showLocation,
    } = this.props;

    // Accordion list
    const accordionList = activity.options.filter(option => option.hidden === false).filter(option => option.archived === false).map(item => ({
      ...item,
      question: `${item.name} (${this.getOptionPrice(item)})`,
      // availability: this.getAvailability(item),
      answer: item.description,
    }));

    // Option prices
    const { min, max } = minMaxOptionPrices(activity.options);

    // Favourites
    const FAVED = user.favorites.activities.includes(activity.api_id);
    return (
      <Modal
        open={open}
        closeHandler={handleClose}
      >
        <div className="c-activity-modal">
          <Gallery
            slides={this.getGallerySlides(activity)}
            showPagination
          />

          <div className="c-activity-modal__main l-activity-page__main">
            <div className="l-activity-page__main-text">
              <h2 className="c-heading c-heading--h2">
                {activity.name}
              </h2>

              {showLocation && (
                <p className="l-activity-page__main-location">
                  {activity.location}
                </p>
              )}

              <h3 className="c-heading c-heading--h3">
                {numberToCurrency(min)}
                {max !== min ? ` – $${max}` : null}
              </h3>
              {showFavorite && (
                <div className={`l-activity-page__favourites ${FAVED ? 'is-faved' : ''}`}>
                  <Favourite
                    addOnID={activity.api_id}
                    onFavHook={(authenticated) => {
                      if (!authenticated) handleClose();
                    }}
                  />
                </div>
              )}
            </div>

            <FaqAccordion
              list={accordionList}
              closeOther
              open
            />
          </div>
        </div>
      </Modal>
    );
  }
}

ActivityModal.propTypes = {
  user: PropTypes.any.isRequired,
  activity: PropTypes.any.isRequired,
  images: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  showFavorite: PropTypes.bool,
  showLocation: PropTypes.bool,
};

ActivityModal.defaultProps = {
  showFavorite: true,
  showLocation: true,
};

export default connect(mapStateToProps)(ActivityModal);
