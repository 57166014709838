import React, { PureComponent } from 'react';
import { Link } from 'gatsby';
import { Banner } from 'components';
import { GET_ALL_REVIEWS } from 'actions/tours';
import StarRatings from 'react-star-ratings';
import SVGFeefo from '../../../public/svg/feefo-logo.svg';

class Review extends PureComponent {
  constructor() {
    super();
    this.state = {
      total: 0,
      avgRating: 5,
    };
  }

  componentWillMount() {
    (async () => {
      const ALL_REVIEWS = await GET_ALL_REVIEWS().catch((e) => {
        console.error(e.message);
      });
      if (ALL_REVIEWS) {
        this.setState({
          total: ALL_REVIEWS.count,
          avgRating: ALL_REVIEWS.rating,
        });
      }
    })();
  }

  render() {
    const {
      total,
      avgRating,
    } = this.state;
    const reviews = [{
      author: 'Nina Marie Harris',
      text: 'I can’t recommend Haka enough! I have done tours with other companies over the last few years but the way Haka does their thing is like a breath of fresh air.',
    }, {
      author: 'Clare P.',
      text: 'Absolutely everything I could have wanted. Brilliant tour guide, small close knit group, fab accommodation all the way and learnt so much! Will never forget.',
    }, {
      author: 'Pablo T.',
      text: 'Second time holidaying with Haka Tours. Tour guides are extremely knowledgeable about local attractions and history, plus a few secret surprises. Couldn’t recommend enough. A+++',
    }];
    const reviewText = reviews[Math.floor(Math.random() * reviews.length)];
    return (
      <Banner
        backgroundImage="https://static-hakatours.imgix.net/day17_haast_blue_pools_mt_aspiring_julian_apse_v2.jpg?fit=crop&auto=format"
        modifier="c-banner--sm l-review-banner"
      >
        <div className="c-heading c-heading--h2">Here‘s what you‘ve said about us</div>
        <div className="blockquote">
          <p className="body">
            {reviewText.text}
          </p>
          <p className="author">{reviewText.author}</p>
        </div>
        <div className="rating-box">
          <p>Average Customer Rating:
            <StarRatings
              rating={avgRating}
              starRatedColor="#FFDC00"
              starDimension="26px"
              starSpacing="0px"
              numberOfStars={5}
            />
            <span className="number-rate">{avgRating}</span>
            <SVGFeefo />
          </p>
          <p className="sub-body">Independent Service Rating - Based on {total} verified reviews.</p>
          <Link
            to="/about-us/reviews/"
            className="c-button c-button--primary c-button--wide review-botton"
          >
            View all reviews
          </Link>
        </div>
      </Banner>
    );
  }
}

export default Review;
