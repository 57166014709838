import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class FormInput extends PureComponent {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
  }


  /**
   * On change event
   *
   * @param event
   */
  onChange({ target: { value } }) {
    const { name, onChange } = this.props;
    if (onChange) onChange({ [name]: value });
  }


  /**
   * Render
   */
  render() {
    const {
      id,
      name,
      type,
      value,
      defaultValue,
      placeholder,
      modifier,
      required,
      disabled,
      onChange,
      attrs,
      minLength,
      maxLength,
      defaultChecked,
    } = this.props;
    // optional attributes
    const ATTRS = {
      ...attrs,
    };
    if (onChange) ATTRS.onChange = onChange;
    if (value) ATTRS.value = value;
    if (defaultValue) ATTRS.defaultValue = defaultValue;
    if (minLength) ATTRS.minLength = minLength;
    if (maxLength) ATTRS.maxLength = maxLength;
    if (defaultChecked) ATTRS.defaultChecked = defaultChecked;

    if (type === 'textarea') {
      return (
        <textarea
          id={id}
          name={name}
          className={`c-form-control c-form-control--textarea ${modifier}`}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          {...ATTRS}
        />
      );
    }
    return (
      <input
        id={id}
        name={name}
        type={type}
        className={`c-form-control c-form-control--input ${modifier}`}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        {...ATTRS}
      />
    );
  }
}

FormInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  modifier: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  attrs: PropTypes.any,
  minLength: PropTypes.any,
  maxLength: PropTypes.any,
  defaultChecked: PropTypes.any,
};

FormInput.defaultProps = {
  value: '',
  defaultValue: '',
  type: 'text',
  placeholder: null,
  modifier: '',
  required: false,
  disabled: false,
  onChange: null,
  attrs: {},
  minLength: null,
  maxLength: null,
  defaultChecked: null,
};

export default FormInput;
