/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';
import { getSeasonPriceString, filterSeasonalPricing } from 'utilities';

import SVGAccordionPlus from '../../../public/svg/accordion-plus.svg';

const showdown = require('showdown');

const converter = new showdown.Converter();

const FaqAccordion = ({ list, open, closeOther }) => (
  <Accordion
    className="c-accordion"
    accordion={closeOther}
  >
    {list.map((item, i) => (
      <AccordionItem
        key={item.id}
        className="c-accordion__item"
        expanded={open && i === 0 && list.length === 1}
      >
        <AccordionItemTitle className="c-accordion__title">
          <SVGAccordionPlus />
          <h5 className="c-heading c-heading--h5">
            {item.question}
          </h5>
        </AccordionItemTitle>
        <AccordionItemBody
          className="c-accordion__body"
          hideBodyClassName="c-accordion__body--hidden"
        >
          <div className="c-accordion__body-inner">
            <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(item.answer) }} />
          </div>
          {item.seasonal_pricings && filterSeasonalPricing(item.seasonal_pricings).length > 0 && (
            <div style={{ marginBottom: '1rem' }}>
              <div><b>Pricing</b></div>
              {filterSeasonalPricing(item.seasonal_pricings).map((season) => (
                <div>{getSeasonPriceString(season)}</div>
              ))}
            </div>
          )}
        </AccordionItemBody>
      </AccordionItem>
    ))}
  </Accordion>
);

FaqAccordion.propTypes = {
  list: PropTypes.array.isRequired,
  open: PropTypes.bool,
  closeOther: PropTypes.bool,
};

FaqAccordion.defaultProps = {
  open: false,
  closeOther: false,
};

export default FaqAccordion;
