import React from 'react';
import PropTypes from 'prop-types';

const AltPanels = ({ items, modifier }) => (
  <section className="l-alternating-panels">
    <div className={`l-container ${modifier}`}>
      { items.map(item => (
        <div className="l-alt-panels__item" key={item.title}>
          <div className={`l-alt-panels__item-image ${item.image.align}`}>
            <img src={item.image.url} alt={item.image.title} width={item.image.width} height={item.image.height} />
          </div>
          <div className="l-alt-panels__item-content">
            <h4 className="l-alt-panels__item-title c-heading c-heading--h4">{item.title}</h4>
            <div
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          </div>
        </div>
      ))}
    </div>
  </section>
);

AltPanels.propTypes = {
  items: PropTypes.array.isRequired,
  modifier: PropTypes.string,
};

AltPanels.defaultProps = {
  modifier: '',
};

export default AltPanels;
