import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps } from 'utilities';
// import { Layout } from 'containers';
// import Helmet from 'react-helmet';
// import { BASE_URL } from 'config';
import { FormInput, FormSelect, withFormGroup } from 'components';
import { SUBMIT_SALESFORCE_LEAD_AFTER } from 'actions/tours';
import { navigate } from 'gatsby';
import { ClipLoader } from 'react-spinners';

const Select = withFormGroup(FormSelect);

class ItineraryDownloadPerTourAfter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      whereVisiting: '',
      planVisiting: [],
      travelStyle: [],
      howLong: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPlanVisitChanged = this.onPlanVisitChanged.bind(this);
    this.onTravelStylesChanged = this.onTravelStylesChanged.bind(this);
    this.onWhereVisitingChanged = this.onWhereVisitingChanged.bind(this);
    this.onHowLongChanged = this.onHowLongChanged.bind(this);
  }

  onPlanVisitChanged(e) {
    const { planVisiting } = this.state;
    if (planVisiting.indexOf(e.currentTarget.value) > -1) {
      planVisiting.splice(planVisiting.indexOf(e.currentTarget.value), 1);
    } else {
      planVisiting.push(e.currentTarget.value);
    }
  }

  onWhereVisitingChanged(e) {
    this.setState({ whereVisiting: e.WhereVisiting });
  }

  onHowLongChanged(e) {
    this.setState({ howLong: e.HowLong });
  }

  onTravelStylesChanged(e) {
    const { travelStyle } = this.state;
    if (travelStyle.indexOf(e.currentTarget.value) > -1) {
      travelStyle.splice(travelStyle.indexOf(e.currentTarget.value), 1);
    } else {
      travelStyle.push(e.currentTarget.value);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { salesforce, absoluteSlug } = this.props;
    const {
      planVisiting,
      travelStyle,
      whereVisiting,
      howLong,
    } = this.state;
    this.setState({
      saving: true,
    }, async () => {
      const UPDATE_LEAD = await SUBMIT_SALESFORCE_LEAD_AFTER(salesforce, planVisiting, travelStyle, whereVisiting, howLong);
      if (UPDATE_LEAD && UPDATE_LEAD.success) {
        navigate(`${absoluteSlug}/itinerary-thank-you/`);
      }
    });
    this.setState({
      saving: true,
    });
  }

  render() {
    const {
      saving,
    } = this.state;
    // const Input = withFormGroup(FormInput);
    const destinations = [
      { name: '--None--', value: '', id: '' },
      { name: 'North Island Only', value: 'North Island Only', id: 'North Island Only' },
      { name: 'South Island Only', value: 'South Island Only', id: 'South Island Only' },
      { name: 'Both Islands', value: 'Both Islands', id: 'Both Islands' },
    ];
    const durations = [
      { name: '--None--', value: '', id: '' },
      { name: 'Up to 1 week', value: 'Up to 1 week', id: 'Up to 1 week' },
      { name: '1 - 2 weeks', value: '1 - 2 weeks', id: '1 - 2 weeks' },
      { name: '2 - 4 weeks', value: '2 - 4 weeks', id: '2 - 4 weeks' },
      { name: '4 weeks +', value: '4 weeks +', id: '4 weeks +' },
    ];
    return (
      <form className={`l-col ${saving ? 'itinerary-is-saving' : ''}`} action="" onSubmit={this.handleSubmit}>
        <label className="l-form-group__label">When are you planning to visit?</label>
        <div className="l-form-group">
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="ThisMonth"
              name="ThisMonth"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="This month"
              onChange={this.onPlanVisitChanged}
            />
            <label htmlFor="ThisMonth" className="l-form-group__label">
              This month
            </label>
          </div>
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="ThreeMonth"
              name="ThreeMonth"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="Within the next 3 months"
              onChange={this.onPlanVisitChanged}
            />
            <label htmlFor="ThreeMonth" className="l-form-group__label">
              Within the next 3 months
            </label>
          </div>
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="SixMonth"
              name="SixMonth"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="Within the next 6 months"
              onChange={this.onPlanVisitChanged}
            />
            <label htmlFor="SixMonth" className="l-form-group__label">
              Within the next 6 months
            </label>
          </div>
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="TwelveMonth"
              name="TwelveMonth"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="Within the next 12 months"
              onChange={this.onPlanVisitChanged}
            />
            <label htmlFor="TwelveMonth" className="l-form-group__label">
              Within the next 12 months
            </label>
          </div>
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="PlusTwelveMonth"
              name="PlusTwelveMonth"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="12+ months"
              onChange={this.onPlanVisitChanged}
            />
            <label htmlFor="PlusTwelveMonth" className="l-form-group__label">
              12+ months
            </label>
          </div>
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="NotSure"
              name="NotSure"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="Not sure yet"
              onChange={this.onPlanVisitChanged}
            />
            <label htmlFor="NotSure" className="l-form-group__label">
              Not sure yet
            </label>
          </div>
        </div>
        <Select
          id="WhereVisiting"
          name="WhereVisiting"
          label="Where are you planning on going?"
          onChange={this.onWhereVisitingChanged}
          options={destinations}
          required
        />
        <Select
          id="HowLong"
          name="HowLong"
          label="For How Long?"
          onChange={this.onHowLongChanged}
          options={durations}
          required
        />
        <label className="l-form-group__label">What’s your travel style? (select all that apply to you)</label>
        <div className="l-form-group">
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="Adrenaline"
              name="Adrenaline"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="Adrenaline Junkie"
              onChange={this.onTravelStylesChanged}
            />
            <label htmlFor="Adrenaline" className="l-form-group__label">
              Adrenaline Junkie
            </label>
          </div>
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="Cultural"
              name="Cultural"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="Cultural Experiences"
              onChange={this.onTravelStylesChanged}
            />
            <label htmlFor="Cultural" className="l-form-group__label">
              Cultural Experiences
            </label>
          </div>
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="Movie"
              name="Movie"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="Movie Buff"
              onChange={this.onTravelStylesChanged}
            />
            <label htmlFor="Movie" className="l-form-group__label">
              Movie Buff
            </label>
          </div>
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="Nature"
              name="Nature"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="Nature Fanatic"
              onChange={this.onTravelStylesChanged}
            />
            <label htmlFor="Nature" className="l-form-group__label">
              Nature Fanatic
            </label>
          </div>
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="Water"
              name="Water"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="Water Lover"
              onChange={this.onTravelStylesChanged}
            />
            <label htmlFor="Water" className="l-form-group__label">
              Water Lover
            </label>
          </div>
        </div>
        {saving && (
          <div className="loading-screen">
            <ClipLoader
              sizeUnit="px"
              size={50}
              color="#123abc"
            />
          </div>
        )}
        {!saving && (
          <button type="submit" value="Submit" className="c-button c-button--border">Submit</button>
        )}
      </form>
    );
  }
}

ItineraryDownloadPerTourAfter.propTypes = {
  salesforce: PropTypes.any.isRequired,
  absoluteSlug: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(ItineraryDownloadPerTourAfter);
