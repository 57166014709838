import React, { PureComponent } from 'react';
import { Link } from 'gatsby';
import { animateScroll } from 'react-scroll';
import moment from 'moment';
import { GET_ALL_REVIEWS } from 'actions/tours';
import {
  PAGES,
  PRIVATE_TOURS_URL,
  CONTACT,
  SOCIAL,
  SCROLL_CONFIG,
} from 'config';
import { FooterNav, SiteLogo, NewsletterForm } from 'components';
import StarRatings from 'react-star-ratings';
import SVGScrollArrowUp from '../../../public/svg/scroll-arrow-up.svg';
import SVGFeefo from '../../../public/svg/feefo-logo.svg';

const tourCategoryNav = [
  {
    id: 'NZ ADVENTURE TOURS',
    name: 'NZ ADVENTURE TOURS',
    path: '/adventure-tours/',
    external: true,
  },
  {
    id: 'HAKA PLUS TOURS',
    name: 'HAKA PLUS TOURS',
    path: '/haka-plus-tours/',
    external: true,
  },
  {
    id: 'NZ SNOW TOURS',
    name: 'NZ SNOW TOURS',
    path: '/new-zealand-snow-tours/',
    external: true,
  },
  {
    id: 'PRIVATE TOURS',
    name: 'PRIVATE TOURS',
    path: PRIVATE_TOURS_URL,
    external: true,
  },
  {
    id: 'INBOUND SERVICES',
    name: 'INBOUND SERVICES',
    path: '/about-us/inbound-tour-operator-services/',
    external: true,
  },
  // {
  //   id: 'GERMAN-SPEAKING TOURS',
  //   name: 'GERMAN-SPEAKING TOURS',
  //   path: 'https://anznaturetours.com/',
  //   external: true,
  // },
  {
    id: 'GET OUR BROCHURE',
    name: 'GET OUR BROCHURE',
    path: '/itinerary-download/?tour_id=all',
    external: true,
  },
  {
    id: 'VIEW ALL TOUR TYPES',
    name: 'VIEW ALL',
    path: '/tours/',
    external: true,
  },
];

const bestNZTours = [
  {
    id: '7 DAY SOUTH ISLAND LICK TOUR',
    name: '7 DAY SOUTH ISLAND LICK TOUR',
    path: '/adventure-tours/south-island/',
    external: true,
  },
  {
    id: '7 DAY COASTS & CULTURE',
    name: '7 DAY COASTS & CULTURE',
    path: '/adventure-tours/north-island/',
    external: true,
  },
  {
    id: '8 DAY NZ SNOW SAFARI',
    name: '8 DAY NZ SNOW SAFARI',
    path: '/new-zealand-snow-tours/south-island-snow-safari/',
    external: true,
  },
  {
    id: '16 DAY AMPLIFIED NZ TOUR',
    name: '16 DAY AMPLIFIED NZ TOUR',
    path: '/adventure-tours/two-weeks/',
    external: true,
  },
  {
    id: '20 DAY NEW ZEALAND UNCOVERED',
    name: '20 DAY NEW ZEALAND UNCOVERED',
    path: '/haka-plus-tours/20-day/',
    external: true,
  },
  // {
  //   id: '23 DAY EPIC NZ TOUR',
  //   name: '23 DAY EPIC NZ TOUR',
  //   path: '/adventure-tours/three-weeks/',
  //   external: true,
  // },
  {
    id: 'VIEW ALL TOURS',
    name: 'VIEW ALL',
    path: '/tours/',
    external: true,
  },
];

const destinations = [
  {
    id: 'HOBBITON MOVIE SET',
    name: 'HOBBITON MOVIE SET',
    path: '/destinations/matamata-hobbiton/',
    external: true,
  },
  {
    id: 'MILFORD SOUND',
    name: 'MILFORD SOUND',
    path: '/destinations/milford-sound/',
    external: true,
  },
  {
    id: 'QUEENSTOWN',
    name: 'QUEENSTOWN',
    path: '/destinations/queenstown/',
    external: true,
  },
  {
    id: 'FRANZ JOSEF',
    name: 'FRANZ JOSEF',
    path: '/destinations/franz-josef/',
    external: true,
  },
  {
    id: 'TONGARIRO CROSSING',
    name: 'TONGARIRO CROSSING',
    path: '/destinations/tongariro-national-park/',
    external: true,
  },
  {
    id: 'VIEW ALL DESTINATIONS',
    name: 'VIEW ALL',
    path: '/destinations/',
    external: true,
  },
];

const aboutNav = [
  {
    id: 'Contact Us',
    name: 'Contact Us',
    path: '/contact-us/',
    external: true,
  },
  {
    id: 'Our Trips',
    name: 'Our Trips',
    path: '/about-us/our-trips/',
    external: true,
  },
  {
    id: 'Our Purpose',
    name: 'Our Purpose',
    path: '/about-us/our-purpose/',
    external: true,
  },
  {
    id: 'Our People',
    name: 'Our People',
    path: '/about-us/our-people/',
    external: true,
  },
  {
    id: 'FAQS',
    name: 'FAQS',
    path: '/about-us/faqs/',
    external: true,
  },
  {
    id: 'Haka tours Blog',
    name: 'Haka tours Blog',
    path: 'https://hakatours.com/blog/',
    external: true,
  },
  {
    id: 'Reviews',
    name: 'Reviews',
    path: '/about-us/reviews/',
    external: true,
  },
  {
    id: 'TermsAndConditions',
    name: 'Bookings Conditions',
    path: '/booking-conditions/',
    external: true,
  },
  {
    id: 'PrivacyPolicy',
    name: 'Privacy Policy',
    path: '/privacy-policy',
    external: true,
  },
];

const familyNav = [
  {
    id: 'Haka Tourism Group Corporate Site',
    name: 'Haka Tourism Group Corporate Site',
    path: 'https://hakatourismgroup.com/',
    external: true,
  },
  {
    id: 'Haka Lodges',
    name: 'Haka Lodges',
    path: 'https://www.hakalodges.com/',
    external: true,
  },
  {
    id: 'Haka Sport Tours',
    name: 'Haka Sport Tours',
    path: 'https://hakasportstours.com/',
    external: true,
  },
  {
    id: 'Haka Private Tours',
    name: 'Haka Private Tours',
    path: 'https://hakatours.com/private-tours/',
    external: true,
  },
  {
    id: 'NZ Snow Tours',
    name: 'NZ Snow Tours',
    path: 'http://nzsnowtours.com/',
    external: true,
  },
  {
    id: 'Haka Educational Tours',
    name: 'Haka Educational Tours',
    path: 'https://hakaeducationaltours.com/',
    external: true,
  },
  {
    id: 'ANZ Nature Tours',
    name: 'ANZ Nature Tours',
    path: 'https://www.anznaturetours.com/',
    external: true,
  },
];

class Footer extends PureComponent {
  constructor() {
    super();
    this.state = {
      total: 0,
      avgRating: 5,
    };
  }

  componentWillMount() {
    (async () => {
      const ALL_REVIEWS = await GET_ALL_REVIEWS().catch((e) => {
        console.error(e.message);
      });
      if (ALL_REVIEWS) {
        this.setState({
          total: ALL_REVIEWS.count,
          avgRating: ALL_REVIEWS.rating,
        });
      }
    })();
  }

  render() {
    const {
      total,
      avgRating,
    } = this.state;

    return (
      <footer className="l-footer">
        <div className="l-footer__upper">
          <div className="l-container">
            <div className="l-footer__upper-grid">
              <div className="l-footer__brand">

                <SiteLogo />

                <address className="l-footer__contact">
                  <dl>
                    <div>
                      <dt>Call</dt>
                      <dd>
                        <a href={`tel:${CONTACT.PHONE}`}>
                          {CONTACT.PHONE}
                        </a>
                      </dd>
                    </div>
                    <div>
                      <dt>Email</dt>
                      <dd>
                        <a href={`mailto:${CONTACT.EMAIL}`}>
                          {CONTACT.EMAIL}
                        </a>
                      </dd>
                    </div>
                  </dl>
                  <p>
                    {CONTACT.ADDRESS}
                  </p>
                </address>
                <div className="l-footer__review">
                  <p>Average Customer Rating:</p>
                  <p>
                    <StarRatings
                      rating={avgRating}
                      starRatedColor="#FFDC00"
                      starDimension="26px"
                      starSpacing="0px"
                      numberOfStars={5}
                    />
                    <span className="review-rating">{avgRating}</span> <SVGFeefo />
                  </p>
                  <p className="review-sub">
                    Independent Service Rating - Based on {total} verified reviews.
                    <Link
                      to="/about-us/reviews/"
                      className="review-link"
                    >
                      Read all reviews
                    </Link>
                  </p>
                </div>
              </div>
              <div className="l-footer__shortcuts">
                <Link
                  to={PAGES.TOURS.PATH}
                  className="c-button c-button--border"
                >
                  View Tours
                </Link>
                <Link
                  to={PAGES.DESTINATIONS.PATH}
                  className="c-button c-button--border"
                >
                  View Destinations
                </Link>
                <Link
                  to={PAGES.ACTIVITIES.PATH}
                  className="c-button c-button--border"
                >
                  View Activities
                </Link>
              </div>
              <FooterNav title="Haka Tour Types" list={tourCategoryNav} modifier="hide-small-screen" />
              <FooterNav title="Best NZ Tours" list={bestNZTours} modifier="hide-small-screen" />
              <FooterNav title="Destinations" list={destinations} modifier="hide-small-screen" />
              <FooterNav title="About us" list={aboutNav} />
              <FooterNav title="Our family" list={familyNav} />
            </div>

            <NewsletterForm />

            <button
              type="button"
              className="l-footer__scroll"
              onClick={() => animateScroll.scrollTo(0, SCROLL_CONFIG)}
            >
              <SVGScrollArrowUp />
              <span>Top</span>
            </button>
          </div>
        </div>

        <div className="l-footer__lower">
          <div className="l-container">
            <div className="l-footer__lower-grid">
              <div className="l-footer__terms">
                <ul>
                  <li>
                    {`© ${moment().year()} Copyright Haka Tours`}
                  </li>
                </ul>
              </div>

              <div className="l-footer__social">
                <ul>
                  {Object.entries(SOCIAL).map(([KEY, VALUE]) => (
                    <li key={KEY}>
                      <a
                        className="c-icon"
                        href={VALUE.URL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {VALUE.ICON}
                        <span className="u-sr-only">
                          {VALUE.TITLE}
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
