import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';


const FooterNav = ({ title, list, modifier }) => (
  <nav
    className={`c-footer-nav ${modifier}`}
    role="navigation"
  >
    <span className="c-footer-nav__title">
      {title}
    </span>

    <ul>
      {list.map(item => (
        <li key={item.id}>
          {item.external ? (
            <a
              href={item.path}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.name}
            </a>
          ) : (
            <Link to={`${item.path}/`}>
              {item.name}
            </Link>
          )}
        </li>
      ))}
    </ul>
  </nav>
);

FooterNav.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  modifier: PropTypes.string,
};

FooterNav.defaultProps = {
  title: 'Tours',
  list: [],
  modifier: '',
};

export default FooterNav;
