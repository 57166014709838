import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { mapStateToProps } from 'utilities';
import { BASE_URL } from 'config';
import { FormInput, withFormGroup } from 'components';
import { SUBMIT_SALESFORCE_CONTACT_FORM } from 'actions/tours';
import { navigate } from 'gatsby';
import { ClipLoader } from 'react-spinners';
import Reaptcha from 'reaptcha';

const Input = withFormGroup(FormInput);

class ContactUsForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lastName: '',
      firstName: '',
      email: '',
      message: '',
      subscribe: false,
      saving: false,
      verified: false,
      adventureSelected: false,
      hakaPlusSelected: false,
      snowTourSelected: false,
      privateGroupSelected: false,
    };
    this.onLastNameChanged = this.onLastNameChanged.bind(this);
    this.onFirstNameChanged = this.onFirstNameChanged.bind(this);
    this.onEmailChanged = this.onEmailChanged.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSubscribeChanged = this.onSubscribeChanged.bind(this);
    this.onVerify = this.onVerify.bind(this);
    this.onAdventureSelected = this.onAdventureSelected.bind(this);
    this.onHakaPlusSelected = this.onHakaPlusSelected.bind(this);
    this.onSnowTourSelected = this.onSnowTourSelected.bind(this);
    this.onPrivateGroupSelected = this.onPrivateGroupSelected.bind(this);
    this.onMessageChanged = this.onMessageChanged.bind(this);
  }

  onVerify() {
    this.setState({ verified: true });
  }

  onAdventureSelected() {
    this.setState({ adventureSelected: true });
  }

  onHakaPlusSelected() {
    this.setState({ hakaPlusSelected: true });
  }

  onSnowTourSelected() {
    this.setState({ snowTourSelected: true });
  }

  onPrivateGroupSelected() {
    this.setState({ privateGroupSelected: true });
  }

  onLastNameChanged(e) {
    this.setState({ lastName: e.currentTarget.value });
  }

  onMessageChanged(e) {
    this.setState({ message: e.currentTarget.value });
  }

  onFirstNameChanged(e) {
    this.setState({ firstName: e.currentTarget.value });
  }

  onEmailChanged(e) {
    this.setState({ email: e.currentTarget.value });
  }

  onSubscribeChanged(e) {
    this.setState({ subscribe: e.currentTarget.checked });
  }

  handleSubmit(e) {
    e.preventDefault();
    const {
      lastName,
      firstName,
      email,
      subscribe,
      verified,
      adventureSelected,
      hakaPlusSelected,
      snowTourSelected,
      privateGroupSelected,
      message,
    } = this.state;
    const sourceUrl = `${BASE_URL}/contact-us/`;
    const FORM = e.currentTarget;
    if (!FORM.checkValidity()) return;
    if (!verified) return;
    this.setState({ saving: true });
    this.setState({
      saving: true,
    }, async () => {
      const NEW_LEAD = await SUBMIT_SALESFORCE_CONTACT_FORM(
        sourceUrl,
        firstName,
        lastName,
        email,
        subscribe,
        adventureSelected,
        hakaPlusSelected,
        snowTourSelected,
        privateGroupSelected,
        message,
      );
      if (NEW_LEAD && NEW_LEAD.success) {
        navigate('/contact-thank-you/');
      }
    });
    this.setState({
      saving: true,
    });
  }

  render() {
    const {
      lastName,
      firstName,
      email,
      saving,
    } = this.state;

    const {
      messageLabel,
      checkboxSection,
    } = this.props;

    const SALESFORCE_SITE_KEY = process.env.GATSBY_SALESFORCE_SITE_KEY;
    return (
      <form className={`l-col ${saving ? 'itinerary-is-saving' : ''}`} action="" onSubmit={this.handleSubmit}>
        <Input
          id="FirstName"
          name="FirstName"
          label="First Name &#42;"
          defaultValue={firstName}
          placeholder="First Name &#42;"
          onChange={this.onFirstNameChanged}
          required
        />
        <Input
          id="LastName"
          name="LastName"
          label="Last Name &#42;"
          defaultValue={lastName}
          placeholder="Last Name &#42;"
          onChange={this.onLastNameChanged}
          required
        />
        <Input
          id="Email"
          name="Email"
          label="Email &#42;"
          defaultValue={email}
          placeholder="Email &#42;"
          onChange={this.onEmailChanged}
          required
        />
        <Input
          id="Message"
          name="Message"
          type="textarea"
          label={messageLabel}
          onChange={this.onMessageChanged}
        />
        {(checkboxSection) && (
          <React.Fragment>
            <div className="title">
              <div className="title" contentid="title">
                <div>
                  <span className="msg-text"><b>PLEASE TELL US WHAT KIND OF ADVENTURE YOU WOULD LIKE TO HAVE:</b></span>
                </div>
              </div>
            </div>
            <div className="l-form-group l-form-group--checkbox">
              <FormInput
                type="checkbox"
                id="chck_adventure"
                name="Adventure"
                groupModifier="l-form-group--checkbox"
                modifier="c-form-control--checkbox"
                onChange={this.onAdventureSelected}
              />
              {/* eslint-disable-next-line */}
            <label htmlFor="Adventure" className="l-form-group__label">
              Adventure Tours
            </label>
            </div>
            <div className="l-form-group l-form-group--checkbox">
              <FormInput
                type="checkbox"
                id="chck_haka_plus"
                name="HakaPlus"
                groupModifier="l-form-group--checkbox"
                modifier="c-form-control--checkbox"
                onChange={this.onHakaPlusSelected}
              />
              {/* eslint-disable-next-line */}
            <label htmlFor="HakaPlus" className="l-form-group__label">
              Haka Plus
            </label>
            </div>
            <div className="l-form-group l-form-group--checkbox">
              <FormInput
                type="checkbox"
                id="chck_snow_tour"
                name="SnowTour"
                groupModifier="l-form-group--checkbox"
                modifier="c-form-control--checkbox"
                onChange={this.onSnowTourSelected}
              />
              {/* eslint-disable-next-line */}
            <label htmlFor="SnowTour" className="l-form-group__label">
              Snow Tours
            </label>
            </div>
            <div className="l-form-group l-form-group--checkbox">
              <FormInput
                type="checkbox"
                id="chck_private_group"
                name="PrivateGroup"
                groupModifier="l-form-group--checkbox"
                modifier="c-form-control--checkbox"
                onChange={this.onPrivateGroupSelected}
              />
              {/* eslint-disable-next-line */}
            <label htmlFor="PrivateGroup" className="l-form-group__label">
              Private Group Tours
            </label>
            </div>
            <div className="l-form-group l-form-group--checkbox">
              <FormInput
                type="checkbox"
                id="Subscribe"
                name="Subscribe"
                groupModifier="l-form-group--checkbox"
                modifier="c-form-control--checkbox"
                onChange={this.onSubscribeChanged}
              />
              {/* eslint-disable-next-line */}
            <label htmlFor="Subscribe" className="l-form-group__label">
              Yes, I would like to hear more from Haka Tours
            </label>
            </div>
          </React.Fragment>
        )}
        <Reaptcha sitekey={`${SALESFORCE_SITE_KEY}`} onVerify={this.onVerify} />
        <br />
        {saving && (
          <div className="loading-screen">
            <ClipLoader
              sizeUnit="px"
              size={50}
              color="#123abc"
            />
          </div>
        )}
        {!saving && (
          <button type="submit" value="Submit" className="c-button c-button--border">Submit</button>
        )}
      </form>
    );
  }
}

ContactUsForm.propTypes = {
  messageLabel: PropTypes.string,
  checkboxSection: PropTypes.bool,
};

ContactUsForm.defaultProps = {
  messageLabel: 'Message:',
  checkboxSection: true,
};

export default connect(mapStateToProps)(ContactUsForm);
