import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { parseVideo } from 'utilities';
import SVGVideoPlay from '../../../public/svg/video-play.svg';


const Slide = ({
  title,
  imageUrl,
  videoUrl,
  thumbnail,
  children,
  onPlayClick,
  linkTo,
}) => {
  const {
    channel,
    videoId,
    previewUrl,
  } = parseVideo(videoUrl);
  let lazyImg = (
    <a href={linkTo}>
      <LazyLoadImage
        src={previewUrl || imageUrl}
        effect="blur"
        wrapperClassName="lazy-img-wrapper"
        alt={title}
      />
    </a>
  );

  if (thumbnail) {
    lazyImg = (
      <img src={previewUrl || imageUrl} alt={title} />
    );
  }

  return (
    <div className="swiper-slide">
      {previewUrl || imageUrl ? lazyImg : null}

      {channel && videoId && !thumbnail ? (
        <button
          type="button"
          className="c-video-play-button"
          onClick={() => onPlayClick(channel, videoId)}
        >
          <SVGVideoPlay />
        </button>
      ) : null}

      {children}
    </div>
  );
};

Slide.propTypes = {
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  linkTo: PropTypes.string,
  thumbnail: PropTypes.bool,
  children: PropTypes.node,
  onPlayClick: PropTypes.func,
};

Slide.defaultProps = {
  title: '',
  imageUrl: '',
  videoUrl: '',
  linkTo: '',
  thumbnail: false,
  children: null,
  onPlayClick: () => { },
};

export default Slide;
