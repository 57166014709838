import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class FormLabel extends PureComponent {
  render() {
    const {
      inputID,
      title,
      modifier,
    } = this.props;
    /* eslint-disable jsx-a11y/label-has-for */
    return (
      <label
        htmlFor={inputID}
        className={`l-form-group__label ${modifier}`}
      >
        <span>{title}</span>
      </label>
    );
  }
}

FormLabel.propTypes = {
  inputID: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  modifier: PropTypes.string,
};

FormLabel.defaultProps = {
  modifier: '',
};

export default FormLabel;
