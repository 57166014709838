import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormInput, FormLabel, Modal } from 'components';
import showdown from 'showdown';
import { numberToCurrency } from 'utilities/helpers';

const CONVERTER = new showdown.Converter();
const TOUR_CODE_LIST_1 = ['AMPR43', 'AMPR44', 'AMP74'];
const TOUR_CODE_LIST_2 = [' AMP73', 'LEG94'];

class BookingAccommAddOn extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedNights: this.getSelectedNights() || 0,
      openErrorModal: false,
      errorMessage: undefined,
    };
    this.onNightsChange = this.onNightsChange.bind(this);
  }

  componentDidMount() {
    const { tourCode } = this.props;

    if (TOUR_CODE_LIST_1.includes(tourCode)) {
      this.setState({ errorMessage: '<h3>No availability</h3><p>Please note that your accommodation in the Coromandel has no upgrade availability. We’ll reach out to you directly to share further information on this.</p>' });
    } else if (TOUR_CODE_LIST_2.includes(tourCode)) {
      this.setState({ errorMessage: 'Please note that your accommodation in the Coromandel has limited upgrade availability. We’ll reach out to you directly to share further information on this.' });
    }
  }

  /**
   * Listen for nights input change
   * @param e
   */
  onNightsChange(e) {
    const { updateNights, addOn } = this.props;
    this.setState({
      selectedNights: e.currentTarget.value,
    });
    updateNights({
      isBefore: addOn.is_before,
      customID: addOn.customID,
    }, e.currentTarget.value);
  }

  /**
   * Get the selected number of nights
   * @returns {boolean}
   */
  getSelectedNights() {
    const { accommodations, addOn } = this.props;
    const MATCH = accommodations
      .find(accomm => accomm.customID === addOn.customID
        && accomm.is_before === addOn.is_before);
    return MATCH ? MATCH.nights : 0; // 0 is the default
  }

  /**
   * Get option input & label
   * @param options
   * @returns {*}
   */
  getInput(options) {
    const { update, addOn, isDisabled } = this.props;
    const { selectedNights } = this.state;

    let VALUE = [];

    options.forEach((item) => {
      for (let i = 0; i < item.amount; i += 1) {
        if (item.option_id) {
          VALUE.push(item.option_id);
        }
      }
    });

    VALUE = VALUE.join('-');

    const ID = `${addOn.customID}-${VALUE}`;

    const TITLE = options.map((item) => {
      const PLURAL = item.amount > 1 ? 's' : '';
      if (item.type.toLowerCase() === 'dorm') {
        return `${item.amount} x ${item.type} bed${PLURAL}`;
      }
      return `${item.amount} x ${item.type}${PLURAL}`;
    }).join(', ');

    const PRICE = options.map(item => item.amount * item.unit_price).reduce((accumulator, currentValue) => currentValue + accumulator);

    const DATA = {
      options,
      id: ID, // used to reference in app
      numNights: selectedNights,
      isBefore: addOn.is_before,
      customID: addOn.customID,
    };

    const CHECKED = this.isOptionSelected(ID, addOn.customID, PRICE);

    return (
      <>
        <FormInput
          id={ID}
          name={addOn.customID}
          type="checkbox"
          modifier="c-form-control--checkbox"
          value={VALUE}
          onChange={() => {
            update(DATA, CHECKED); // checked will toggle the input.checked attr
          }}
          attrs={{
            checked: CHECKED,
            disabled: isDisabled,
          }}
        />
        <label
          htmlFor={ID}
        >
          {TITLE.toLowerCase()} {PRICE === 0 ? '(included)' : `- ${numberToCurrency(PRICE)}`}
        </label>
      </>
    );
  }

  handleCloseErrorModal() {
    this.setState({ openErrorModal: false });
  }

  /**
   * Check if option is selected
   * @param id
   * @param customID
   * @param price
   * @returns {boolean}
   */
  isOptionSelected(id, customID, price) {
    const { accommodations } = this.props;
    const MATCH = accommodations.find(accomm => accomm.id === id);
    // if no other options in same group is selected and the option is free - select by default
    const GROUP = accommodations.find(accomm => accomm.customID === customID);
    return !!MATCH || (!GROUP && price === 0);
  }

  render() {
    const {
      addOn,
      numNights,
      enableNights,
      isDisabled,
      tourCode,
    } = this.props;

    const {
      openErrorModal,
      errorMessage,
    } = this.state;

    const PICTURE_URL = addOn.picture || false;

    return (
      <div className={`u-card c-booking-add-on ${PICTURE_URL ? 'c-booking-add-on--picture' : ''}`}>
        <div>
          <h4 className="c-heading c-heading--h4 c-booking-add-on__title">
            {addOn.custom_title}
          </h4>
          <div
            className="c-booking-add-on__description"
            dangerouslySetInnerHTML={{ __html: CONVERTER.makeHtml(addOn.description_markdown) }}
          />
          <fieldset>
            {numNights && enableNights
              ? (
                <div className="l-form-group l-form-group--radio">
                  <p>Nights:</p>
                  {
                    Array.from(Array(numNights).keys()).map(num => (
                      <div
                        aria-hidden="true"
                        className="l-form-group__control-wrapper"
                        key={num}
                      >
                        <FormInput
                          id={`NumNights-${num}-${addOn.customID}`}
                          name={`NumNights-${addOn.customID}`}
                          type="radio"
                          value={num}
                          modifier="c-form-control--radio"
                          onChange={this.onNightsChange}
                          defaultChecked={(num) === this.getSelectedNights()}
                          disabled={isDisabled}
                        />
                        <FormLabel inputID={`NumNights-${num}-${addOn.customID}`} title={`${num}`} />
                      </div>
                    ))
                  }
                </div>
              ) : null
            }
            <div className="l-form-group l-form-group--radio-list">
              {addOn.options.map(option => (
                <div
                  aria-hidden="true"
                  className="l-form-group__control-wrapper"
                  key={option[0].option_id}
                  onClick={() => {
                    if (TOUR_CODE_LIST_1.concat(TOUR_CODE_LIST_2).includes(tourCode)) {
                      this.setState({ openErrorModal: true });
                    }
                  }}
                >
                  {this.getInput(option)}
                </div>
              ))}
            </div>
          </fieldset>
          <Modal
            open={openErrorModal}
            closeHandler={() => {
              this.handleCloseErrorModal();
            }}
          >
            <div className="c-activity-modal">
              <div className="l-container" style={{ padding: '2rem' }}>
                <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
              </div>
            </div>
          </Modal>
        </div>
        {PICTURE_URL
          ? (
            <picture>
              <img
                src={`${PICTURE_URL}?w=380&auto=format`}
                alt={addOn.name}
                className="c-booking-add-on--img"
              />
            </picture>
          ) : null
        }
      </div>
    );
  }
}

BookingAccommAddOn.propTypes = {
  addOn: PropTypes.any.isRequired,
  numNights: PropTypes.number,
  enableNights: PropTypes.bool,
  update: PropTypes.func.isRequired,
  updateNights: PropTypes.func.isRequired,
  accommodations: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool,
  tourCode: PropTypes.string,
};

BookingAccommAddOn.defaultProps = {
  numNights: 0,
  enableNights: true,
  isDisabled: false,
  tourCode: '',
};

export default BookingAccommAddOn;
