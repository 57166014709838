import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'components';
import SVGSnow from '../../../public/svg/icons/snow.svg';
import SVGAdventure from '../../../public/svg/icons/adventure.svg';
import SVGMTB from '../../../public/svg/icons/mtb.svg';

const showdown = require('showdown');

const converter = new showdown.Converter();

class PersonCard extends PureComponent {
  constructor() {
    super();
    this.state = {
      modalOpen: false,
    };
    this.onOpenHandler = this.onOpenHandler.bind(this);
    this.onCloseHandler = this.onCloseHandler.bind(this);
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  onOpenHandler(e) {
    e.preventDefault();
    this.setState({
      modalOpen: true,
    });
  }

  onCloseHandler() {
    this.setState({
      modalOpen: false,
    });
  }

  escFunction(e) {
    if (e.keyCode === 27) {
      this.onCloseHandler();
    }
  }

  render() {
    const { person } = this.props;
    const { modalOpen } = this.state;
    return (
      <div className="c-person-card" key={person.id}>
        <button
          type="button"
          className="u-reset-btn c-person-card__btn"
          onClick={this.onOpenHandler}
        >
          <img
            className="c-person-card__img"
            src={`${person.image_url}?fit=crop&w=384&h=384&auto=format`}
            alt={person.name}
            width="330"
            height="330"
          />
          <span className="c-person-card__icons">
            {person.mtb ? <SVGMTB /> : null}
            {person.adventure ? <SVGAdventure /> : null}
            {person.snow ? <SVGSnow /> : null}
          </span>
          <span className="c-person-card__details">
            <h4 className="c-heading c-heading--h3">{person.name}</h4>
          </span>
        </button>
        <Modal
          open={modalOpen}
          closeHandler={this.onCloseHandler}
          modifier="c-modal--overflow"
        >
          <div className="c-person-card__modal">
            <img
              className="c-person-card__modal__img"
              src={`${person.image_url}?fit=crop&w=384&h=384&auto=format`}
              alt={person.name}
              width="384"
              height="384"
            />
            <div className="c-person-card__modal__details">
              <h4 className="c-heading c-heading--h4">{person.name}</h4>
              <p dangerouslySetInnerHTML={{ __html: converter.makeHtml(person.description_markdown) }} />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

PersonCard.propTypes = {
  person: PropTypes.any.isRequired,
};

export default PersonCard;
