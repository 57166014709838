import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { STATES } from 'config';
import { isTabletScreen } from 'utilities';
import { SubNavBasic } from 'components';
import SVGArrow from '../../../public/svg/sub-nav-arrow.svg';

const BODY_ACTIVE_CLASS = 's-mob-nav-active';

class SubNav extends PureComponent {
  constructor() {
    super();

    this.state = {
      subNav: {},
    };

    this.onSubNavClick = this.onSubNavClick.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.rawMenuClick = this.rawMenuClick.bind(this);
  }


  onSubNavClick(e, id) {
    if (isTabletScreen(window)) e.preventDefault();

    this.setState(prevState => ({
      subNav: {
        ...prevState.subNav,
        [id]: !prevState.subNav[id],
      },
    }));
  }


  onBackClick() {
    this.setState({
      subNav: {},
    });
  }

  onMenuClick(e) {
    if (window.matchMedia('(max-width: 1200px)').matches && window.matchMedia('(min-width: 767px)').matches) {
      e.preventDefault();
    }
    window.requestAnimationFrame(() => document.body.classList.remove(BODY_ACTIVE_CLASS));
    this.removeDocumentTop();
  }

  rawMenuClick() {
    window.requestAnimationFrame(() => document.body.classList.remove(BODY_ACTIVE_CLASS));
    this.removeDocumentTop();
  }

  removeDocumentTop() {
    document.body.style.top = '0';
    window.requestAnimationFrame(() => window.scrollTo(0, this.offsetY));
  }


  render() {
    const { subNav } = this.state;

    const {
      list,
      childListKey,
      isActive,
      limitedAmount,
      onBackClick,
    } = this.props;

    if (!list.length) return null;

    return (
      <div className={`c-sub-nav ${isActive ? STATES.ACTIVE : ''}`}>
        <button
          type="button"
          className="c-sub-nav__back"
          onClick={onBackClick}
        >
          <SVGArrow />
          Back
        </button>

        <ul className="c-sub-nav__list">
          {list.map(item => (
            <li
              key={item.id}
              className="c-sub-nav__item"
            >
              {item.absolute_slug ? (
                <div className="c-nav__main">
                  <Link
                    to={`${item.absolute_slug}/`}
                    className="c-sub-nav__link"
                    onClick={this.rawMenuClick}
                  >
                    {item.name}
                  </Link>
                  {childListKey && item[childListKey] ? (
                    <button
                      type="button"
                      className="c-sub-nav__link-arrow"
                      onClick={this.rawMenuClick}
                    >
                      <SVGArrow />
                    </button>
                  ) : null}
                </div>
              ) : (
                <div className="c-sub-nav__link">
                  {item.name}
                  {childListKey && item[childListKey] ? (
                    <button
                      type="button"
                      className="c-sub-nav__link-arrow"
                      onClick={e => this.onSubNavClick(e, item.id)}
                    >
                      <SVGArrow />
                    </button>
                  ) : null}
                </div>
              )}

              {childListKey && item[childListKey] ? (
                <SubNavBasic
                  list={limitedAmount ? item[childListKey].slice(0, 8) : item[childListKey]}
                  title={item.name}
                  isActive={!!subNav[item.id]}
                  onBackClick={this.onBackClick}
                  onMenuClick={this.rawMenuClick}
                />
              ) : null}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

SubNav.propTypes = {
  list: PropTypes.array.isRequired,
  childListKey: PropTypes.string,
  isActive: PropTypes.bool,
  limitedAmount: PropTypes.bool,
  onBackClick: PropTypes.func,
};

SubNav.defaultProps = {
  childListKey: null,
  isActive: false,
  limitedAmount: false,
  onBackClick: () => {},
};

export default SubNav;
