import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import SVGCross from '../../../public/svg/cross-thin.svg';

class Modal extends React.Component {
  shouldComponentUpdate(nextProps) {
    const {
      children,
      open,
      closeHandler,
      modifier,
    } = this.props;

    return nextProps.children !== children
          || nextProps.open !== open
          || nextProps.closeHandler !== closeHandler
          || nextProps.modifier !== modifier;
  }

  render() {
    const {
      children, open, closeHandler, modifier,
    } = this.props;
    let modal = null;
    if (typeof document === 'undefined') return null;

    if (open) {
      modal = (
        <div
          className={`c-modal ${modifier} ${open ? 'is-active' : ''}`}
        >
          <button
            className="c-modal__bg-close-btn"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              if (closeHandler && typeof closeHandler === 'function') closeHandler(e);
            }}
          >
            <span className="u-sr-only">Close</span>
          </button>
          <div className="c-modal__content">
            <button
              type="button"
              className="c-modal__close-btn"
              onClick={(e) => {
                e.preventDefault();
                if (closeHandler && typeof closeHandler === 'function') closeHandler(e);
              }}
            >
              <span className="u-sr-only">Close</span>
              <SVGCross />
            </button>
            <div className="c-modal__content-inner">
              {children}
            </div>
          </div>
        </div>
      );
    }

    return ReactDOM.createPortal(
      modal,
      document.getElementById('modal-root'),
    );
  }
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  closeHandler: PropTypes.func.isRequired,
  modifier: PropTypes.string,
};

Modal.defaultProps = {
  modifier: '',
};

export default Modal;
