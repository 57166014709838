import React, { Component } from 'react';
import PropTypes from 'prop-types';


class ErrorBoundary extends Component {
  constructor() {
    super();

    this.state = {
      hasError: false,
      errorMessage: '',
    };
  }


  componentDidCatch(error, errorInfo) {
    console.log(errorInfo);// eslint-disable-line

    this.setState({
      hasError: true,
      errorMessage: error.message,
    });
  }


  render() {
    const { children } = this.props;
    const { hasError, errorMessage } = this.state;

    if (hasError) {
      // render any custom fallback UI
      return (
        <div>
          <h1>Something went wrong!</h1>
          <p>
            {errorMessage}
          </p>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
