import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';

import { PAGES } from 'config';
import { mapStateToProps } from 'utilities';
import { SIGN_OUT_USER } from 'actions/user';
import { SubNavBasic } from 'components';
import SVGUser from '../../../public/svg/user.svg';


const USER_NAV_SIGNED_OUT = [
  {
    id: 1,
    name: 'Sign in',
    absolute_slug: PAGES.SIGN_IN.PATH,
  },
  {
    id: 2,
    name: 'Create account',
    absolute_slug: PAGES.SIGN_UP.PATH,
  },
];
const USER_NAV_SIGNED_IN = [
  {
    id: 1,
    name: 'My tours',
    absolute_slug: PAGES.USER.PATH,
  },
  {
    id: 2,
    name: 'My favourites',
    absolute_slug: PAGES.USER_FAVOURITES.PATH,
  },
  {
    id: 3,
    name: 'Edit Account',
    absolute_slug: PAGES.USER_EDIT.PATH,
  },
];


class AccountNav extends PureComponent {
  constructor() {
    super();

    this.handleSignOut = this.handleSignOut.bind(this);
  }


  /**
   * Handle sign out
   */
  handleSignOut(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    SIGN_OUT_USER(dispatch);
    navigate('/');
  }


  /**
   * Render
   */
  render() {
    const { user } = this.props;

    // add total bookings to nav item
    if (user.authenticated) {
      const NUM_BOOKINGS = user.bookings.bookings.length;
      USER_NAV_SIGNED_IN[0].name = `My tours ${NUM_BOOKINGS ? `(${NUM_BOOKINGS})` : ''}`;
    }

    // add total bookings to nav item
    if (user.authenticated) {
      const NUM_FAVS = user.favorites.activities.length;
      USER_NAV_SIGNED_IN[1].name = `My favourites ${NUM_FAVS ? `(${NUM_FAVS})` : ''}`;
    }

    return (
      <div className="l-header__contact">
        <Link
          to={PAGES.CONTACT.PATH}
          className="c-button c-button--border c-button--small"
        >
          Contact Us
        </Link>

        <div className="l-header__user-nav">
          <Link
            to={(user.authenticated) ? PAGES.USER.PATH : PAGES.SIGN_IN.PATH}
            className="c-icon"
          >
            <span className="u-sr-only">Sign in</span>
            <SVGUser />
          </Link>

          <SubNavBasic list={(user.authenticated ? USER_NAV_SIGNED_IN : USER_NAV_SIGNED_OUT)}>
            {user.authenticated && (
              <li className="c-sub-nav-basic__item">
                <button
                  type="button"
                  className="c-sub-nav-basic__link"
                  onClick={this.handleSignOut}
                >
                  Sign out
                </button>
              </li>
            )}
          </SubNavBasic>
        </div>
      </div>
    );
  }
}

AccountNav.propTypes = {
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AccountNav);
