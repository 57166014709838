import React from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';

import { STATES } from 'config';
import { FormSelect } from 'components';

const PageNav = ({ pages }) => (
  <nav className="l-page-nav">
    <form action="">
      <FormSelect
        id="Page"
        name="page"
        value={(() => {
          if (typeof window === 'undefined') return '';
          const MATCH = pages.find(page => window.location.pathname.includes(page.PATH));
          if (MATCH) return MATCH.PATH;
          return '';
        })()}
        onChange={(option) => {
          if (option && option.page) {
            navigate(option.page);
          }
        }}
        options={pages.map(page => ({
          id: page.PATH,
          name: page.TITLE,
          value: page.PATH,
        }))}
      />
    </form>
    <ul>
      {pages.map(page => (
        <li key={page.PATH}>
          <Link to={page.PATH} activeClassName={STATES.ACTIVE}>
            {page.ICON}
            {page.TITLE}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);

PageNav.propTypes = {
  pages: PropTypes.array.isRequired,
};

export default PageNav;
