import { REQUEST, RESPONSE_HANDLER } from 'utilities';

/**
 * Get departures for a tour
 * @param tourID
 * @returns {Promise<*>}
 * @constructor
 */
export const GET_TOUR_DEPARTURES = async (tourID) => { // eslint-disable-line
  const GET = await REQUEST.get(`tour_info/departures?tour_id=${tourID}?minimal_data=true`);
  const RESPONSE = await RESPONSE_HANDLER(GET);
  if (RESPONSE.success) {
    const NORMALIZE_DATA = [
      ...RESPONSE.data['tour_info/departures'],
    ];
    // overwrite raw response data
    RESPONSE.data = NORMALIZE_DATA;
  }
  return RESPONSE;
};

/**
 * Get last deal departures
 * @returns {Promise<*>}
 * @constructor
 */
export const GET_LAST_DEAL_DEPARTURES = async () => { // eslint-disable-line
  const GET = await REQUEST.get('tour_info/departures?last_deal=true&minimal_data=true');
  const RESPONSE = await RESPONSE_HANDLER(GET);
  if (RESPONSE.success) {
    const NORMALIZE_DATA = [
      ...RESPONSE.data['tour_info/departures'],
    ];
    // overwrite raw response data
    RESPONSE.data = NORMALIZE_DATA;
  }
  return RESPONSE;
};

export const GET_REVIEW = async (tourID) => {
  const GET = await REQUEST.get(`tour_info/tours/${tourID}/get_review`);
  const RESPONSE = await RESPONSE_HANDLER(GET);
  let starRating = 0;
  if (RESPONSE.success) {
    starRating = RESPONSE.data;
  }
  return starRating;
};

export const GET_ALL_REVIEWS = async () => {
  const GET = await REQUEST.get('tour_info/tours/get_all_reviews');
  const RESPONSE = await RESPONSE_HANDLER(GET);
  let starRating = 0;
  if (RESPONSE.success) {
    starRating = RESPONSE.data;
  }
  return starRating;
};

export const GET_IMPORTED_REVIEW_DETAILS = async (tourID, rating, timeFrame, page) => {
  const GET = await REQUEST.get(`tour_info/tours/get_imported_review_details?tour_id=${tourID}&rating=${rating}&timeFrame=${timeFrame}&page=${page}`);
  const RESPONSE = await RESPONSE_HANDLER(GET);
  let reviewsList = [];
  if (RESPONSE.success) {
    reviewsList = RESPONSE.data;
  }
  return reviewsList;
};

export const GET_REVIEW_DETAILS = async (tourID, rating, timeFrame, page) => {
  const GET = await REQUEST.get(`tour_info/tours/get_review_details?tour_id=${tourID}&rating=${rating}&timeFrame=${timeFrame}&page=${page}`);
  const RESPONSE = await RESPONSE_HANDLER(GET);
  let reviewsList = [];
  if (RESPONSE.success) {
    reviewsList = RESPONSE.data;
  }
  return reviewsList;
};

/**
 * Get ALL departures
 * @returns {Promise<*>}
 * @constructor
 */
export const GET_DEPARTURES = async () => {
  const GET = await REQUEST.get('tour_info/departures?archived=false');
  const RESPONSE = await RESPONSE_HANDLER(GET);
  if (RESPONSE.success) {
    const NORMALIZE_DATA = [
      ...RESPONSE.data['tour_info/departures'],
    ];
    // overwrite raw response data
    RESPONSE.data = NORMALIZE_DATA;
  }
  return RESPONSE;
};

/**
 * Get VALID departures
 * @returns {Promise<*>}
 * @constructor
 */
export const GET_VALID_DEPARTURES = async () => {
  const GET = await REQUEST.get('tour_info/departures?valid_departure=true');
  const RESPONSE = await RESPONSE_HANDLER(GET);
  if (RESPONSE.success) {
    const NORMALIZE_DATA = [
      ...RESPONSE.data['tour_info/departures'],
    ];
    // overwrite raw response data
    RESPONSE.data = NORMALIZE_DATA;
  }
  return RESPONSE;
};

/**
 * Get a single departure
 * @returns {Promise<*>}
 * @constructor
 */
export const GET_DEPARTURE = async (departureID) => {
  const GET = await REQUEST.get(`tour_info/departures/${departureID}`);
  const RESPONSE = await RESPONSE_HANDLER(GET);
  if (RESPONSE.success) {
    const NORMALIZE_DATA = RESPONSE.data['tour_info/departure'];
    // overwrite raw response data
    RESPONSE.data = NORMALIZE_DATA;
  }
  return RESPONSE;
};

export const SUBMIT_SALESFORCE_LEAD_ALL_ITINERARY = async (
  sourceUrl,
  firstName,
  lastName,
  userEmail,
  userPhone,
  userSubscribe,
  adventureSelected,
  hakaPlusSelected,
  snowTourSelected,
  privateGroupSelected,
) => {
  const CREATE_LEAD_ALL_ITINERARY = await REQUEST.post('third_party/salesforce', {
    json: {
      source_url: sourceUrl,
      first_name: firstName,
      last_name: lastName,
      email: userEmail,
      phone: userPhone,
      subscribe: userSubscribe,
      adventure_selected: adventureSelected,
      hakaPlus_selected: hakaPlusSelected,
      snowTour_selected: snowTourSelected,
      privateGroup_selected: privateGroupSelected,
      type: 'init_all_lead',
    },
  });
  const RESPONSE = await RESPONSE_HANDLER(CREATE_LEAD_ALL_ITINERARY);
  return RESPONSE;
};

export const SUBMIT_SALESFORCE_CONTACT_FORM = async (
  sourceUrl,
  firstName,
  lastName,
  userEmail,
  userSubscribe,
  adventureSelected,
  hakaPlusSelected,
  snowTourSelected,
  privateGroupSelected,
  message,
) => {
  const CREATE_LEAD_ALL_ITINERARY = await REQUEST.post('third_party/salesforce', {
    json: {
      source_url: sourceUrl,
      first_name: firstName,
      last_name: lastName,
      email: userEmail,
      subscribe: userSubscribe,
      adventure_selected: adventureSelected,
      hakaPlus_selected: hakaPlusSelected,
      snowTour_selected: snowTourSelected,
      privateGroup_selected: privateGroupSelected,
      message_text: message,
      type: 'contact_us_lead',
    },
  });
  const RESPONSE = await RESPONSE_HANDLER(CREATE_LEAD_ALL_ITINERARY);
  return RESPONSE;
};

export const SUBMIT_SALESFORCE_LEAD = async (
  dispatch,
  sourceUrl,
  firstName,
  lastName,
  userEmail,
  userPhone,
  userSubscribe,
  tourId,
) => {
  const OPTIONS = {
    FirstName: firstName,
    LastName: lastName,
    Email: userEmail,
    Phone: userPhone,
    Web_Lead_Type__c: 'Sample Itinerary',
    Source_URL__c: sourceUrl,
    RecordTypeId: '0122v000001q0nm',
    LeadSource: 'Web - hakatours.com',
    Tour_Admin_Id__c: tourId,
    Subscribed__c: userSubscribe,
  };
  const CREATE_LEAD = await REQUEST.post('third_party/salesforce', {
    json: {
      source_url: sourceUrl,
      first_name: firstName,
      last_name: lastName,
      email: userEmail,
      phone: userPhone,
      subscribe: userSubscribe,
      tour_id: tourId,
      type: 'init',
    },
  });
  const RESPONSE = await RESPONSE_HANDLER(CREATE_LEAD);
  if (RESPONSE.success) {
    await dispatch({
      type: 'SET_SALESFORCE_LEAD_DATA',
      payload: OPTIONS,
    });
  }
  return RESPONSE;
};

export const SUBMIT_SALESFORCE_LEAD_SNOW_AFTER = async (salesforce, planVisiting, snowAbilityLevel, gearOption) => {
  const UPDATE_LEAD = await REQUEST.post('third_party/salesforce', {
    json: {
      source_url: salesforce.Source_URL__c,
      first_name: salesforce.FirstName,
      last_name: salesforce.LastName,
      email: salesforce.Email,
      phone: salesforce.Phone,
      subscribe: salesforce.Subscribed__c,
      tour_id: salesforce.Tour_Admin_Id__c,
      when_visiting: planVisiting.join(';'),
      snow_ability_level: snowAbilityLevel,
      ski_or_board: gearOption,
      type: 'post_snow',
    },
  });
  const RESPONSE = await RESPONSE_HANDLER(UPDATE_LEAD);
  return RESPONSE;

  // let authString = 'https://test.salesforce.com/services/oauth2/token?grant_type=password';
  // authString += '&client_id=3MVG959Nd8JMmavTMXRaVlFZLZzo0KwrnDH2MHfOsuZ7cGBmFo9p7iv7gwtOA4HEksKcJI2VH9TfSk4X9L4wU';
  // authString += '&client_secret=84023403359A2410CD50963C97C7F755B7643ED945C9B2F3ECF67432581326C3';
  // authString += '&username=integration@hakatours.com.dev&password=Google12';
  // const CREATE_AUTH = await SALESFORCE_REQUEST.post(authString);
  // const AUTH = await RESPONSE_HANDLER(CREATE_AUTH);
  // if (AUTH && AUTH.success) {
  //   const OPTIONS = {
  //     json: {
  //       FirstName: salesforce.FirstName,
  //       LastName: salesforce.LastName,
  //       Email: salesforce.Email,
  //       Phone: salesforce.Phone,
  //       Web_Lead_Type__c: 'Sample Itinerary Post-Download',
  //       Source_URL__c: 'https://new-staging.hakatours.com/itinerary-download/',
  //       RecordTypeId: '0122v000001q0nm',
  //       LeadSource: 'Web - hakatours.com',
  //       Tour_Admin_Id__c: salesforce.Tour_Admin_Id__c,
  //       Subscribed__c: salesforce.Subscribed__c,
  //       When_Visiting__c: planVisiting.join(';'),
  //       Snow_Ability_Level__c: snowAbilityLevel,
  //       Ski_or_Board__c: gearOption,
  //     },
  //   };
  //   OPTIONS.headers = {
  //     Authorization: `Bearer ${AUTH.data.access_token}`,
  //   };
  //   const UPDATE_LEAD = await SALESFORCE_REQUEST.post('https://hakatours--dev.my.salesforce.com/services/data/v47.0/sobjects/Lead/', OPTIONS);
  //   const RESULT = await RESPONSE_HANDLER(UPDATE_LEAD);
  //   return RESULT;
  // }
  // return 'failed';
};

export const SUBMIT_SALESFORCE_LEAD_AFTER = async (salesforce, planVisiting, travelStyle, whereVisiting, howLong) => {
  const UPDATE_LEAD = await REQUEST.post('third_party/salesforce', {
    json: {
      source_url: salesforce.Source_URL__c,
      first_name: salesforce.FirstName,
      last_name: salesforce.LastName,
      email: salesforce.Email,
      phone: salesforce.Phone,
      subscribe: salesforce.Subscribed__c,
      tour_id: salesforce.Tour_Admin_Id__c,
      when_visiting: planVisiting.join(';'),
      where_visiting: whereVisiting,
      how_long_visiting: howLong,
      travel_style: travelStyle.join(';'),
      type: 'post',
    },
  });
  const RESPONSE = await RESPONSE_HANDLER(UPDATE_LEAD);
  return RESPONSE;

  // let authString = 'https://test.salesforce.com/services/oauth2/token?grant_type=password';
  // authString += '&client_id=3MVG959Nd8JMmavTMXRaVlFZLZzo0KwrnDH2MHfOsuZ7cGBmFo9p7iv7gwtOA4HEksKcJI2VH9TfSk4X9L4wU';
  // authString += '&client_secret=84023403359A2410CD50963C97C7F755B7643ED945C9B2F3ECF67432581326C3';
  // authString += '&username=integration@hakatours.com.dev&password=Google12';
  // const CREATE_AUTH = await SALESFORCE_REQUEST.post(authString);
  // const AUTH = await RESPONSE_HANDLER(CREATE_AUTH);
  // if (AUTH && AUTH.success) {
  //   const OPTIONS = {
  //     json: {
  //       FirstName: salesforce.FirstName,
  //       LastName: salesforce.LastName,
  //       Email: salesforce.Email,
  //       Phone: salesforce.Phone,
  //       Web_Lead_Type__c: 'Sample Itinerary Post-Download',
  //       Source_URL__c: 'https://new-staging.hakatours.com/itinerary-download/',
  //       RecordTypeId: '0122v000001q0nm',
  //       LeadSource: 'Web - hakatours.com',
  //       Tour_Admin_Id__c: salesforce.Tour_Admin_Id__c,
  //       Subscribed__c: salesforce.Subscribed__c,
  //       When_Visiting__c: planVisiting.join(';'),
  //       Where_Visiting__c: whereVisiting,
  //       How_Long_Visiting__c: howLong,
  //       Travel_Interest__c: travelStyle.join(';'),
  //     },
  //   };
  //   OPTIONS.headers = {
  //     Authorization: `Bearer ${AUTH.data.access_token}`,
  //   };
  //   const UPDATE_LEAD = await SALESFORCE_REQUEST.post('https://hakatours--dev.my.salesforce.com/services/data/v47.0/sobjects/Lead/', OPTIONS);
  //   const RESULT = await RESPONSE_HANDLER(UPDATE_LEAD);
  //   return RESULT;
  // }
  // return 'failed';
};

// export const SUBMIT_SALESFORCE_LEAD = async (dispatch, sourceUrl, firstName, lastName, email, phone, subscribe, tourId) => {
//   let authString = 'https://test.salesforce.com/services/oauth2/token?grant_type=password';
//   authString += '&client_id=3MVG959Nd8JMmavTMXRaVlFZLZzo0KwrnDH2MHfOsuZ7cGBmFo9p7iv7gwtOA4HEksKcJI2VH9TfSk4X9L4wU';
//   authString += '&client_secret=84023403359A2410CD50963C97C7F755B7643ED945C9B2F3ECF67432581326C3';
//   authString += '&username=integration@hakatours.com.dev&password=Google12';
//   const CREATE_AUTH = await SALESFORCE_REQUEST.post(authString);
//   const AUTH = await RESPONSE_HANDLER(CREATE_AUTH);
//   if (AUTH && AUTH.success) {
//     const OPTIONS = {
//       json: {
//         FirstName: firstName,
//         LastName: lastName,
//         Email: email,
//         Phone: phone,
//         Web_Lead_Type__c: 'Sample Itinerary',
//         Source_URL__c: sourceUrl,
//         RecordTypeId: '0122v000001q0nm',
//         LeadSource: 'Web - hakatours.com',
//         Tour_Admin_Id__c: tourId,
//         Subscribed__c: subscribe,
//       },
//     };
//     OPTIONS.headers = {
//       Authorization: `Bearer ${AUTH.data.access_token}`,
//     };
//     const CREATE_LEAD = await SALESFORCE_REQUEST.post('https://hakatours--dev.my.salesforce.com/services/data/v47.0/sobjects/Lead/', OPTIONS);
//     const RESULT = await RESPONSE_HANDLER(CREATE_LEAD);
//     if (RESULT && RESULT.success) {
//      await dispatch({
//        type: 'SET_SALESFORCE_LEAD_DATA',
//        payload: OPTIONS.json,
//      });
//     }
//     return RESULT;
//   }
//   return 'failed';
// };
