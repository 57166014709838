import React, { PureComponent } from 'react';
import { Element } from 'react-scroll';
import { StaticQuery } from 'gatsby';
import {
  FormSelect,
  withFormGroup,
} from 'components';
import { FORMAT_GRAPH_DATA } from 'utilities';
import { GET_REVIEW_DETAILS, GET_ALL_REVIEWS, GET_IMPORTED_REVIEW_DETAILS } from 'actions/tours';
import { ClipLoader } from 'react-spinners';
import StarRatings from 'react-star-ratings';
import ReadMoreAndLess from 'react-read-more-less';
import SVGFeefo from '../../../public/svg/feefo-logo.svg';

class ReviewList extends PureComponent {
  constructor() {
    super();
    this.onTourChanged = this.onTourChanged.bind(this);
    this.onRatingChanged = this.onRatingChanged.bind(this);
    this.onTimeFrameChanged = this.onTimeFrameChanged.bind(this);
    this.loadMoreItems = this.loadMoreItems.bind(this);
    this.state = {
      selectedTour: null,
      selectRating: null,
      selectedTimeFrame: 'most-recent',
      list: [],
      loading: false,
      total: 0,
      avgRating: 5,
      totalPage: 0,
      currentPage: 0,
      currentPageNext: 0,
      totalPageNext: 0,
      loadingMore: false,
    };
  }

  componentWillMount() {
    this.setState({
      loading: true,
    }, async () => {
      const IMPORTED_REVIEWS = await GET_IMPORTED_REVIEW_DETAILS(null, null, 'most-recent', 1);
      const REVIEWS = await GET_REVIEW_DETAILS(null, null, 'most-recent', 1);
      const ALL_REVIEWS = await GET_ALL_REVIEWS();
      if (REVIEWS) {
        this.setState({
          totalPage: REVIEWS.summary.meta.pages,
          currentPage: REVIEWS.summary.meta.current_page,
          list: REVIEWS.reviews,
          loading: false,
        });
      }
      if (IMPORTED_REVIEWS) {
        this.setState({
          totalPageNext: IMPORTED_REVIEWS.summary.meta.pages,
          loading: false,
        });
      }
      if (ALL_REVIEWS) {
        this.setState({
          total: ALL_REVIEWS.count,
          avgRating: ALL_REVIEWS.rating,
        });
      }
    });
  }

  onTimeFrameChanged(value) {
    const { selectedTour, selectRating } = this.state;
    this.setState({
      selectedTimeFrame: value.timeFrame === '' ? null : value.timeFrame,
    });
    this.setState({
      loading: true,
    }, async () => {
      const IMPORTED_REVIEWS = await GET_IMPORTED_REVIEW_DETAILS(selectedTour, selectRating, value.timeFrame, 1);
      const REVIEWS = await GET_REVIEW_DETAILS(selectedTour, selectRating, value.timeFrame, 1);
      if (REVIEWS) {
        this.setState({
          totalPage: REVIEWS.summary.meta.pages,
          currentPage: REVIEWS.summary.meta.current_page,
          list: REVIEWS.reviews,
          loading: false,
        });
      }
      if (IMPORTED_REVIEWS) {
        this.setState({
          totalPageNext: IMPORTED_REVIEWS.summary.meta.pages,
          loading: false,
        });
      }
    });
  }

  onRatingChanged(value) {
    const { selectedTour, selectedTimeFrame } = this.state;
    this.setState({
      selectRating: value.rating === '' ? null : value.rating,
    });
    this.setState({
      loading: true,
    }, async () => {
      const IMPORTED_REVIEWS = await GET_IMPORTED_REVIEW_DETAILS(selectedTour, value.rating, selectedTimeFrame, 1);
      const REVIEWS = await GET_REVIEW_DETAILS(selectedTour, value.rating, selectedTimeFrame, 1);
      if (REVIEWS) {
        this.setState({
          totalPage: REVIEWS.summary.meta.pages,
          currentPage: REVIEWS.summary.meta.current_page,
          list: REVIEWS.reviews,
          loading: false,
        });
      }
      if (IMPORTED_REVIEWS) {
        this.setState({
          totalPageNext: IMPORTED_REVIEWS.summary.meta.pages,
          loading: false,
        });
      }
    });
  }

  onTourChanged(value) {
    const { selectRating, selectedTimeFrame } = this.state;
    this.setState({
      selectedTour: value.tourType === '' ? null : value.tourType,
    });
    this.setState({
      loading: true,
    }, async () => {
      const IMPORTED_REVIEWS = await GET_IMPORTED_REVIEW_DETAILS(value.tourType, selectRating, selectedTimeFrame, 1);
      const REVIEWS = await GET_REVIEW_DETAILS(value.tourType, selectRating, selectedTimeFrame, 1);
      if (REVIEWS) {
        this.setState({
          totalPage: REVIEWS.summary.meta.pages,
          currentPage: REVIEWS.summary.meta.current_page,
          list: REVIEWS.reviews,
          loading: false,
        });
      }
      if (IMPORTED_REVIEWS) {
        this.setState({
          totalPageNext: IMPORTED_REVIEWS.summary.meta.pages,
          loading: false,
        });
        if (REVIEWS.reviews.length < 3) {
          const newList = [...REVIEWS.reviews];
          this.setState({ list: newList.concat(IMPORTED_REVIEWS.reviews) });
        }
      }
    });
  }

  loadMoreItems() {
    const {
      list,
      selectedTour,
      selectRating,
      selectedTimeFrame,
      currentPage,
      totalPage,
      currentPageNext,
    } = this.state;
    this.setState({
      loadingMore: true,
    }, async () => {
      if (currentPage < totalPage) {
        const REVIEWS = await GET_REVIEW_DETAILS(selectedTour, selectRating, selectedTimeFrame, currentPage + 1);
        if (REVIEWS) {
          this.setState({
            totalPage: REVIEWS.summary.meta.pages,
            currentPage: REVIEWS.summary.meta.current_page,
            loadingMore: false,
          });
          const newList = [...list];
          this.setState({ list: newList.concat(REVIEWS.reviews) });
        }
      } else {
        const IMPORTED_REVIEWS = await GET_IMPORTED_REVIEW_DETAILS(selectedTour, selectRating, selectedTimeFrame, currentPageNext + 1);
        if (IMPORTED_REVIEWS) {
          this.setState({
            totalPageNext: IMPORTED_REVIEWS.summary.meta.pages,
            currentPageNext: IMPORTED_REVIEWS.summary.meta.current_page,
            loadingMore: false,
          });
          const newList = [...list];
          this.setState({ list: newList.concat(IMPORTED_REVIEWS.reviews) });
        }
      }
    });
  }

  render() {
    const {
      selectedTour,
      list,
      selectRating,
      selectedTimeFrame,
      loading,
      total,
      avgRating,
      currentPage,
      totalPage,
      loadingMore,
      totalPageNext,
      currentPageNext,
    } = this.state;
    const totalReviewPage = totalPage + totalPageNext;
    // remove duplicate reviews
    const newList = list.filter((el, index, self) => (
      index === self.findIndex(t => (
        t.products[0].id === el.products[0].id
      ))
    ));
    const Select = withFormGroup(FormSelect);
    const rating = [{
      id: '1',
      value: '1',
      name: '1',
    }, {
      id: '2',
      value: '2',
      name: '2',
    }, {
      id: '3',
      value: '3',
      name: '3',
    }, {
      id: '4',
      value: '4',
      name: '4',
    }, {
      id: '5',
      value: '5',
      name: '5',
    }];
    const timeFrame = [{
      id: 'most-recent',
      value: 'most-recent',
      name: 'Most Recent',
    }, {
      id: 'least-recent',
      value: 'least-recent',
      name: 'Least Recent',
    }];
    return (
      <StaticQuery
        query={graphql`
          query {
          allTourPages(
            sort: {fields: [name], order: ASC},
            filter: {
              hidden_category: { eq: false },
            }
          ) {
            edges {
              node {
                id
                api_id
                name
              }
            }
          }
        }
      `}
        render={({ allTourPages }) => (
          <Element
            name="name"
            className="l-review-list"
          >
            <div className="l-container">
              <div className="header-box">
                <div className="l-review-list__header">
                  <span className="header-sub">Average Customer Rating:</span>
                  <StarRatings
                    rating={avgRating}
                    starRatedColor="#FFDC00"
                    starDimension="26px"
                    starSpacing="0px"
                    numberOfStars={5}
                  />
                  <span className="rating-sub">{avgRating}</span> <SVGFeefo />
                  <p>
                    Independent Service Rating - Based on {total} verified reviews.
                  </p>
                </div>
                <div className="l-review-list__filters">
                  <div className="l-review-list__filters-grid l-review-list__filters-grid--3">
                    <Select
                      label="Types"
                      placeholder="All Types"
                      id="tourType"
                      name="tourType"
                      options={FORMAT_GRAPH_DATA(allTourPages)}
                      value={selectedTour}
                      onChange={this.onTourChanged}
                      srOnly
                    />

                    <Select
                      label="Rating"
                      placeholder="All Reviews"
                      id="rating"
                      name="rating"
                      options={rating}
                      value={selectRating}
                      onChange={this.onRatingChanged}
                      srOnly
                    />

                    <Select
                      label="TimeFrame"
                      id="timeFrame"
                      name="timeFrame"
                      value={selectedTimeFrame}
                      options={timeFrame}
                      onChange={this.onTimeFrameChanged}
                      srOnly
                    />
                  </div>
                </div>
              </div>
              {loading && (
                <div className="loading-screen">
                  <ClipLoader
                    sizeUnit="px"
                    size={50}
                    color="#123abc"
                  />
                </div>
              )}
              {!loading && newList.length === 0 && (
                <div style={{ margin: 40 }}>
                  <h4 className="c-heading c-heading--h4 u-text--center">
                    No matches - please try another search
                  </h4>
                </div>
              )}
              {!loading && (
                <div>
                  {newList.map(item => (
                    <div className="review-section" key={item.products[0].id}>
                      <div className="start-rating-section">
                        <StarRatings
                          rating={item.products[0].rating.rating}
                          starRatedColor="#FFDC00"
                          starDimension="26px"
                          starSpacing="0px"
                          numberOfStars={5}
                        />
                      </div>
                      {item.products[0].product && (
                        <div className="c-heading c-heading--h4">
                          {item.products[0].product.title}
                        </div>
                      )}
                      {item.customer && (
                        <div className="author-title">
                          <b>{item.customer.display_name ? item.customer.display_name : 'Trusted Customer'}</b>
                        </div>
                      )}
                      {!item.customer && !item.imported_custom && (
                        <div className="author-title">
                          <b>Trusted Customer</b>
                        </div>
                      )}
                      {item.imported_custom && (
                        <div className="c-heading c-heading--h4">
                          {item.imported_custom.title}
                        </div>
                      )}
                      {item.imported_custom && (
                        <div className="author-title">
                          <b>{item.imported_custom.author ? item.imported_custom.author : 'Trusted Customer'}</b>
                        </div>
                      )}
                      <br />
                      <div>
                        {item.products[0].review && (
                          <ReadMoreAndLess
                            className="read-more-content"
                            charLimit={250}
                            readMoreText="Read more"
                            readLessText="Read less"
                          >
                            {item.products[0].review.replace(/\ufffd/g, '’')}
                          </ReadMoreAndLess>
                        )}
                      </div>
                      {item.products[0].thread && (
                        <div>
                          <div className="review-response">
                            {item.products[0].thread[0].comment}
                          </div>
                          <div className="sub-reviews">From Haka Tours</div>
                        </div>
                      )}
                    </div>
                  ))}
                  {!loadingMore && newList.length > 0 && (currentPage + currentPageNext) < totalReviewPage && (
                    <div className="guest-review-loadMore">
                      <button
                        type="button"
                        className="c-button c-button--border c-button--small"
                        onClick={this.loadMoreItems}
                      >
                        Load More
                      </button>
                    </div>
                  )}
                  {loadingMore && (
                    <div className="loading-screen">
                      <ClipLoader
                        sizeUnit="px"
                        size={50}
                        color="#123abc"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </Element>
        )}
      />
    );
  }
}

export default ReviewList;
