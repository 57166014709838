import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';


class FormSelect extends PureComponent {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
  }

  /**
   * On change event
   *
   * @param event
   */
  onChange({ target: { value } }) {
    const { name, onChange } = this.props;
    if (onChange) onChange({ [name]: value });
  }


  /**
   * Render
   */
  render() {
    const {
      id,
      name,
      options,
      value,
      placeholder,
      modifier,
      required,
      disabled,
      arrowColor,
      discounts,
    } = this.props;
    const ATTRS = {};
    if (value) ATTRS.value = value;
    return (
      <select
        id={id}
        name={name}
        className={`c-form-control${discounts} c-form-control c-form-control--select${arrowColor} js-form-control ${modifier}`}
        required={required}
        disabled={disabled}
        onChange={this.onChange}
        {
          ...ATTRS
        }
      >
        {placeholder && (
          <option value="">
            {placeholder}
          </option>
        )}

        {options.map(option => (
          <option
            key={option.id}
            value={option.value || option.api_id}
          >
            {option.title || option.name}
          </option>
        ))}
      </select>
    );
  }
}

FormSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  modifier: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  arrowColor: PropTypes.string,
  discounts: PropTypes.string,
};

FormSelect.defaultProps = {
  value: '',
  placeholder: null,
  modifier: '',
  required: false,
  disabled: false,
  onChange: null,
  arrowColor: '',
  discounts: '',
};

export default FormSelect;
