import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { GET_REVIEW } from 'actions/tours';
import { scroller } from 'react-scroll';
import {
  HEADER_HEIGHT,
  SCROLL_CONFIG,
  TOUR_PAGE_NAV,
  TOUR_PAGE_NAV_HEIGHT, HEADER_HEIGHT_MOBILE,
} from 'config';
import StarRatings from 'react-star-ratings';
import { isMobileScreen } from 'utilities';

class TourReviewRating extends PureComponent {
  constructor() {
    super();

    this.state = {
      rating: 0,
      count: 0,
    };
  }

  componentWillMount() {
    const {
      tour,
    } = this.props;
    (async () => {
      // Get departures fresh!
      const RATING = await GET_REVIEW(tour).catch((e) => {
        console.error(e.message);
      });
      if (RATING) {
        this.setState({
          rating: RATING.rating,
          count: RATING.count,
        });
      }
    })();
  }

  render() {
    const { rating, count } = this.state;
    const { showTotal } = this.props;
    if (rating === 0) {
      return null;
    }
    return (
      <div>
        <StarRatings
          rating={rating}
          starRatedColor="#FFDC00"
          starDimension="20px"
          starSpacing="0px"
          numberOfStars={5}
        />
        <span className="starRating">{rating}</span>
        {showTotal && (
          <button
            type="button"
            className="button-to-link"
            onClick={() => scroller.scrollTo(TOUR_PAGE_NAV.REVIEWS.NAME, {
              ...SCROLL_CONFIG,
              offset: isMobileScreen(window) ? -HEADER_HEIGHT_MOBILE : -(HEADER_HEIGHT + TOUR_PAGE_NAV_HEIGHT),
            })}
          >
            {count} Reviews
          </button>
        )}
      </div>
    );
  }
}

TourReviewRating.defaultProps = {
  showTotal: false,
};

TourReviewRating.propTypes = {
  tour: PropTypes.string.isRequired,
  showTotal: PropTypes.bool,
};

export default TourReviewRating;
