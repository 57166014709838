import React from 'react';
import PropTypes from 'prop-types';
import StarRatings from 'react-star-ratings';
import ReadMoreAndLess from 'react-read-more-less';

const ReviewSummary = ({ item }) => (
  <div>
    <div>
      <StarRatings
        rating={item.products[0].rating.rating}
        starRatedColor="#FFDC00"
        starDimension="26px"
        starSpacing="0px"
        numberOfStars={5}
      />
    </div>
    <div key={item.products[0].id}>
      {item.products[0].product && (
        <div className="c-heading c-heading--h4">
          {item.products[0].product.title}
        </div>
      )}
      {item.customer && (
        <div className="author-title">
          <b>{item.customer.display_name ? item.customer.display_name : 'Trusted Customer'}</b>
        </div>
      )}
      {!item.customer && !item.imported_custom && (
        <div className="author-title">
          <b>Trusted Customer</b>
        </div>
      )}
      {item.imported_custom && (
        <div className="c-heading c-heading--h4">
          {item.imported_custom.title}
        </div>
      )}
      {item.imported_custom && (
        <div className="author-title">
          <b>{item.imported_custom.author ? item.imported_custom.author : 'Trusted Customer'}</b>
        </div>
      )}
      <ReadMoreAndLess
        className="read-more-content"
        charLimit={250}
        readMoreText="Read more"
        readLessText="Read less"
      >
        {item.products[0].review.replace(/\ufffd/g, '’')}
      </ReadMoreAndLess>
      {item.products[0].thread && (
        <div>
          <div className="review-response">
            {item.products[0].thread[0].comment}
          </div>
          <div className="sub-reviews">From Haka Tours</div>
        </div>
      )}
    </div>
  </div>
);

ReviewSummary.propTypes = {
  item: PropTypes.object.isRequired,
};


export default ReviewSummary;
