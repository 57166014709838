/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { Header, Footer } from 'components';
import { META_DATA, META_TITLE } from 'config';

import 'styles/index.scss';

const FORCE_ACTIVE = [
  'tour-day',
  'activity-page',
  'user',
  'user/favourites',
  'user/sign-in',
  'user/sign-up',
  'user/reset-password',
  'user-edit',
  'itinerary-download',
  'contact-thank-you',
  'itinerary-thank-you',
  '404',
];

const MAIN_ALT_STYLE = [
  'user',
  'user/favourites',
  'user/sign-in',
  'user/sign-up',
  'user/reset-password',
  'itinerary-download',
  'contact-thank-you',
  'itinerary-thank-you',
  '404',
];

const Layout = ({ page, children }) => {
  const forceActive = FORCE_ACTIVE.includes(page);
  const mainAltStyle = MAIN_ALT_STYLE.includes(page);

  return (
    <>
      <Helmet
        title={META_TITLE}
        defaultTitle={META_TITLE}
        meta={META_DATA}
        // titleTemplate={`%s | ${META_TITLE}`}
        bodyAttributes={{
          class: `h-page--${page} ${forceActive ? 'u-body--top' : ''}`,
        }}
      />
      <Header forceActive={forceActive} />
      <main className={`l-main ${mainAltStyle ? 'l-main--alt' : ''}`} role="main">
        {children}
      </main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
