import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery, Link } from 'gatsby';
import { Element } from 'react-scroll';

import { PAGES } from 'config';
import { FORMAT_GRAPH_DATA, getRate } from 'utilities';
import {
  FormSelect,
  TourCard,
  withFormGroup,
} from 'components';


const DURATION_OPTIONS = {
  '1-7': {
    title: '1 - 7 days',
    value: '1-7',
  },
  '8-14': {
    title: '8 - 14 days',
    value: '8-14',
  },
  '15+': {
    title: '15+ days',
    value: '15-100',
  },
};

const SORT_OPTIONS = {
  POPULAR: {
    title: 'Popular',
    value: 'POPULAR',
  },
  PRICE_HIGH: {
    title: 'Price: High-low',
    value: 'PRICE_HIGH',
  },
  PRICE_LOW: {
    title: 'Price: Low-high',
    value: 'PRICE_LOW',
  },
  DURATION_LONGEST: {
    title: 'Longest duration',
    value: 'DURATION_LONGEST',
  },
  DURATION_SHORTEST: {
    title: 'Shortest duration',
    value: 'DURATION_SHORTEST',
  },
};

const LOCATION_GROUP_OPTIONS = {
  ALL: {
    title: 'All Tours',
    value: 'ALL',
  },
  NORTH_AND_SOUTH_ISLAND: {
    title: 'North & South Island',
    value: 'North And South',
  },
  NORTH_ISLAND: {
    title: 'North Island Only',
    value: 'North Island',
  },
  SOUTH_ISLAND: {
    title: 'South Island Only',
    value: 'South Island',
  },
};

const FIELDS = {
  CATEGORY: {
    ID: 'TourCategory',
    OPTIONS: [],
  },
  LOCATION: {
    ID: 'TourLocation',
    OPTIONS: [],
  },
  DURATION: {
    ID: 'TourDuration',
    OPTIONS: Object.values(DURATION_OPTIONS).map((option, i) => ({
      ...option,
      id: i,
    })),
  },
  SORT: {
    ID: 'TourSort',
    OPTIONS: Object.values(SORT_OPTIONS).map((option, i) => ({
      ...option,
      id: i,
      title: `Sort by: ${option.title}`,
    })),
  },
  LOCATION_GROUP: {
    ID: 'LocationSort',
    OPTIONS: Object.values(LOCATION_GROUP_OPTIONS).map((option, i) => ({
      ...option,
      id: i,
      title: `${option.title}`,
    })),
  },
};

class TourList extends PureComponent {
  /**
   * Initial state values
   */
  static values() {
    const {
      CATEGORY, LOCATION, DURATION, SORT, LOCATION_GROUP,
    } = FIELDS;

    return {
      [CATEGORY.ID]: '',
      [LOCATION.ID]: '',
      [DURATION.ID]: '',
      [SORT.ID]: SORT_OPTIONS.POPULAR.value,
      [LOCATION_GROUP.ID]: LOCATION_GROUP_OPTIONS.ALL.value,
    };
  }


  /**
   * Constructor
   */
  constructor() {
    super();

    this.onChange = this.onChange.bind(this);

    this.state = {
      values: this.constructor.values(),
      rate: null,
    };
  }

  async componentDidMount() {
    try {
      const rate = await getRate();
      this.setState({ rate });
    } catch (error) {
      console.error('Error fetching rate:', error);
    }
  }

  /**
   * On change event
   */
  onChange(value) {
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        ...value,
      },
    }));
  }


  /**
   * Sort the list of tours
   *
   * @param list
   */
  sortList(list) {
    const { values } = this.state;
    const { SORT } = FIELDS;
    const {
      POPULAR,
      PRICE_HIGH,
      PRICE_LOW,
      DURATION_LONGEST,
      DURATION_SHORTEST,
    } = SORT_OPTIONS;

    list.sort((a, b) => {
      switch (values[SORT.ID]) {
        case POPULAR.value: {
          return a.order - b.order;
        }
        case PRICE_HIGH.value: {
          return b.starting_price - a.starting_price;
        }
        case PRICE_LOW.value: {
          return a.starting_price - b.starting_price;
        }
        case DURATION_LONGEST.value: {
          return b.number_of_days - a.number_of_days;
        }
        case DURATION_SHORTEST.value: {
          return a.number_of_days - b.number_of_days;
        }
        default: {
          return a.order - b.order;
        }
      }
    });
  }


  /**
   * Filter the list of tours
   */
  filterList() {
    const { list, hideCategoryFilter } = this.props;
    const { values } = this.state;
    const {
      CATEGORY,
      LOCATION,
      LOCATION_GROUP,
      DURATION,
      SORT,
    } = FIELDS;

    let filteredList = list;

    if (values[CATEGORY.ID] && !hideCategoryFilter) {
      filteredList = filteredList.filter(tour => tour.category_ids.includes(values[CATEGORY.ID]));
    }

    if (values[LOCATION.ID]) {
      filteredList = filteredList.filter(tour => tour.destination_ids.includes(values[LOCATION.ID]));
    }

    if (values[DURATION.ID]) {
      let lowerLimit = 1;
      let upperLimit = 7;

      switch (values[DURATION.ID]) {
        case DURATION_OPTIONS['1-7'].value:
          lowerLimit = 1;
          upperLimit = 7;
          break;
        case DURATION_OPTIONS['8-14'].value:
          lowerLimit = 8;
          upperLimit = 14;
          break;
        case DURATION_OPTIONS['15+'].value:
          lowerLimit = 15;
          upperLimit = 100;
          break;
        default:
          lowerLimit = 1;
          upperLimit = 7;
      }

      filteredList = filteredList.filter(tour => tour.number_of_days >= lowerLimit && tour.number_of_days <= upperLimit);
    }

    if (values[SORT.ID]) {
      this.sortList(filteredList);
    }

    if (values[LOCATION_GROUP.ID]) {
      if (values[LOCATION_GROUP.ID] === 'North Island' || values[LOCATION_GROUP.ID] === 'South Island') {
        filteredList = filteredList.filter(tour => tour.destination_groups && tour.destination_groups.length === 1 && tour.destination_groups[0] === values[LOCATION_GROUP.ID]);
      } else if (values[LOCATION_GROUP.ID] === 'North And South') {
        filteredList = filteredList.filter(tour => tour.destination_groups && tour.destination_groups.length === 2);
      } else if (values[LOCATION_GROUP.ID] === 'German-speaking tours') {
        filteredList = filteredList.filter(tour => tour.is_german_tour);
      }
    }

    return filteredList;
  }


  /**
   * Render
   */
  render() {
    const { values, rate } = this.state;
    const Select = withFormGroup(FormSelect);
    const {
      heading,
      limit,
      hideFilters,
      hideCategoryFilter,
      hideFooter,
      modifier,
    } = this.props;

    const {
      CATEGORY,
      DURATION,
      SORT,
      LOCATION_GROUP,
    } = FIELDS;

    const list = this.filterList();

    if (limit && limit < list.length) {
      list.length = limit; // eslint-disable-line
    }

    return (
      <StaticQuery
        query={graphql`
          query {
            allTourCategories(
              sort: {fields: [order], order: ASC},
              filter: {
                hidden: { eq: false },
              }
            ) {
              edges {
                node {
                  id
                  api_id
                  name
                }
              }
            }

            allDestinationPages {
              edges {
                node {
                  id
                  api_id
                  name
                  tour_ids
                }
              }
            }
          }
        `}
        render={data => (
          <Element
            name={TourList.name}
            className={`l-tour-list ${modifier}`}
          >

            <div className="l-container">
              <div className="l-tour-list__header">
                {heading}
              </div>

              <div className={`l-tour-list__filters ${hideFilters ? 'u-display--none' : ''}`}>
                <div className={`l-tour-list__filters-grid ${hideCategoryFilter ? 'l-tour-list__filters-grid--3' : ''}`}>
                  {hideCategoryFilter || (
                    <Select
                      label="Types"
                      placeholder="All types"
                      id={CATEGORY.ID}
                      name={CATEGORY.ID}
                      options={FORMAT_GRAPH_DATA(data.allTourCategories)}
                      value={values[CATEGORY.ID]}
                      onChange={this.onChange}
                      srOnly
                    />
                  )}
                  <Select
                    label="Location Groups"
                    id={LOCATION_GROUP.ID}
                    name={LOCATION_GROUP.ID}
                    options={FIELDS.LOCATION_GROUP.OPTIONS}
                    value={values[LOCATION_GROUP.ID]}
                    onChange={this.onChange}
                    srOnly
                  />

                  <Select
                    label="Durations"
                    placeholder="All durations"
                    id={DURATION.ID}
                    name={DURATION.ID}
                    options={FIELDS.DURATION.OPTIONS}
                    value={values[DURATION.ID]}
                    onChange={this.onChange}
                    srOnly
                  />

                  <Select
                    label="Sort by"
                    id={SORT.ID}
                    name={SORT.ID}
                    options={FIELDS.SORT.OPTIONS}
                    value={values[SORT.ID]}
                    onChange={this.onChange}
                    srOnly
                  />

                </div>
              </div>

              {!list.length && (
                <h4 className="c-heading c-heading--h4 u-text--center">No matching tours</h4>
              )}

              <div className="l-tour-list__grid">
                {list.map(item => (
                  <TourCard
                    key={item.id}
                    data={item}
                    rate={rate}
                  />
                ))}
              </div>

              {!hideFooter && (
                <div className="l-tour-list__footer">
                  <Link
                    to={PAGES.TOURS.PATH}
                    className="c-button c-button--primary c-button--wide"
                  >
                    View all our tours
                  </Link>
                </div>
              )}
            </div>
          </Element>
        )}
      />
    );
  }
}

TourList.propTypes = {
  heading: PropTypes.node.isRequired,
  list: PropTypes.array.isRequired,
  limit: PropTypes.number,
  hideFilters: PropTypes.bool,
  hideCategoryFilter: PropTypes.bool,
  hideFooter: PropTypes.bool,
  modifier: PropTypes.string,
};

TourList.defaultProps = {
  limit: null,
  hideFilters: false,
  hideCategoryFilter: false,
  hideFooter: false,
  modifier: '',
};

export default TourList;
