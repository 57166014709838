import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ModalVideo from 'react-modal-video';
import { Link } from 'gatsby';
import { parseVideo } from 'utilities';
import { Breadcrumbs } from 'components';
import { GET_ALL_REVIEWS } from 'actions/tours';
import StarRatings from 'react-star-ratings';
import SVGVideoPlay from '../../../public/svg/video-play.svg';
import SVGFeefo from '../../../public/svg/feefo-logo.svg';

class VideoIntro extends PureComponent {
  /**
   * Constructor
   */
  constructor() {
    super();

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      isOpen: false,
      total: 0,
      avgRating: 5,
    };
  }

  componentWillMount() {
    (async () => {
      const ALL_REVIEWS = await GET_ALL_REVIEWS().catch((e) => {
        console.error(e.message);
      });
      if (ALL_REVIEWS) {
        this.setState({
          total: ALL_REVIEWS.count,
          avgRating: ALL_REVIEWS.rating,
        });
      }
    })();
  }


  /**
   * Open the video modal
   */
  openModal() {
    this.setState({ isOpen: true });
  }


  /**
   * Close the video modal
   */

  closeModal() {
    this.setState({ isOpen: false });
  }


  /**
   * Render
   */
  render() {
    const { isOpen, total, avgRating } = this.state;
    const {
      children, videoUrl, title, reversed, breadcrumbs, hideReviews, hasPromo, disableVideoPromo,
    } = this.props;

    const { channel, videoId, previewUrl } = parseVideo(videoUrl);

    return (
      <section className={hasPromo ? 'l-video-intro promo' : 'l-video-intro'} id="how-our-tour-work">
        <div className="l-container">
          {!!breadcrumbs.length && (
            <Breadcrumbs list={breadcrumbs} />
          )}

          {title && (
            <div className="l-video-intro__header">
              <h2 className="c-heading c-heading--h2">
                {title}
              </h2>
            </div>
          )}

          <br />

          <div className="l-grid l-grid--2">
            {!disableVideoPromo && (
              <div
                className="l-video-intro__thumbnail"
                style={{ backgroundImage: `url('${previewUrl}')` }}
              >
                <button
                  type="button"
                  className="c-video-play-button"
                  onClick={this.openModal}
                >
                  <SVGVideoPlay />
                </button>
                {!hideReviews && (
                  <div className="review-box">
                    {hasPromo && (
                      <div className="promo-box">
                        <div className="c-heading c-heading--h4 review-box__header">
                          LET IT SNOW
                        </div>
                        <div className="promo-box__body">
                          We take care of all the logistics so you can maximise your time on the slopes! Departures July - September 2024.
                          <br />Secure your spot on the mountain today!
                        </div>
                      </div>
                    )}
                    <div className="review-box__inner">
                      <div className="c-heading c-heading--h4 review-box__header">
                        Average Customer Rating:
                        <StarRatings
                          rating={avgRating}
                          starRatedColor="#FFDC00"
                          starDimension="24px"
                          starSpacing="0px"
                          numberOfStars={5}
                        />
                        <span className="number-rate">{avgRating}</span>
                        <SVGFeefo />
                      </div>
                      <div className="review-box__body">
                        Independent Service Rating - Based on {total} verified reviews.
                      </div>
                      <div className="review-box__footer">
                        <Link
                          to="/about-us/reviews/"
                          className="c-button c-button--primary c-button--wide"
                        >
                          View all reviews
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {disableVideoPromo && (
              <div className="review-box-without-video">
                <div className="c-book-haka">
                  <h3 className="c-heading c-heading--h3 c-book-haka__title">WHY CHOOSE BORN TO ROAM?</h3>
                  <ul>
                    <li>
                      Roam offers a range of independent, customisable multi-day tours, perfect for the more free-spirited traveller.
                    </li>
                    <li>
                      All the tours have included activities and highlights for you to enjoy, and just like our Haka Tours, optional add-ons are available along the way!
                    </li>
                    <li>
                      100% trip guarantee!
                    </li>
                    <li>
                      Our Born to Roam tours are perfect for everyone. From families and couples, to groups of friends, or individuals that want to explore New Zealand their own way!
                    </li>
                    <li>
                      Frequent departures - daily for all tours (except Capture the Cape and Ride the Rails).
                    </li>
                    <li>
                      Pre-book Activities: We are the only NZ tour company that enables you to pre-book activities before your tour starts so you don’t miss out.
                    </li>
                    <li>
                      Flexi-Guarantee: If you are more than 45 days out you can swap onto a different departure or change your tour completely, free of charge.
                    </li>
                    <li>
                      $99 Lifetime Deposits: If you can’t travel it means your deposit isn’t lost, simply saved for your next Haka adventure.
                    </li>
                  </ul>
                </div>
                <div className="review-box__inner">
                  <div className="c-heading c-heading--h4 review-box__header">
                    Average Customer Rating:
                    <StarRatings
                      rating={avgRating}
                      starRatedColor="#FFDC00"
                      starDimension="24px"
                      starSpacing="0px"
                      numberOfStars={5}
                    />
                    <span className="number-rate">{avgRating}</span>
                    <SVGFeefo />
                  </div>
                  <div className="review-box__body">
                    Independent Service Rating - Based on {total} verified reviews.
                  </div>
                  <div className="review-box__footer">
                    <Link
                      to="/about-us/reviews/"
                      className="c-button c-button--primary c-button--wide"
                    >
                      View all reviews
                    </Link>
                  </div>
                </div>
              </div>
            )}

            <div className={`l-video-intro__text ${reversed ? 'l-video-intro__text-reversed' : ''}`}>
              {children}
            </div>
          </div>

          <ModalVideo
            channel={channel}
            videoId={videoId}
            isOpen={isOpen}
            onClose={this.closeModal}
          />
        </div>
      </section>
    );
  }
}

VideoIntro.propTypes = {
  children: PropTypes.node.isRequired,
  videoUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
  reversed: PropTypes.bool,
  hideReviews: PropTypes.bool,
  hasPromo: PropTypes.bool,
  disableVideoPromo: PropTypes.bool,
  breadcrumbs: PropTypes.array,
};

VideoIntro.defaultProps = {
  title: null,
  reversed: false,
  hideReviews: false,
  hasPromo: false,
  disableVideoPromo: true,
  breadcrumbs: [],
};

export default VideoIntro;
