/* eslint-disable */
import React, { PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  FormInput, FormSelect, FormLabel, withFormGroup, Modal, AlertBanner
} from 'components';

import Nationalites from 'config/nationalities';
import Titles from 'config/titles';

class TravellerDetailsFieldset extends PureComponent {

  constructor(props) {
    super(props);
    const {
      nationality,
      firstName,
      lastName,
      travellerTitle,
    } = this.props;
    this.state = {
      travellerNationality: nationality,
      travellerFirstName: firstName,
      travellerLastName: lastName,
      travellersTitle: travellerTitle,
      travellerDOBDay: '',
      travellerDOBMonth: '',
      travellerDOBYear: '',
      open: false,
    };
    this.onNationalityChanged = this.onNationalityChanged.bind(this);
    this.onFirstNameChanged = this.onFirstNameChanged.bind(this);
    this.onLastNameChanged = this.onLastNameChanged.bind(this);
    this.onTitleChanged = this.onTitleChanged.bind(this);
    this.onYearChanged = this.onYearChanged.bind(this);
    this.onMonthChanged = this.onMonthChanged.bind(this);
    this.onDayChanged = this.onDayChanged.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  onNationalityChanged(e) {
    this.setState({ travellerNationality: e.TravellerNationality });
  }

  onFirstNameChanged(e) {
    this.setState({ travellerFirstName: e.target.value });
  }

  onLastNameChanged(e) {
    this.setState({ travellerLastName: e.target.value });
  }

  onYearChanged(e) {
    this.setState({ dob_year: e.target.value });
    if (moment().format('YYYY') - e.target.value < 18) 
      alert("Please note, guests need to be aged 18 by the date of tour departure. Thank you for your understanding.");
  }

  onMonthChanged(e) {
    this.setState({ dob_month: e.target.value });
    if (moment().format('YYYY') - this.state.dob_year < 18 || moment().format('MM') - e.target.value < 0)
      alert("Please note, guests need to be aged 18 by the date of tour departure. Thank you for your understanding.");
  }

  onDayChanged(e) {
    this.setState({ dob_day: e.target.value });
    if (moment().format('YYYY') - this.state.dob_year < 18 || moment().format('MM') - this.state.dob_month < 0 || moment().format('DD') - e.target.value < 0)
      alert("Please note, guests need to be aged 18 by the date of tour departure. Thank you for your understanding.");
  }

  onTitleChanged(e) {
    this.setState({ travellersTitle: e.TravellerTitle });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    const { title, travellerNumber, traveller } = this.props;
    const {
      travellerNationality,
      travellerFirstName,
      travellerLastName,
      travellersTitle,
      open,
    } = this.state;
    const Input = withFormGroup(FormInput);
    const Select = withFormGroup(FormSelect);
    // Format DOB from string e.g. '1980-12-11'
    const DOB = moment(traveller.date_of_birth);
    const DOB_DAY = DOB ? DOB.format('DD') : '';
    const DOB_MONTH = DOB ? DOB.format('MM') : '';
    const DOB_YEAR = DOB ? DOB.format('YYYY') : '';
    return (
      <fieldset className="l-traveller-details-fieldset">
        <legend className="c-heading c-heading--h4">{title || `Traveller #${travellerNumber}`}</legend>
        <div className="l-form__contents">
          <Select
            id={`Traveller[${travellerNumber}]Title`}
            name="TravellerTitle"
            label="Title &#42;"
            value={travellersTitle}
            onChange={this.onTitleChanged}
            placeholder="Title"
            options={Titles}
            required
          />
          <div className="l-form-group">
            <FormLabel id={`Traveller[${travellerNumber}]FirstName`} title="First Name &#42;" />
            <FormInput
              id={`Traveller[${travellerNumber}]FirstName`}
              name="TravellerFirstName"
              label="First Name &#42;"
              placeholder="First Name"
              onChange={this.onFirstNameChanged}
              value={travellerFirstName}
              required
            />
          </div>
          <div className="l-form-group">
            <FormLabel id={`Traveller[${travellerNumber}]LastName`} title="Last Name &#42;" />
            <FormInput
              id={`Traveller[${travellerNumber}]LastName`}
              name="TravellerLastName"
              value={travellerLastName}
              onChange={this.onLastNameChanged}
              label="Last Name &#42;"
              placeholder="Last Name"
              required
            />
          </div>
          <div className="l-form-group l-form-group--dob">
            <FormLabel inputID="DOBDay" title="Date of Birth &#42;" />
            <FormInput
              id={`Traveller[${travellerNumber}]DOBDay`}
              name="TravellerDOBDay"
              type="number"
              placeholder="DD"
              defaultValue={DOB_DAY || ''}
              required
              attrs={
                {
                  min: 1,
                  max: 31,
                }
              }
              onChange={this.onDayChanged}
            />
            <FormInput
              id={`Traveller[${travellerNumber}]DOBMonth`}
              name="TravellerDOBMonth"
              type="number"
              placeholder="MM"
              defaultValue={DOB_MONTH || ''}
              required
              attrs={
                {
                  min: 1,
                  max: 12,
                }
              }
              onChange={this.onMonthChanged}
            />
            <FormInput
              id={`Traveller[${travellerNumber}]DOBYear`}
              name="TravellerDOBYear"
              type="number"
              placeholder="YYYY"
              defaultValue={DOB_YEAR || ''}
              required
              attrs={
                {
                  min: 1900,
                  max: moment().year(),
                }
              }
              onChange={this.onYearChanged}
            />
          </div>
          <Select
            id={`Traveller[${travellerNumber}]Nationality`}
            name="TravellerNationality"
            label="Nationality &#42;"
            // defaultValue={traveller.nationality}
            value={travellerNationality}
            onChange={this.onNationalityChanged}
            placeholder="What is your primary nationality?"
            options={Nationalites}
            required
          />
          <Input
            id={`Traveller[${travellerNumber}]Location`}
            name="TravellerLocation"
            label="Current Location"
            defaultValue={traveller.current_location}
            placeholder="Let us know your current location"
          />
          <Input
            id={`Traveller[${travellerNumber}]Allergies`}
            name="TravellerAllergies"
            label="Allergies"
            defaultValue={traveller.allergies}
            placeholder="Let us know about any allergies you have"
          />
          {/* <div className="l-form-group l-form-group--radio">
            <h4
              className="l-form-group__label vaccine-status"
              onClick={this.handleOpen}
            >
              <u>Are you fully vaccinated against Covid-19?</u>
            </h4>
            {['Yes', 'No'].map(num => (
              <div className="l-form-group__control-wrapper" key={`Traveller[${travellerNumber}]-FullyVaccinated-${num}`}>
                <FormInput
                  id={`Traveller[${travellerNumber}]-${num}`}
                  name={`Traveller[${travellerNumber}]-FullyVaccinated`}
                  type="radio"
                  modifier="c-form-control--radio"
                  value={num}
                />
                <FormLabel inputID={`Traveller[${travellerNumber}]-${num}`} title={String(num)} />
              </div>
            ))}
          </div> */}
          <Modal
            open={open}
            closeHandler={() => {
              this.handleClose();
            }}
          >
            <div className="c-activity-modal">
              <div className="l-container" style={{ padding: '2rem' }}>
                <div>
                  Travellers are required to produce proof of COVID-19 vaccination on all of our trips.
                  Your Haka Tour Manager will need to see proof of vaccination before your tour departs on Day 1.
                  Please ensure you have a copy of your vaccine certificate.
                  If you are unable to be vaccinated for medical reasons, please get in touch <a href="mailto: info@hakatours.com">info@hakatours.com</a>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </fieldset>
    );
  }
}

TravellerDetailsFieldset.propTypes = {
  traveller: PropTypes.any.isRequired,
  title: PropTypes.string,
  nationality: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  travellerTitle: PropTypes.string,
  travellerNumber: PropTypes.number,
};

TravellerDetailsFieldset.defaultProps = {
  title: '',
  nationality: '',
  firstName: '',
  lastName: '',
  travellerTitle: '',
  travellerNumber: 1,
};

export default TravellerDetailsFieldset;
