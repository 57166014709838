import React from 'react';
import { Element } from 'react-scroll';


const Awards = () => (
  <Element
    name={Awards.name}
    className="l-awards-nz"
  >
    <div className="l-container">
      <div className="l-awards-nz__header">
        <h2 className="c-heading c-heading--h1">Latest Awards</h2>
      </div>

      <div className="l-awards-nz__body">
        <div className="l-grid l-grid--4">
          <div className="l-awards-nz__tile">
            <div className="l-awards-nz__tile-icon-4">
              <a href="/about-us/our-awards/" target="_blank">
                <img src="https://static-hakatours.imgix.net/Award_6.jpg?fit=crop&auto=format" alt="" />
              </a>
            </div>
          </div>
          <div className="l-awards-nz__tile">
            <div className="l-awards-nz__tile-icon">
              <a href="/about-us/our-awards/" target="_blank">
                <img src="https://static-hakatours.imgix.net/Award_5.jpg?fit=crop&auto=format" alt="" />
              </a>
            </div>
          </div>

          <div className="l-awards-nz__tile">
            <div className="l-awards-nz__tile-icon">
              <a href="/about-us/our-awards/" target="_blank">
                <img src="https://static-hakatours.imgix.net/NewZealand-Culture-Winner-2019-6.png?fit=crop&auto=format" alt="" />
              </a>
            </div>
          </div>

          <div className="l-awards-nz__tile">
            <div className="l-awards-nz__tile-icon-3">
              <a href="/about-us/our-awards/" target="_blank">
                <img src="https://static-hakatours.imgix.net/trip-advisor.png?fit=crop&auto=format" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Element>
);

export default Awards;
