/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Masonry from 'react-masonry-component';

import { TestimonialCard } from 'components';
import { PAGES } from 'config';


class TestimonialList extends PureComponent {
  constructor() {
    super();
    this.state = {
      page: 1,
    };
  }

  render() {
    const { page } = this.state;
    const { textLimit } = this.props;
    const {
      title, list, paginate, pageLength, limit
    } = this.props;

    if (!list.length) return null;

    let newList = list;

    if (limit && limit < list.length) {
      newList.length = limit;
    }

    if (paginate && pageLength) {
      const END = page * pageLength;
      newList = list.slice(0, END)
    }

    return (
      <section className="l-testimonial-list">
        <div className="l-container">
          <div className="l-testimonial-list__header">
            <h2 className="c-heading c-heading--h2">
              {title}
            </h2>
          </div>
          <Masonry
            className="l-testimonial-list__grid"
            elementType="div"
            disableImagesLoaded={false}
            updateOnEachImageLoad={false}
            options={{
              gutter: 20,
              transitionDuration: 0,
            }}
          >
            {newList.map(item => (
              <TestimonialCard
                key={item.id}
                data={item}
                textLimit={textLimit}
              />
            ))}
          </Masonry>

          <div className="l-testimonial-list__footer">
            {
              paginate && newList.length < list.length ?
                (
                  <button type="button" className="c-button c-button--border c-button--wide" onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      page: page + 1,
                    });
                  }}>
                    Load More
                  </button>
                ) : null
            }
            {
              !paginate ?
                (
                  <Link
                    to={PAGES.ABOUT_REVIEWS.PATH}
                    className="c-button c-button--primary c-button--wide"
                  >
                    View all reviews
                  </Link>
                ) : null
            }
          </div>
        </div>
      </section>
    );
  }
}

TestimonialList.defaultProps = {
  textLimit: 9999,
};

TestimonialList.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  limit: PropTypes.number,
  textLimit: PropTypes.number,
};

export default TestimonialList;
