import React from 'react';
import { Link } from 'gatsby';

import Logo from '../../../public/svg/logo.svg';


const SiteLogo = () => (
  <Link
    to="/"
    className="c-site-logo"
  >
    <Logo />
    <span className="u-sr-only">Haka Tours</span>
  </Link>
);

export default SiteLogo;
