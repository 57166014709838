import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { PAGES } from 'config';


const Breadcrumbs = ({ list }) => {
  if (!list || !list.length) return null;

  return (
    <nav className="c-breadcrumbs">
      <ul className="c-breadcrumbs__list">
        <li className="c-breadcrumbs__item">
          <Link
            to={PAGES.HOME.PATH}
            className="c-breadcrumbs__link"
          >
            {PAGES.HOME.TITLE}
          </Link>
        </li>

        {list.map(item => (
          <li
            key={`${item.absolute_slug}-${item.name}`}
            className="c-breadcrumbs__item"
          >
            <Link
              to={item.absolute_slug}
              className="c-breadcrumbs__link"
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

Breadcrumbs.propTypes = {
  list: PropTypes.array,
};

Breadcrumbs.defaultProps = {
  list: null,
};

export default Breadcrumbs;
