import React from 'react';
import PropTypes from 'prop-types';

import { CONTACT } from 'config';

const BookingToolbar = ({ children, saving }) => (
  <div className="c-booking-toolbar">
    <div className="l-container">
      <div className="c-booking-toolbar__help">
        <p>Need help? Call <a href={`tel:${CONTACT.PHONE}`}>{CONTACT.PHONE}</a> or
          <button
            type="button"
            className="u-reset-btn"
            onClick={(e) => {
              e.preventDefault();
              // This interacts a global JS chat widget: https://www.jivochat.com/api/
              // eslint-disable-next-line
                if (jivo_api && typeof jivo_api.open === 'function') {
                  jivo_api.open(); // eslint-disable-line
              }
            }}
          >
            Live Chat
          </button>
        </p>
      </div>
      <div className="c-booking-toolbar__actions">
        { saving
          ? <h4 className="c-heading c-heading--h4 c-booking-toolbar__saving">Saving</h4>
          : children
        }
      </div>
    </div>
  </div>
);

BookingToolbar.propTypes = {
  children: PropTypes.node.isRequired,
  saving: PropTypes.bool,
};

BookingToolbar.defaultProps = {
  saving: false,
};

export default BookingToolbar;
