import React from 'react';
import PropTypes from 'prop-types';
import {
  VideoEmbed,
} from 'layouts';
// import Masonry from 'react-masonry-component';
// import { CrowdRiffGallery } from 'react-crowdriff-gallery';

// import { SOCIAL } from 'config';
// import { InstagramCard } from 'components';\


const ProudSupporters = ({
  title,
}) => (
  <section className="l-proud-list">
    <div className="l-container">
      <div className="l-instagram-list__header">
        <h2 className="c-heading c-heading--h2">
          {title}
        </h2>
      </div>
      <VideoEmbed
        videoUrl="https://www.youtube.com/watch?v=5LW2Qebrxew"
      />
    </div>
  </section>
);

ProudSupporters.propTypes = {
  title: PropTypes.string,
};

ProudSupporters.defaultProps = {
  title: 'Our latest #HakaTours adventures',
};

export default ProudSupporters;
