import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import {
  getImageUrl,
  mapStateToProps,
} from 'utilities';

class IncludedActivityCard extends PureComponent {
  render() {
    const {
      data, showLocation,
    } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query {
            allActivityImages {
              edges {
                node {
                  api_id
                  imgix_url
                }
              }
            }
          }
        `}
        render={({ allActivityImages }) => {
          const imageUrl = getImageUrl({
            id: data.sliding_first_picture_id,
            images: allActivityImages.edges,
            width: 280,
            height: 175,
          });

          return (
            <div className="c-activity-card">
              <div className="c-activity-card__image">
                {data.sliding_first_picture_id && (
                  <LazyLoadImage
                    src={imageUrl}
                    effect="blur"
                    wrapperClassName="lazy-img-wrapper"
                    alt={data.name}
                  />
                )}
              </div>
              <div className="c-activity-card__content">
                <React.Fragment>
                  {showLocation && (
                    <span className="c-activity-card__location">
                      {data.location} | {data.category}
                    </span>
                  )}
                  <h5 className="c-heading c-heading--h5">
                    {data.name}
                  </h5>
                </React.Fragment>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

IncludedActivityCard.propTypes = {
  data: PropTypes.object.isRequired,
  showLocation: PropTypes.bool,
};

IncludedActivityCard.defaultProps = {
  showLocation: true,
};

export default connect(mapStateToProps)(IncludedActivityCard);
