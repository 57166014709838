import React, { PureComponent } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';

import { PAGES, STATES } from 'config';
import {
  FORMAT_GRAPH_DATA, isTabletScreen, getRate,
} from 'utilities';
import {
  SubNav, TourSubNav, SubNavBasic, AccountNav,
} from 'components';
import SVGArrow from '../../../public/svg/sub-nav-arrow.svg';

const BODY_ACTIVE_CLASS = 's-mob-nav-active';

class Nav extends PureComponent {
  constructor() {
    super();

    this.state = {
      subNav: {},
      rate: 0,
    };

    this.onSubNavClick = this.onSubNavClick.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.rawMenuClick = this.rawMenuClick.bind(this);
  }

  async componentDidMount() {
    try {
      const rate = await getRate();
      this.setState({ rate });
    } catch (error) {
      console.error('Error fetching rate:', error);
    }
  }

  onSubNavClick(e, id) {
    if (isTabletScreen(window)) e.preventDefault();

    this.setState(prevState => ({
      subNav: {
        ...prevState.subNav,
        [id]: !prevState.subNav[id],
      },
    }));
  }


  onBackClick() {
    this.setState({
      subNav: {},
    });
  }

  onMenuClick(e) {
    if (window.matchMedia('(max-width: 1200px)').matches && window.matchMedia('(min-width: 767px)').matches) {
      e.preventDefault();
    }
    window.requestAnimationFrame(() => document.body.classList.remove(BODY_ACTIVE_CLASS));
    this.removeDocumentTop();
  }

  rawMenuClick() {
    window.requestAnimationFrame(() => document.body.classList.remove(BODY_ACTIVE_CLASS));
    this.removeDocumentTop();
  }

  removeDocumentTop() {
    document.body.style.top = '0';
    window.requestAnimationFrame(() => window.scrollTo(0, this.offsetY));
  }


  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allTourCategories(
              sort: {fields: [order], order: ASC},
              filter: {
                hidden: { eq: false },
              }
            ) {
              edges {
                node {
                  id
                  name
                  full_name
                  absolute_slug
                  background_color
                  category_icon {
                    id
                    svg_text
                  }
                  tours {
                    id
                    name
                    order
                    is_german_tour
                    absolute_slug
                    number_of_days
                    starting_price
                    small_map_id
                  }
                }
              }
            }

            allActivityCategories {
              edges {
                node {
                  id
                  name
                  absolute_slug
                  activities {
                    id
                    name
                    absolute_slug
                  }
                }
              }
            }

            allDestinationPages {
              edges {
                node {
                  id
                  name
                  absolute_slug
                  destination_category
                  has_discover_more
                }
              }
            }
          }
        `}
        render={(data) => {
          const { subNav, rate } = this.state;
          const {
            TOURS, DESTINATIONS, ABOUT, WIN, ABOUT_REVIEWS,
          } = PAGES;

          const destinationItems = FORMAT_GRAPH_DATA(data.allDestinationPages);
          const tourList = FORMAT_GRAPH_DATA(data.allTourCategories);

          const destinationList = [
            {
              id: 1,
              name: 'View all destinations',
              absolute_slug: DESTINATIONS.PATH,
            },
            {
              id: 2,
              name: 'North Island',
              destinations: destinationItems.filter(item => (item.destination_category === 'North Island' && item.has_discover_more === true)),
            },
            {
              id: 3,
              name: 'South Island',
              destinations: destinationItems.filter(item => (item.destination_category === 'South Island' && item.has_discover_more === true)),
            },
          ];
          const aboutList = [
            {
              id: 1,
              name: PAGES.ABOUT_TRIPS.TITLE,
              absolute_slug: PAGES.ABOUT_TRIPS.PATH,
            },
            {
              id: 2,
              name: PAGES.ABOUT_STORY.TITLE,
              absolute_slug: PAGES.ABOUT_STORY.PATH,
            },
            // {
            //   id: 3,
            //   name: PAGES.ABOUT_BRAND.TITLE,
            //   absolute_slug: PAGES.ABOUT_BRAND.PATH,
            // },
            {
              id: 3,
              name: PAGES.ABOUT_PURPOSE.TITLE,
              absolute_slug: PAGES.ABOUT_PURPOSE.PATH,
            },
            {
              id: 4,
              name: PAGES.ABOUT_PEOPLE.TITLE,
              absolute_slug: PAGES.ABOUT_PEOPLE.PATH,
            },
            // {
            //   id: 5,
            //   name: 'Our Awards',
            //   absolute_slug: PAGES.ABOUT_AWARDS.PATH,
            // },
            {
              id: 6,
              name: 'DMC Services',
              absolute_slug: PAGES.INBOUND_OP.PATH,
            },
            {
              id: 7,
              name: PAGES.ABOUT_FAQS.TITLE,
              absolute_slug: PAGES.ABOUT_FAQS.PATH,
            }
          ];

          const mainNav = [
            {
              id: TOURS.PATH,
              path: TOURS.PATH,
              title: TOURS.TITLE,
              subNavComponent: (
                <TourSubNav
                  list={tourList}
                  isActive={!!subNav[TOURS.PATH]}
                  onBackClick={this.onBackClick}
                  onClick={e => this.onMenuClick(e)}
                  rate={rate}
                />
              ),
            },
            {
              id: DESTINATIONS.PATH,
              path: DESTINATIONS.PATH,
              title: DESTINATIONS.TITLE,
              subNavComponent: (
                <SubNav
                  list={destinationList}
                  childListKey="destinations"
                  isActive={!!subNav[DESTINATIONS.PATH]}
                  onBackClick={this.onBackClick}
                  onClick={e => this.onMenuClick(e)}
                />
              ),
            },
            {
              id: ABOUT_REVIEWS.PATH,
              path: ABOUT_REVIEWS.PATH,
              title: ABOUT_REVIEWS.TITLE,
            },
            {
              id: ABOUT.PATH,
              path: ABOUT.PATH,
              title: ABOUT.TITLE,
              subNavComponent: (
                <SubNavBasic
                  list={aboutList}
                  isActive={!!subNav[ABOUT.PATH]}
                  onBackClick={this.onBackClick}
                  onMenuClick={this.rawMenuClick}
                />
              ),
            },
            {
              id: PAGES.DISCOUNTS.PATH,
              path: PAGES.DISCOUNTS.PATH,
              title: 'Deals',
            },
          ];

          return (
            <nav className="c-nav" role="navigation">
              <ul className="c-nav__list">
                {mainNav.map(item => (
                  <li
                    key={item.id}
                    className={`c-nav__item ${item.subNavComponent ? 'c-nav__item--has-subnav' : ''}`}
                  >
                    <div className="c-nav__main">
                      {(item.title === ABOUT_REVIEWS.TITLE || item.title === 'Deals')
                        ? (
                          <Link
                            to={item.path}
                            className="c-nav__link"
                            activeClassName={STATES.ACTIVE}
                            onClick={this.rawMenuClick}
                          >
                            {item.title}
                          </Link>
                        ) : (
                          <Link
                            to={item.path}
                            className="c-nav__link"
                            activeClassName={STATES.ACTIVE}
                            onClick={e => this.onMenuClick(e)}
                          >
                            {item.title}
                          </Link>
                        )}
                      {(item.title === ABOUT_REVIEWS.TITLE || item.title === WIN.TITLE || item.title === 'Deals') ? null : (
                        <button
                          type="button"
                          className="c-nav__link-arrow"
                          onClick={e => this.onSubNavClick(e, item.id)}
                        >
                          <SVGArrow />
                        </button>
                      )}
                    </div>

                    {item.subNavComponent}
                  </li>
                ))}
              </ul>

              {typeof window !== 'undefined' && isTabletScreen(window) && (
                <AccountNav />
              )}
            </nav>
          );
        }}
      />
    );
  }
}

export default Nav;
