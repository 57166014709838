import React from 'react';
import PropTypes from 'prop-types';

import { FaqAccordion } from 'components';


const FaqsList = ({ title, list }) => {
  if (!list.length) return null;

  return (
    <section className="l-faqs-list">
      <div className="l-container">
        <div className="l-faqs-list__header">
          <h2 className="c-heading c-heading--h2">
            {title}
          </h2>
        </div>

        <div className="l-faqs-list__list">
          <FaqAccordion list={list} />
        </div>
      </div>
    </section>
  );
};

FaqsList.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
};

export default FaqsList;
