import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link, StaticQuery } from 'gatsby';
import ReactTooltip from 'react-tooltip';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import {
  getImageUrl, numberToCurrency, getImageAltText, calculatePriceWithRate,
} from 'utilities';
import { Gallery, TourReviewRating } from 'components';
import { PulseLoader } from 'react-spinners';

const TourCard = ({ data, rate }) => (
  <StaticQuery
    query={graphql`
      query {
        allTourImages {
          edges {
            node {
              api_id
              imgix_url
              alt_text
            }
          }
        }
      }
    `}
    render={({ allTourImages }) => {
      const slideIDs = [
        data.sliding_first_picture_id,
        data.sliding_second_picture_id,
        data.sliding_third_picture_id,
        data.sliding_fourth_picture_id,
        data.sliding_fifth_picture_id,
        data.sliding_sixth_picture_id,
        data.sliding_seventh_picture_id,
        data.sliding_eighth_picture_id,
        data.sliding_ninth_picture_id,
        data.sliding_tenth_picture_id,
      ];

      const slides = slideIDs
        .filter(id => id)
        .map(id => ({
          id,
          imageUrl: getImageUrl({
            id,
            images: allTourImages.edges,
            width: 380,
            height: 230,
          }),
          title: getImageAltText({
            id,
            images: allTourImages.edges,
          }),
        }));

      const mapUrl = getImageUrl({
        id: data.small_map_id,
        images: allTourImages.edges,
        width: 140,
      });

      const germanFlag = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
       <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
         "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
       <svg xmlns="http://www.w3.org/2000/svg" width="100" height="60" viewBox="0 0 5 3">
         <desc>Flag of Germany</desc>
         <rect id="black_stripe" width="5" height="3" y="0" x="0" fill="#000"/>
         <rect id="red_stripe" width="5" height="2" y="1" x="0" fill="#D00"/>
         <rect id="gold_stripe" width="5" height="1" y="2" x="0" fill="#FFCE00"/>
       </svg>`;

      // const imageUrl = getImageUrl({
      //   id: data.picture_id,
      //   images: allTourImages.edges,
      //   width: 380,
      //   height: 230,
      // });

      return (
        <div className="c-tour-card">
          <div className="c-tour-card__image">
            <Gallery slides={slides} linkTo={data.absolute_slug} />

            <div className="c-tour-card__duration">
              <span>
                {data.number_of_days}
              </span>
              <span>Days</span>
            </div>

            {data.is_german_tour && (
              <div className="c-tour-card__german">
                <span
                  className="c-tour-card__category-icon-flag"
                  data-tip
                  data-for="germanTour"
                  dangerouslySetInnerHTML={{ __html: germanFlag }}
                />
                <ReactTooltip
                  id="germanTour"
                  type="dark"
                  effect="solid"
                  className="c-tooltip"
                  multiline
                  border
                >
                  <span>This tour has a German-speaking tour guide and is best suited to people who speak German</span>
                </ReactTooltip>
              </div>
            )}

            {(data.name === "New Zealand Amplified Tour" 
              || data.name === "New Zealand's North Island Adventure" 
              || data.name === "New Zealand's South Island Adventure") && (
              <div className="c-tour-card__banner" 
              style={{ backgroundColor: "#FF0000" }}
              >
                <div className="c-tour-card__banner-angle" 
                  style={{ borderLeftColor: "#FF0000" }}
                />
                <span>
                  TRIPS FROM 1 JUNE 2025
                </span>
              </div>
            )}

            <div
              className="c-tour-card__category"
              style={{ backgroundColor: data.background_color }}
            >
              <div
                className="c-tour-card__category-angle"
                style={{ borderLeftColor: data.background_color }}
              />
              {data.parent_category_icon && (
                <div
                  className="c-tour-card__category-icon"
                  dangerouslySetInnerHTML={{ __html: data.parent_category_icon.svg_text }}
                />
              )}
              <span>
                {data.parent_name}
              </span>
            </div>

            {Array.isArray(data.category_icons) && !!data.category_icons.length ? (
              <div className="c-tour-card__activities">
                <ul>
                  {data.category_icons.map(icon => (
                    <Fragment key={icon.id}>
                      <li
                        data-tip
                        data-for={icon.id}
                        dangerouslySetInnerHTML={{ __html: icon.svg_text }}
                      />
                      <ReactTooltip
                        id={icon.id}
                        type="dark"
                        effect="solid"
                        className="c-tooltip"
                        multiline
                        border
                      >
                        <span>{`${icon.tooltip}`}</span>
                      </ReactTooltip>
                    </Fragment>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>

          <div className="c-tour-card__content">
            <h3 className="c-heading c-heading--h3 c-tour-card__title">
              {data.name}
            </h3>

            <div className="c-tour-card__price">
              Price from
              <span>
                {(rate !== null) ? (
                  numberToCurrency(calculatePriceWithRate(data.starting_price, rate))
                ) : (
                  <PulseLoader
                    sizeUnit="px"
                    size={5}
                    color="#000"
                  />
                )}
              </span>
              NZD
            </div>

            <TourReviewRating tour={data.api_id} />

            <p className="c-tour-card__description">
              {data.short_description_markdown}
            </p>

            {!data.archived ? (
              <Link
                to={`${data.absolute_slug}/`}
                className="c-button c-button--border c-button--small"
              >
                View tour
              </Link>
            ) : (
              <Link
                to={`/new-zealand-tours/`}
                className="c-button c-button--border c-button--small"
              >
                View tour
              </Link>
            )}

            {mapUrl && (
              <LazyLoadImage
                src={mapUrl}
                className="c-tour-card__map"
                threshold={200}
                wrapperClassName="lazy-img-wrapper"
                effect="opacity"
                alt={`${data.name} map`}
              />
            )}
          </div>
        </div>
      );
    }}
  />
);

TourCard.propTypes = {
  data: PropTypes.object.isRequired,
  rate: PropTypes.number,
};

TourCard.defaultProps = {
  rate: null,
};

export default TourCard;
