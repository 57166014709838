/* eslint-disable */
import React from 'react';

import { FormLabel } from 'components';

const withFormGroup = Field => (props) => {
  const {
    groupModifier = '',
    row,
    srOnly,
    hidden,
    id,
    label,
    name,
  } = props;

  const FormGroup = (
    <div className={`l-form-group ${groupModifier} ${hidden && !row ? 'u-display--none' : ''}`}>

      <FormLabel inputID={id} title={label} modifier={srOnly ? 'u-sr-only' : ''} />

      <Field {...props} name={name} />

    </div>
  );

  if (row) {
    return (
      <div className={`l-form-group__row ${hidden ? 'u-display--none' : ''}`}>
        {FormGroup}
      </div>
    );
  }

  return FormGroup;
};

export default withFormGroup;
