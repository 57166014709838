export default [
  {
    id: 'Afghan',
    value: 'Afghan',
    name: 'Afghan',
  },
  {
    id: 'Albanian',
    value: 'Albanian',
    name: 'Albanian',
  },
  {
    id: 'Algerian',
    value: 'Algerian',
    name: 'Algerian',
  },
  {
    id: 'American',
    value: 'American',
    name: 'American',
  },
  {
    id: 'Andorran',
    value: 'Andorran',
    name: 'Andorran',
  },
  {
    id: 'Angolan',
    value: 'Angolan',
    name: 'Angolan',
  },
  {
    id: 'Antiguans',
    value: 'Antiguans',
    name: 'Antiguans',
  },
  {
    id: 'Argentinean',
    value: 'Argentinean',
    name: 'Argentinean',
  },
  {
    id: 'Armenian',
    value: 'Armenian',
    name: 'Armenian',
  },
  {
    id: 'Australian',
    value: 'Australian',
    name: 'Australian',
  },
  {
    id: 'Austrian',
    value: 'Austrian',
    name: 'Austrian',
  },
  {
    id: 'Azerbaijani',
    value: 'Azerbaijani',
    name: 'Azerbaijani',
  },
  {
    id: 'Bahamian',
    value: 'Bahamian',
    name: 'Bahamian',
  },
  {
    id: 'Bahraini',
    value: 'Bahraini',
    name: 'Bahraini',
  },
  {
    id: 'Bangladeshi',
    value: 'Bangladeshi',
    name: 'Bangladeshi',
  },
  {
    id: 'Barbadian',
    value: 'Barbadian',
    name: 'Barbadian',
  },
  {
    id: 'Barbudans',
    value: 'Barbudans',
    name: 'Barbudans',
  },
  {
    id: 'Batswana',
    value: 'Batswana',
    name: 'Batswana',
  },
  {
    id: 'Belarusian',
    value: 'Belarusian',
    name: 'Belarusian',
  },
  {
    id: 'Belgian',
    value: 'Belgian',
    name: 'Belgian',
  },
  {
    id: 'Belizean',
    value: 'Belizean',
    name: 'Belizean',
  },
  {
    id: 'Beninese',
    value: 'Beninese',
    name: 'Beninese',
  },
  {
    id: 'Bhutanese',
    value: 'Bhutanese',
    name: 'Bhutanese',
  },
  {
    id: 'Bolivian',
    value: 'Bolivian',
    name: 'Bolivian',
  },
  {
    id: 'Bosnian',
    value: 'Bosnian',
    name: 'Bosnian',
  },
  {
    id: 'Brazilian',
    value: 'Brazilian',
    name: 'Brazilian',
  },
  {
    id: 'British',
    value: 'British',
    name: 'British',
  },
  {
    id: 'Bruneian',
    value: 'Bruneian',
    name: 'Bruneian',
  },
  {
    id: 'Bulgarian',
    value: 'Bulgarian',
    name: 'Bulgarian',
  },
  {
    id: 'Burkinabe',
    value: 'Burkinabe',
    name: 'Burkinabe',
  },
  {
    id: 'Burmese',
    value: 'Burmese',
    name: 'Burmese',
  },
  {
    id: 'Burundian',
    value: 'Burundian',
    name: 'Burundian',
  },
  {
    id: 'Cambodian',
    value: 'Cambodian',
    name: 'Cambodian',
  },
  {
    id: 'Cameroonian',
    value: 'Cameroonian',
    name: 'Cameroonian',
  },
  {
    id: 'Canadian',
    value: 'Canadian',
    name: 'Canadian',
  },
  {
    id: 'Cape Verdean',
    value: 'Cape Verdean',
    name: 'Cape Verdean',
  },
  {
    id: 'Central African',
    value: 'Central African',
    name: 'Central African',
  },
  {
    id: 'Chadian',
    value: 'Chadian',
    name: 'Chadian',
  },
  {
    id: 'Chilean',
    value: 'Chilean',
    name: 'Chilean',
  },
  {
    id: 'Chinese',
    value: 'Chinese',
    name: 'Chinese',
  },
  {
    id: 'Colombian',
    value: 'Colombian',
    name: 'Colombian',
  },
  {
    id: 'Comoran',
    value: 'Comoran',
    name: 'Comoran',
  },
  {
    id: 'Congolese',
    value: 'Congolese',
    name: 'Congolese',
  },
  {
    id: 'Costa Rican',
    value: 'Costa Rican',
    name: 'Costa Rican',
  },
  {
    id: 'Croatian',
    value: 'Croatian',
    name: 'Croatian',
  },
  {
    id: 'Cuban',
    value: 'Cuban',
    name: 'Cuban',
  },
  {
    id: 'Cypriot',
    value: 'Cypriot',
    name: 'Cypriot',
  },
  {
    id: 'Czech',
    value: 'Czech',
    name: 'Czech',
  },
  {
    id: 'Danish',
    value: 'Danish',
    name: 'Danish',
  },
  {
    id: 'Djibouti',
    value: 'Djibouti',
    name: 'Djibouti',
  },
  {
    id: 'Dominican',
    value: 'Dominican',
    name: 'Dominican',
  },
  {
    id: 'Dutch',
    value: 'Dutch',
    name: 'Dutch',
  },
  {
    id: 'East Timorese',
    value: 'East Timorese',
    name: 'East Timorese',
  },
  {
    id: 'Ecuadorean',
    value: 'Ecuadorean',
    name: 'Ecuadorean',
  },
  {
    id: 'Egyptian',
    value: 'Egyptian',
    name: 'Egyptian',
  },
  {
    id: 'Emirian',
    value: 'Emirian',
    name: 'Emirian',
  },
  {
    id: 'Equatorial Guinean',
    value: 'Equatorial Guinean',
    name: 'Equatorial Guinean',
  },
  {
    id: 'Eritrean',
    value: 'Eritrean',
    name: 'Eritrean',
  },
  {
    id: 'Estonian',
    value: 'Estonian',
    name: 'Estonian',
  },
  {
    id: 'Ethiopian',
    value: 'Ethiopian',
    name: 'Ethiopian',
  },
  {
    id: 'Fijian',
    value: 'Fijian',
    name: 'Fijian',
  },
  {
    id: 'Filipino',
    value: 'Filipino',
    name: 'Filipino',
  },
  {
    id: 'Finnish',
    value: 'Finnish',
    name: 'Finnish',
  },
  {
    id: 'French',
    value: 'French',
    name: 'French',
  },
  {
    id: 'Gabonese',
    value: 'Gabonese',
    name: 'Gabonese',
  },
  {
    id: 'Gambian',
    value: 'Gambian',
    name: 'Gambian',
  },
  {
    id: 'Georgian',
    value: 'Georgian',
    name: 'Georgian',
  },
  {
    id: 'German',
    value: 'German',
    name: 'German',
  },
  {
    id: 'Ghanaian',
    value: 'Ghanaian',
    name: 'Ghanaian',
  },
  {
    id: 'Greek',
    value: 'Greek',
    name: 'Greek',
  },
  {
    id: 'Grenadian',
    value: 'Grenadian',
    name: 'Grenadian',
  },
  {
    id: 'Guatemalan',
    value: 'Guatemalan',
    name: 'Guatemalan',
  },
  {
    id: 'Guinea-Bissauan',
    value: 'Guinea-Bissauan',
    name: 'Guinea-Bissauan',
  },
  {
    id: 'Guinean',
    value: 'Guinean',
    name: 'Guinean',
  },
  {
    id: 'Guyanese',
    value: 'Guyanese',
    name: 'Guyanese',
  },
  {
    id: 'Haitian',
    value: 'Haitian',
    name: 'Haitian',
  },
  {
    id: 'Herzegovinian',
    value: 'Herzegovinian',
    name: 'Herzegovinian',
  },
  {
    id: 'Honduran',
    value: 'Honduran',
    name: 'Honduran',
  },
  {
    id: 'Hungarian',
    value: 'Hungarian',
    name: 'Hungarian',
  },
  {
    id: 'I-Kiribati',
    value: 'I-Kiribati',
    name: 'I-Kiribati',
  },
  {
    id: 'Icelander',
    value: 'Icelander',
    name: 'Icelander',
  },
  {
    id: 'Indian',
    value: 'Indian',
    name: 'Indian',
  },
  {
    id: 'Indonesian',
    value: 'Indonesian',
    name: 'Indonesian',
  },
  {
    id: 'Iranian',
    value: 'Iranian',
    name: 'Iranian',
  },
  {
    id: 'Iraqi',
    value: 'Iraqi',
    name: 'Iraqi',
  },
  {
    id: 'Irish',
    value: 'Irish',
    name: 'Irish',
  },
  {
    id: 'Israeli',
    value: 'Israeli',
    name: 'Israeli',
  },
  {
    id: 'Italian',
    value: 'Italian',
    name: 'Italian',
  },
  {
    id: 'Ivorian',
    value: 'Ivorian',
    name: 'Ivorian',
  },
  {
    id: 'Jamaican',
    value: 'Jamaican',
    name: 'Jamaican',
  },
  {
    id: 'Japanese',
    value: 'Japanese',
    name: 'Japanese',
  },
  {
    id: 'Jordanian',
    value: 'Jordanian',
    name: 'Jordanian',
  },
  {
    id: 'Kazakhstani',
    value: 'Kazakhstani',
    name: 'Kazakhstani',
  },
  {
    id: 'Kenyan',
    value: 'Kenyan',
    name: 'Kenyan',
  },
  {
    id: 'Kittian and Nevisian',
    value: 'Kittian and Nevisian',
    name: 'Kittian and Nevisian',
  },
  {
    id: 'Kuwaiti',
    value: 'Kuwaiti',
    name: 'Kuwaiti',
  },
  {
    id: 'Kyrgyz',
    value: 'Kyrgyz',
    name: 'Kyrgyz',
  },
  {
    id: 'Laotian',
    value: 'Laotian',
    name: 'Laotian',
  },
  {
    id: 'Latvian',
    value: 'Latvian',
    name: 'Latvian',
  },
  {
    id: 'Lebanese',
    value: 'Lebanese',
    name: 'Lebanese',
  },
  {
    id: 'Liberian',
    value: 'Liberian',
    name: 'Liberian',
  },
  {
    id: 'Libyan',
    value: 'Libyan',
    name: 'Libyan',
  },
  {
    id: 'Liechtensteiner',
    value: 'Liechtensteiner',
    name: 'Liechtensteiner',
  },
  {
    id: 'Lithuanian',
    value: 'Lithuanian',
    name: 'Lithuanian',
  },
  {
    id: 'Luxembourger',
    value: 'Luxembourger',
    name: 'Luxembourger',
  },
  {
    id: 'Macedonian',
    value: 'Macedonian',
    name: 'Macedonian',
  },
  {
    id: 'Malagasy',
    value: 'Malagasy',
    name: 'Malagasy',
  },
  {
    id: 'Malawian',
    value: 'Malawian',
    name: 'Malawian',
  },
  {
    id: 'Malaysian',
    value: 'Malaysian',
    name: 'Malaysian',
  },
  {
    id: 'Maldivan',
    value: 'Maldivan',
    name: 'Maldivan',
  },
  {
    id: 'Malian',
    value: 'Malian',
    name: 'Malian',
  },
  {
    id: 'Maltese',
    value: 'Maltese',
    name: 'Maltese',
  },
  {
    id: 'Marshallese',
    value: 'Marshallese',
    name: 'Marshallese',
  },
  {
    id: 'Mauritanian',
    value: 'Mauritanian',
    name: 'Mauritanian',
  },
  {
    id: 'Mauritian',
    value: 'Mauritian',
    name: 'Mauritian',
  },
  {
    id: 'Mexican',
    value: 'Mexican',
    name: 'Mexican',
  },
  {
    id: 'Micronesian',
    value: 'Micronesian',
    name: 'Micronesian',
  },
  {
    id: 'Moldovan',
    value: 'Moldovan',
    name: 'Moldovan',
  },
  {
    id: 'Monacan',
    value: 'Monacan',
    name: 'Monacan',
  },
  {
    id: 'Mongolian',
    value: 'Mongolian',
    name: 'Mongolian',
  },
  {
    id: 'Moroccan',
    value: 'Moroccan',
    name: 'Moroccan',
  },
  {
    id: 'Mosotho',
    value: 'Mosotho',
    name: 'Mosotho',
  },
  {
    id: 'Motswana',
    value: 'Motswana',
    name: 'Motswana',
  },
  {
    id: 'Mozambican',
    value: 'Mozambican',
    name: 'Mozambican',
  },
  {
    id: 'Namibian',
    value: 'Namibian',
    name: 'Namibian',
  },
  {
    id: 'Nauruan',
    value: 'Nauruan',
    name: 'Nauruan',
  },
  {
    id: 'Nepalese',
    value: 'Nepalese',
    name: 'Nepalese',
  },
  {
    id: 'New Zealander',
    value: 'New Zealander',
    name: 'New Zealander',
  },
  {
    id: 'Nicaraguan',
    value: 'Nicaraguan',
    name: 'Nicaraguan',
  },
  {
    id: 'Nigerian',
    value: 'Nigerian',
    name: 'Nigerian',
  },
  {
    id: 'Nigerien',
    value: 'Nigerien',
    name: 'Nigerien',
  },
  {
    id: 'North Korean',
    value: 'North Korean',
    name: 'North Korean',
  },
  {
    id: 'Northern Irish',
    value: 'Northern Irish',
    name: 'Northern Irish',
  },
  {
    id: 'Norwegian',
    value: 'Norwegian',
    name: 'Norwegian',
  },
  {
    id: 'Omani',
    value: 'Omani',
    name: 'Omani',
  },
  {
    id: 'Pakistani',
    value: 'Pakistani',
    name: 'Pakistani',
  },
  {
    id: 'Palauan',
    value: 'Palauan',
    name: 'Palauan',
  },
  {
    id: 'Panamanian',
    value: 'Panamanian',
    name: 'Panamanian',
  },
  {
    id: 'Papua New Guinean',
    value: 'Papua New Guinean',
    name: 'Papua New Guinean',
  },
  {
    id: 'Paraguayan',
    value: 'Paraguayan',
    name: 'Paraguayan',
  },
  {
    id: 'Peruvian',
    value: 'Peruvian',
    name: 'Peruvian',
  },
  {
    id: 'Polish',
    value: 'Polish',
    name: 'Polish',
  },
  {
    id: 'Portuguese',
    value: 'Portuguese',
    name: 'Portuguese',
  },
  {
    id: 'Qatari',
    value: 'Qatari',
    name: 'Qatari',
  },
  {
    id: 'Romanian',
    value: 'Romanian',
    name: 'Romanian',
  },
  {
    id: 'Russian',
    value: 'Russian',
    name: 'Russian',
  },
  {
    id: 'Rwandan',
    value: 'Rwandan',
    name: 'Rwandan',
  },
  {
    id: 'Saint Lucian',
    value: 'Saint Lucian',
    name: 'Saint Lucian',
  },
  {
    id: 'Salvadoran',
    value: 'Salvadoran',
    name: 'Salvadoran',
  },
  {
    id: 'Samoan',
    value: 'Samoan',
    name: 'Samoan',
  },
  {
    id: 'San Marinese',
    value: 'San Marinese',
    name: 'San Marinese',
  },
  {
    id: 'Sao Tomean',
    value: 'Sao Tomean',
    name: 'Sao Tomean',
  },
  {
    id: 'Saudi',
    value: 'Saudi',
    name: 'Saudi',
  },
  {
    id: 'Scottish',
    value: 'Scottish',
    name: 'Scottish',
  },
  {
    id: 'Senegalese',
    value: 'Senegalese',
    name: 'Senegalese',
  },
  {
    id: 'Serbian',
    value: 'Serbian',
    name: 'Serbian',
  },
  {
    id: 'Seychellois',
    value: 'Seychellois',
    name: 'Seychellois',
  },
  {
    id: 'Sierra Leonean',
    value: 'Sierra Leonean',
    name: 'Sierra Leonean',
  },
  {
    id: 'Singaporean',
    value: 'Singaporean',
    name: 'Singaporean',
  },
  {
    id: 'Slovakian',
    value: 'Slovakian',
    name: 'Slovakian',
  },
  {
    id: 'Slovenian',
    value: 'Slovenian',
    name: 'Slovenian',
  },
  {
    id: 'Solomon Islander',
    value: 'Solomon Islander',
    name: 'Solomon Islander',
  },
  {
    id: 'Somali',
    value: 'Somali',
    name: 'Somali',
  },
  {
    id: 'South African',
    value: 'South African',
    name: 'South African',
  },
  {
    id: 'South Korean',
    value: 'South Korean',
    name: 'South Korean',
  },
  {
    id: 'Spanish',
    value: 'Spanish',
    name: 'Spanish',
  },
  {
    id: 'Sri Lankan',
    value: 'Sri Lankan',
    name: 'Sri Lankan',
  },
  {
    id: 'Sudanese',
    value: 'Sudanese',
    name: 'Sudanese',
  },
  {
    id: 'Surinamer',
    value: 'Surinamer',
    name: 'Surinamer',
  },
  {
    id: 'Swazi',
    value: 'Swazi',
    name: 'Swazi',
  },
  {
    id: 'Swedish',
    value: 'Swedish',
    name: 'Swedish',
  },
  {
    id: 'Swiss',
    value: 'Swiss',
    name: 'Swiss',
  },
  {
    id: 'Syrian',
    value: 'Syrian',
    name: 'Syrian',
  },
  {
    id: 'Taiwanese',
    value: 'Taiwanese',
    name: 'Taiwanese',
  },
  {
    id: 'Tajik',
    value: 'Tajik',
    name: 'Tajik',
  },
  {
    id: 'Tanzanian',
    value: 'Tanzanian',
    name: 'Tanzanian',
  },
  {
    id: 'Thai',
    value: 'Thai',
    name: 'Thai',
  },
  {
    id: 'Togolese',
    value: 'Togolese',
    name: 'Togolese',
  },
  {
    id: 'Tongan',
    value: 'Tongan',
    name: 'Tongan',
  },
  {
    id: 'Trinidadian or Tobagonian',
    value: 'Trinidadian or Tobagonian',
    name: 'Trinidadian or Tobagonian',
  },
  {
    id: 'Tunisian',
    value: 'Tunisian',
    name: 'Tunisian',
  },
  {
    id: 'Turkish',
    value: 'Turkish',
    name: 'Turkish',
  },
  {
    id: 'Tuvaluan',
    value: 'Tuvaluan',
    name: 'Tuvaluan',
  },
  {
    id: 'Ugandan',
    value: 'Ugandan',
    name: 'Ugandan',
  },
  {
    id: 'Ukrainian',
    value: 'Ukrainian',
    name: 'Ukrainian',
  },
  {
    id: 'Uruguayan',
    value: 'Uruguayan',
    name: 'Uruguayan',
  },
  {
    id: 'Uzbekistani',
    value: 'Uzbekistani',
    name: 'Uzbekistani',
  },
  {
    id: 'Venezuelan',
    value: 'Venezuelan',
    name: 'Venezuelan',
  },
  {
    id: 'Vietnamese',
    value: 'Vietnamese',
    name: 'Vietnamese',
  },
  {
    id: 'Welsh',
    value: 'Welsh',
    name: 'Welsh',
  },
  {
    id: 'Yemenite',
    value: 'Yemenite',
    name: 'Yemenite',
  },
  {
    id: 'Zambian',
    value: 'Zambian',
    name: 'Zambian',
  },
  {
    id: 'Zimbabwean',
    value: 'Zimbabwean',
    name: 'Zimbabwean',
  },
];
