import React from 'react';

const BookHakaB2R = () => (
  <div className="c-book-haka">
    <h3 className="c-heading c-heading--h3 c-book-haka__title">WHY CHOOSE BORN TO ROAM?</h3>
    <ul>
      <li>
        Roam offers a range of independent, customisable multi-day tours, perfect for the more free-spirited traveller.
      </li>
      <li>
        All the tours have included activities and highlights for you to enjoy, and just like our Haka Tours, optional add-ons are available along the way!
      </li>
      <li>
        100% trip guarantee!
      </li>
      <li>
        Our Born to Roam tours are perfect for everyone. From families and couples, to groups of friends, or individuals that want to explore New Zealand their own way!
      </li>
      <li>
        Frequent departures - daily for all tours (except Capture the Cape and Ride the Rails).
      </li>
      <li>
        Add on all your activities whilst you’re on the road with us. Your friendly Tour Leader will make your bookings for you!
      </li>
      <li>
        Flexi-Guarantee: If you are more than 45 days out you can swap onto a different departure or change your tour completely, free of charge.
      </li>
      <li>
        $99 Lifetime Deposits: If you can’t travel it means your deposit isn’t lost, simply saved for your next Haka adventure.
      </li>
    </ul>
  </div>
);

export default BookHakaB2R;
