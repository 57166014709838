/* eslint-disable */

import { numberToCurrency } from 'utilities';

import { GET_BOOKING, UPDATE_BOOKING } from 'actions/booking';

/**
 * Get traveller costs
 * @param traveller
 * @param addOns
 * @param remove
 * @returns {Array}
 * @constructor
 */
export const GET_TRAVELLER_COSTS = (traveller, addOns, remove) => {
  const COSTS = [];
  if (!Array.isArray(addOns)) return COSTS;
  addOns.filter(addOn => addOn.traveller_id === traveller.id)
    .forEach((addOn, index) => {
      COSTS.push({
        id: `${index}-${traveller.id}-${addOn.option_id}`,
        title: `${addOn.name}`,
        content: numberToCurrency(addOn.price),
        removeBtnCallback: async () => {
          if (remove && typeof remove === 'function') remove(traveller.id, addOn);
        },
      });
    });
  return COSTS;
};

/**
 * Get combined accomm costs for before and after tour
 * @param accommodations
 * @constructor
 */
export const GET_ACCOMM_COSTS = (accommodations, type, bookingID, session, dispatch) => {
  if (accommodations.length) {
    // use this if we want to add the prices
    // const PRICE = accommodations.reduce((accumulator, currentValue) => accumulator.price + currentValue.price);
    let PRICE = 0;
    accommodations.forEach((accomm) => {
      PRICE += accomm.price;
    });

    const ACCOMM_PAYLOAD = accommodations.map(accomm => ({ id: accomm.id, _destroy: true }));
    const PLURAL = accommodations[0].nights > 1 ? 's' : '';
    return {
      id: `${type}-accomm`,
      title: `Extra ${accommodations[0].nights} night${PLURAL} ${type} tour`,
      content: `${numberToCurrency(PRICE)}`,
      removeBtnCallback: async () => {
        dispatch({
          type: 'BOOKINGS_SET_UPDATING',
          payload: true,
        });

        const REMOVE = await UPDATE_BOOKING(bookingID, session.token, session.email, {
          accommodations: [
            ...ACCOMM_PAYLOAD,
          ],
        });
        if (REMOVE && REMOVE.success) {
          // Get updated booking data
          GET_BOOKING(dispatch, bookingID, session.token, session.email);
        } else {
          dispatch({
            type: 'MESSAGES_ADD',
            payload: {
              id: 'booking-summary-failed-remove-accomm',
              type: 'error',
              content: 'Failed to remove accommodation upgrade.',
            },
          });
        }
        dispatch({
          type: 'BOOKINGS_SET_UPDATING',
          payload: false,
        });
      },
    };
  }
  return null;
};
