import React from 'react';
import PropTypes from 'prop-types';

import SVGCross from '../../../public/svg/cross-thin.svg';

const MessageBanner = ({ messages, dispatch }) => (
  <div className="c-message-banner">
    <ul className="c-message-banner__messages u-reset-list">
      {
        messages.map(message => (
          <li
            className={`c-message-banner__message c-message-banner__message--${message.type || ''}`}
            key={message.id}
          >
            <div
              dangerouslySetInnerHTML={{ __html: message.content }}
            />
            <button
              type="button"
              className="c-message-banner__remove-btn"
              onClick={(e) => {
                e.preventDefault();
                dispatch({
                  type: 'MESSAGES_REMOVE',
                  payload: message.id,
                });
              }}
            >
              <span className="u-sr-only">Remove</span>
              <SVGCross />
            </button>
          </li>
        ))
      }
    </ul>
  </div>
);

MessageBanner.propTypes = {
  messages: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default MessageBanner;
