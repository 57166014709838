import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  IncludedActivityCard,
  IncludedActivityModal,
  Breadcrumbs,
} from 'components';
import { Element } from 'react-scroll/modules';

/* eslint-disable-next-line */
const ACTIVITY_CATEGORIES_MAP = [
  { name: 'Hobbiton Movie Set Tour', category: 'Haka Plus' },
  { name: 'Bay of Islands Sundowner Cruise', category: 'Haka Adventure' },
  { name: 'Carve your own pounamu necklace', category: 'Haka Adventure and Plus' },
  { name: 'Cook Strait Ferry Crossing', category: 'Haka Adventure, Plus and Winter' },
  { name: 'Māori Cultural Experience', category: 'Haka Adventure and Plus' },
  { name: 'Paddle a waka', category: 'Haka Adventure' },
  { name: 'Redwood Forest Canopy Walk', category: 'Haka Plus' },
  { name: 'Sunset Boat Cruise with Fish & Chips Dinner', category: 'Haka Plus' },
  { name: 'Te Puia Māori Village', category: 'Haka Adventure' },
  { name: 'TranzAlpine Train Journey', category: 'Haka Adventure, Plus and Winter' },
  { name: 'Waitangi Treaty Grounds', category: 'Haka Plus and Winter' },
  { name: 'Wine Tasting', category: 'Haka Adventure, Plus and Winter' },
  { name: 'Hobbiton', category: 'Haka Adventure and Plus' },
  { name: 'Mou Waho Island Cruise & Nature Walk', category: 'Haka Plus' },
  { name: 'Zealandia Nature Reserve', category: 'Haka Plus' },
  { name: 'Poor Knights Islands Day Cruise', category: 'Haka Plus' },
  { name: 'Waipoua Forest Day Tour', category: 'Winter' },
  { name: 'Hundertwasser Arts Centre & Māori Arts Gallery', category: 'Winter' },
  { name: 'Glowworm Cave Tour', category: 'Winter' },
  { name: 'Visit Wai-O-Tapu', category: 'Winter' },
  { name: 'Te Papa Museum', category: 'Winter' },
  { name: 'Pounamu Pathways', category: 'Winter' },
  { name: 'Cruise and Nature Walk Lake Mapourika', category: 'Winter' },
  { name: 'Milford Sound Cruise', category: 'Winter' },
  { name: 'Boutique gin distillery visit', category: 'Winter' },
  { name: 'Steampunk Museum Visit', category: 'Winter' },
];

class IncludedActivityList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modal: {},
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.formatedList = this.formatedList.bind(this);
  }

  handleOpen(id) {
    this.setState(prevState => ({
      modal: {
        ...prevState,
        [id]: true,
      },
    }));
  }

  handleClose(id) {
    this.setState(prevState => ({
      modal: {
        ...prevState,
        [id]: false,
      },
    }));
  }

  formatedList() {
    const { list } = this.props;
    list.forEach((item) => {
      const filteredItems = ACTIVITY_CATEGORIES_MAP.filter(i => i.name === item.name);
      /* eslint-disable-next-line */
      item.category = filteredItems.length > 0 ? filteredItems[0].category : '';;
    });

    return list;
  }

  render() {
    const { modal } = this.state;
    const list = this.formatedList();

    const {
      title,
      subTitle,
      breadcrumbs,
    } = this.props;

    return (
      <Element
        name={IncludedActivityList.name}
        className="l-activity-list"
      >
        <div className="l-container">
          <Breadcrumbs list={breadcrumbs} />

          {title && (
            <div className="l-activity-list__header">
              <h1 className="c-heading c-heading--h2">
                {title}
              </h1>

              {subTitle && (
                <p>
                  {subTitle}
                </p>
              )}
            </div>
          )}

          <div className="l-grid l-grid--4">
            {list.map(item => (
              <React.Fragment>
                {/* eslint-disable-next-line */}
                <div onClick={() => this.handleOpen(item.id)}>
                  <IncludedActivityCard
                    key={item.id}
                    data={item}
                  />
                </div>
                <IncludedActivityModal
                  activity={item}
                  open={!!modal[item.id]}
                  handleClose={() => {
                    this.handleClose(item.id);
                  }}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </Element>
    );
  }
}

IncludedActivityList.propTypes = {
  list: PropTypes.array.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  breadcrumbs: PropTypes.array,
};

IncludedActivityList.defaultProps = {
  title: null,
  subTitle: null,
  breadcrumbs: null,
};

export default IncludedActivityList;
