import React from 'react';
import PropTypes from 'prop-types';

const Notice = ({ message }) => (
  <div className="c-notice">
    <h5 className="c-heading c-heading--h5">
      {message === 'locked' ? <div>This booking is locked and cannot be modified. Please <a href="https://hakatours.com/contact-us">get in touch</a> if you require any changes.</div> : message}
    </h5>
  </div>
);

Notice.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Notice;
