import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { connect } from 'react-redux';

import { STATES, BOOKING_NAV, PAGES } from 'config';
import { mapStateToProps } from 'utilities';


const BookingNav = ({ booking }) => {
  const NAV_ITEMS = (booking.currentBooking) ? BOOKING_NAV : [];
  const ENABLED = (booking.currentBooking && booking.currentBooking.agree_with_the_conditions);
  const ENABLED_LOCKED = (booking.currentBooking && booking.currentBooking.locked);

  return (
    <nav className="c-booking-nav" role="navigation">
      <ul className="c-booking-nav__list">
        {NAV_ITEMS
          .filter(item => !ENABLED_LOCKED || item.PATH === PAGES.BOOKINGS_REVIEW.PATH || item.PATH === PAGES.BOOKINGS_PAYMENT.PATH)
          .map(item => (
            <li
              className={`c-booking-nav__item ${ENABLED || item.PATH === PAGES.BOOKINGS_DETAILS.PATH ? '' : 'is-disabled'}`}
              key={item.PATH}
            >
              <Link
                to={`${item.PATH}?bookingId=${booking.currentBooking.id}&tour=${booking.currentBooking.tour_slug}`}
                className={`c-booking-nav__link ${window.location.pathname === item.PATH ? STATES.ACTIVE : ''}`}
              >
                {item.TITLE}
              </Link>
            </li>
          ))
        }
      </ul>
    </nav>
  );
};

BookingNav.propTypes = {
  booking: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(BookingNav);
