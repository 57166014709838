/* eslint-disable */
import React from 'react';
import { Element } from 'react-scroll';
import { withPrefix } from 'gatsby';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const BestInNZ = () => (
  <Element
    name={BestInNZ.name}
    className="l-best-nz"
  >
    <div className="l-container">
      <div className="l-best-nz__header">
        <h1 className="c-heading c-heading--h1">
         Haka Tours: New Zealand's #1 Tour Company
        </h1>
      </div>

      <div className="l-grid l-grid--5">
        <div className="l-best-nz__tile">
          <div className="l-best-nz__tile-icon">
            <LazyLoadImage
              src={withPrefix('/svg/SMALLGROUPS_CIRCLE.svg')}
              wrapperClassName="lazy-img-wrapper"
              effect="opacity"
              alt=""
            />
          </div>
          <div><div className="subTitle"><b>SMALL GROUPS</b></div>With a maximum of 16 people, our small group tours make for a far better travel experience.</div>
        </div>

        <div className="l-best-nz__tile">
          <div className="l-best-nz__tile-icon">
            <LazyLoadImage
              src={withPrefix('/svg/CUSTOMTOURS_CIRCLE.svg')}
              wrapperClassName="lazy-img-wrapper"
              effect="opacity"
              alt=""
            />
          </div>
          <div><div className="subTitle"><b>OPTIONAL ACTIVITIES</b><br /></div>Experience NZ your way by choosing from over 70 optional activities to add to your tour.</div>
        </div>

        <div className="l-best-nz__tile">
          <div className="l-best-nz__tile-icon">
            <LazyLoadImage
              src={withPrefix('/svg/NZRATED_CIRCLE.svg')}
              wrapperClassName="lazy-img-wrapper"
              effect="opacity"
              alt=""
            />
          </div>
          <div><div className="subTitle"><b>FLEXI-BOOKINGS</b><br /></div> Swap tours or dates free of charge any time up until 60 days before your tour starts.</div>
        </div>

        <div className="l-best-nz__tile">
          <div className="l-best-nz__tile-icon">
            <LazyLoadImage
              src={withPrefix('/svg/culture-1.svg')}
              wrapperClassName="lazy-img-wrapper"
              effect="opacity"
              alt=""
            />
          </div>
          <div><div className="subTitle"><b>CULTURAL EXPERIENCES</b><br /></div>Every tour includes experiences to teach you about our unique Māori culture.</div>
        </div>

        <div className="l-best-nz__tile">
          <div className="l-best-nz__tile-icon">
            <LazyLoadImage
              src={withPrefix('/svg/LOCALGUIDES_CIRCLE.svg')}
              wrapperClassName="lazy-img-wrapper"
              effect="opacity"
              alt=""
            />
          </div>
          <div><div className="subTitle"><b>100% KIWI</b><br /></div>We're the NZ experts and our guides promise a 100% purpose led Kiwi experience.</div>
        </div>
      </div>
    </div>
  </Element>
);

export default BestInNZ;
