import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FormInput, withFormGroup } from 'components';
import { RESET_PASSWORD } from 'actions/user';
import { mapStateToProps, RESPONSE_ERROR_FORMATTER } from 'utilities';

class ResetPasswordForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      successMessage: '',
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  /**
   * @param e
   */
  handleFormSubmit(e) {
    e.preventDefault();
    const FORM = e.currentTarget;
    const FORM_DATA = new FormData(FORM);
    const { dispatch } = this.props;
    if (!FORM.checkValidity()) {
      this.setState({
        errors: ['Invalid form data'],
      });
      return;
    }
    (async () => {
      const RESET = await RESET_PASSWORD(dispatch, FORM_DATA.get('Email'));
      if (!RESET || !RESET.success) {
        this.setState({
          successMessage: '',
          errors: RESPONSE_ERROR_FORMATTER(RESET.data.errors),
        });
        return;
      }
      dispatch({
        type: 'USER_RESET',
      });
      FORM.reset();
      this.setState({
        errors: [],
        successMessage: 'Thanks! You have been sent an email with a link to reset your password.',
      });
    })();
  }

  render() {
    const { errors, successMessage } = this.state;
    const Input = withFormGroup(FormInput);
    return (
      <div className="l-reset-password-form">
        <div className="l-container l-container--small">
          <div className="l-reset-password-form__header">
            <h1 className="c-heading c-heading--h2">Reset Your Password</h1>
            <p>Enter your email address to receive password reset instructions</p>
          </div>
          <div className="l-reset-password-form__wrapper">
            <form action="" className="l-form" onSubmit={this.handleFormSubmit}>
              <ul className="l-form__errors">
                {errors.map(error => (
                  <li key={error} className="u-text--center">
                    {error}
                  </li>
                ))}
              </ul>
              {
                successMessage
                  ? <p className="l-form__message u-text--center">{successMessage}</p> : ''
              }
              <div className="l-form__contents">
                <Input
                  type="email"
                  name="Email"
                  id="InputEmail"
                  placeholder="Email"
                  label="Email"
                  srOnly
                  required
                />
              </div>
              <div className="l-form__actions">
                <button
                  className="c-button c-button--wide c-button--primary"
                  type="submit"
                >
                  Reset Password
                </button>
              </div>
            </form>
            <p className="l-reset-password-form__sign-out-note">
              <small>You will be signed out after resetting your password.</small>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

ResetPasswordForm.propTypes = {
  // user: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ResetPasswordForm);
