import React from 'react';
import { Link } from 'gatsby';


const BlogCard = () => (
  <Link
    to="/"
    className="c-blog-card"
  >
    <div className="c-blog-card__image">
      <img src="https://placehold.it/580x260" alt="title" />
    </div>

    <div className="c-blog-card__content">
      <div className="c-blog-card__categories">
        <ul>
          <li>Snow</li>
          <li>Adventure</li>
          <li>Travel Tips</li>
        </ul>
        <span className="c-blog-card__dates">25 May 2018</span>
      </div>
      <h3 className="c-heading c-heading--h3">
        A trip to the snow - New Zealand or Australia?
      </h3>
      <p>
        The days get shorter, weather becomes cooler, food becomes heavier, you become heavier – Winter is coming. We compare a snow holiday in New
        Zealand verse Australia, factoring in weather conditions, ski terrain, transport and cost.
      </p>
    </div>
  </Link>
);

export default BlogCard;
