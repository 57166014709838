import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { numberToCurrency } from 'utilities';
import { Gallery } from 'components';

const TourSearchCard = ({ tour }) => (
  <div className="c-tour-card">
    <div className="c-tour-card__image">
      <Gallery slides={tour.gallery} linkTo={tour.absolute_slug} />

      <div className="c-tour-card__duration">
        <span>
          {tour.number_of_days}
        </span>
        <span>Days</span>
      </div>

      <div
        className="c-tour-card__category"
        style={{ backgroundColor: tour.category.background_color }}
      >
        <div
          className="c-tour-card__category-angle"
          style={{ borderLeftColor: tour.category.background_color }}
        />
        {tour.category.icon && (
          <div
            className="c-tour-card__category-icon"
            dangerouslySetInnerHTML={{ __html: tour.category.icon }}
          />
        )}
        <span>
          {tour.category.name}
        </span>
      </div>
    </div>

    <div className="c-tour-card__content">
      <h3 className="c-heading c-heading--h3 c-tour-card__title">
        {tour.name}
      </h3>

      <div className="c-tour-card__price">
        Price from
        <span>
          {numberToCurrency(tour.starting_price)}
        </span>
        NZD
      </div>

      <p className="c-tour-card__description">
        {tour.short_description_markdown}
      </p>
      <Link
        to={`${tour.absolute_slug}/`}
        className="c-button c-button--border c-button--small"
      >
        View tour
      </Link>
      <LazyLoadImage
        src={tour.map_url}
        className="c-tour-card__map"
        threshold={200}
        wrapperClassName="lazy-img-wrapper"
        effect="opacity"
        alt={`${tour.name} map`}
      />
    </div>
  </div>
);

TourSearchCard.propTypes = {
  tour: PropTypes.object.isRequired,
};

export default TourSearchCard;
