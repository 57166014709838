import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { PAGES } from 'config';
import { FormInput, withFormGroup } from 'components';
import { CHANGE_PASSWORD, SIGN_IN_USER, GET_USER } from 'actions/user';
import { mapStateToProps, RESPONSE_ERROR_FORMATTER } from 'utilities';

class ChangePasswordForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      successMessage: '',
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  /**
   * @param e
   */
  handleFormSubmit(e) {
    e.preventDefault();
    const FORM = e.currentTarget;
    if (FORM instanceof HTMLFormElement === false || !FORM.checkValidity()) return;
    const FORM_DATA = new FormData(FORM);
    this.changePassword(FORM_DATA);
  }

  changePassword(formData) {
    const { dispatch } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const email = urlParams.get('email');
    const password = formData.get('Password');
    (async () => {
      const CHANGE = await CHANGE_PASSWORD(
        token,
        email,
        password,
      );
      if (!CHANGE || !CHANGE.success) {
        this.setState({
          errors: RESPONSE_ERROR_FORMATTER(CHANGE.data.errors),
        });
      }
      if (CHANGE.success) {
        const SIGN_IN = await SIGN_IN_USER(dispatch, email, password);
        if (!SIGN_IN || !SIGN_IN.success) {
          this.setState({
            errors: RESPONSE_ERROR_FORMATTER(SIGN_IN.data.errors),
          });
          return;
        }
        this.handleSuccess(SIGN_IN);
      }
    })();
  }

  handleSuccess(signIn) {
    const { successHook, dispatch } = this.props;
    (async () => {
      const GET = await GET_USER(dispatch, signIn.data.session.token, signIn.data.session.email);
      if (GET && GET.success) {
        if (successHook && typeof successHook === 'function') {
          successHook();
        } else {
          navigate(PAGES.USER.PATH);
        }
      } else {
        this.setState({
          errors: RESPONSE_ERROR_FORMATTER(GET.data.errors),
        });
      }
    })();
  }

  render() {
    const { errors, successMessage } = this.state;
    const Input = withFormGroup(FormInput);
    return (
      <div className="l-change-password-form">
        <div className="l-container l-container--small">
          <div className="l-change-password-form__header">
            <h1 className="c-heading c-heading--h2">Change Your Password</h1>
            <p>Please enter your new password</p>
          </div>
          <div className="l-change-password-form__wrapper">
            <form action="" className="l-form" onSubmit={this.handleFormSubmit}>
              <ul className="l-form__errors">
                {errors.map(error => (
                  <li key={error} className="u-text--center">
                    {error}
                  </li>
                ))}
              </ul>
              {
                successMessage
                  ? <p className="l-form__message u-text--center">{successMessage}</p> : ''
              }
              <div className="l-form__contents">
                <Input
                  type="password"
                  name="Password"
                  id="InputPassword"
                  placeholder="Password"
                  label="Password"
                  minLength={8}
                  srOnly
                  required
                />
              </div>
              <div className="l-form__actions">
                <button
                  className="c-button c-button--wide c-button--primary"
                  type="submit"
                >
                  Change Password
                </button>
              </div>
            </form>
            <p className="l-change-password-form__sign-out-note">
              <small>You will be signed in after changing your password.</small>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

ChangePasswordForm.propTypes = {
  // user: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  successHook: PropTypes.func,
};

ChangePasswordForm.defaultProps = {
  successHook: null,
};

export default connect(mapStateToProps)(ChangePasswordForm);
