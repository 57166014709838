import React from 'react';
import PropTypes from 'prop-types';


const TestimonialCard = ({ data, textLimit }) => (
  <a href="/about-us/reviews/" className="c-testimonial-card" style={{ textDecoration: 'none', color: '#000' }}>
    <div className="c-testimonial-card__header">
      {data.image_url && (
        <div className="c-testimonial-card__image">
          <img
            src={`${data.image_url}?w=55&h=55&fit=crop&auto=format`}
            alt={data.author}
          />
        </div>
      )}

      <div className="c-testimonial-card__name">
        <h4 className="c-heading c-heading--h4">
          {data.author}
        </h4>

        {data.country && (
          <span className="c-testimonial-card__name-location">
            {data.country}
          </span>
        )}
      </div>
    </div>

    <div className="c-testimonial-card__text">
      <p>
        {data.text.substr(0, textLimit).trim()}{data.text.length > textLimit ? '...' : ''}
      </p>
    </div>
  </a>
);

TestimonialCard.defaultProps = {
  textLimit: 1000,
};

TestimonialCard.propTypes = {
  data: PropTypes.object.isRequired,
  textLimit: PropTypes.number,
};

export default TestimonialCard;
