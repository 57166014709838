import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps } from 'utilities';
import { FormInput, FormSelect, withFormGroup } from 'components';
import { SUBMIT_SALESFORCE_LEAD_SNOW_AFTER } from 'actions/tours';
import { navigate } from 'gatsby';
import { ClipLoader } from 'react-spinners';

const Select = withFormGroup(FormSelect);

class ItineraryDownloadPerTourAfterSnow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      planVisiting: [],
      snowAbilityLevel: '',
      gearOption: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPlanVisitChanged = this.onPlanVisitChanged.bind(this);
    this.onSnowAbilityLevelChanged = this.onSnowAbilityLevelChanged.bind(this);
    this.onGearOptionsChanged = this.onGearOptionsChanged.bind(this);
  }

  onPlanVisitChanged(e) {
    const { planVisiting } = this.state;
    if (planVisiting.indexOf(e.currentTarget.value) > -1) {
      planVisiting.splice(planVisiting.indexOf(e.currentTarget.value), 1);
    } else {
      planVisiting.push(e.currentTarget.value);
    }
  }

  onSnowAbilityLevelChanged(e) {
    this.setState({ snowAbilityLevel: e.SnowAbilityLevel });
  }

  onGearOptionsChanged(e) {
    this.setState({ gearOption: e.GearOption });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { salesforce, absoluteSlug } = this.props;
    const {
      planVisiting,
      snowAbilityLevel,
      gearOption,
    } = this.state;
    this.setState({
      saving: true,
    }, async () => {
      const UPDATE_LEAD = await SUBMIT_SALESFORCE_LEAD_SNOW_AFTER(salesforce, planVisiting, snowAbilityLevel, gearOption);
      if (UPDATE_LEAD && UPDATE_LEAD.success) {
        navigate(`${absoluteSlug}/itinerary-thank-you/`);
      }
    });
    this.setState({
      saving: true,
    });
  }

  render() {
    const {
      saving,
    } = this.state;
    // const Input = withFormGroup(FormInput);
    const abilityLevels = [
      { name: '--None--', value: '', id: '' },
      { name: 'Beginner', value: 'Beginner', id: 'Beginner' },
      { name: 'Intermediate', value: 'Intermediate', id: 'Intermediate' },
      { name: 'Advanced', value: 'Advanced', id: 'Advanced' },
    ];
    const gearOptions = [
      { name: '--None--', value: '', id: '' },
      { name: 'Snowboarder', value: 'Snowboarder', id: 'Snowboarder' },
      { name: 'Skier', value: 'Skier', id: 'Skier' },
      { name: 'Both', value: 'Both', id: 'Both' },
    ];
    return (
      <form className={`l-col ${saving ? 'itinerary-is-saving' : ''}`} action="" onSubmit={this.handleSubmit}>
        <label className="l-form-group__label">When are you planning to visit?</label>
        <div className="l-form-group">
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="ThisMonth"
              name="ThisMonth"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="This month"
              onChange={this.onPlanVisitChanged}
            />
            <label htmlFor="ThisMonth" className="l-form-group__label">
              This month
            </label>
          </div>
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="ThreeMonth"
              name="ThreeMonth"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="Within the next 3 months"
              onChange={this.onPlanVisitChanged}
            />
            <label htmlFor="ThreeMonth" className="l-form-group__label">
              Within the next 3 months
            </label>
          </div>
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="SixMonth"
              name="SixMonth"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="Within the next 6 months"
              onChange={this.onPlanVisitChanged}
            />
            <label htmlFor="SixMonth" className="l-form-group__label">
              Within the next 6 months
            </label>
          </div>
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="TwelveMonth"
              name="TwelveMonth"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="Within the next 12 months"
              onChange={this.onPlanVisitChanged}
            />
            <label htmlFor="TwelveMonth" className="l-form-group__label">
              Within the next 12 months
            </label>
          </div>
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="PlusTwelveMonth"
              name="PlusTwelveMonth"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="12+ months"
              onChange={this.onPlanVisitChanged}
            />
            <label htmlFor="PlusTwelveMonth" className="l-form-group__label">
              12+ months
            </label>
          </div>
          <div className="l-form-group--checkbox">
            <FormInput
              type="checkbox"
              id="NotSure"
              name="NotSure"
              groupModifier="l-form-group--checkbox"
              modifier="c-form-control--checkbox"
              value="Not sure yet"
              onChange={this.onPlanVisitChanged}
            />
            <label htmlFor="NotSure" className="l-form-group__label">
              Not sure yet
            </label>
          </div>
        </div>
        <Select
          id="SnowAbilityLevel"
          name="SnowAbilityLevel"
          label="Snow Ability Level:"
          onChange={this.onSnowAbilityLevelChanged}
          options={abilityLevels}
          required
        />
        <Select
          id="GearOption"
          name="GearOption"
          label="Ski or Board:"
          onChange={this.onGearOptionsChanged}
          options={gearOptions}
          required
        />
        {saving && (
          <div className="loading-screen">
            <ClipLoader
              sizeUnit="px"
              size={50}
              color="#123abc"
            />
          </div>
        )}
        {!saving && (
          <button type="submit" value="Submit" className="c-button c-button--border">Submit</button>
        )}
      </form>
    );
  }
}

ItineraryDownloadPerTourAfterSnow.propTypes = {
  salesforce: PropTypes.any.isRequired,
  absoluteSlug: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(ItineraryDownloadPerTourAfterSnow);
