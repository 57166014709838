import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery, Link } from 'gatsby';

import { getImageUrl } from 'utilities';
import { Gallery } from 'components';


class DestinationCard extends PureComponent {
  /**
   * Get the gallery slides
   * @param images
   * @returns array
   */
  getGallerySlides(images) {
    const { data } = this.props;

    const slideIDs = [
      data.first_sub_header_first_picture_id,
      data.first_sub_header_second_picture_id,
      data.first_sub_header_third_picture_id,
      data.first_sub_header_fourth_picture_id,
      data.first_sub_header_fifth_picture_id,
    ];

    return slideIDs
      .map(id => ({
        id,
        imageUrl: getImageUrl({
          id,
          images,
          width: 580,
          height: 356,
        }),
      }));
  }


  /**
   * Render
   */
  render() {
    const { data } = this.props;

    if (!data) return null;

    return (
      <StaticQuery
        query={graphql`
          query {
            allDestinationImages {
              edges {
                node {
                  api_id
                  imgix_url
                }
              }
            }
          }
        `}
        render={({ allDestinationImages }) => (
          <div className="c-destination-card">
            <div className="c-destination-card__gallery">
              <Gallery
                slides={this.getGallerySlides(allDestinationImages.edges)}
                showPagination
              />
            </div>

            <div className="c-destination-card__content">
              <h2 className="c-heading c-heading--h2">
                {data.name}
              </h2>
              <p>
                {data.short_description}
              </p>
              { data.has_discover_more ? (
                <Link
                  to={data.absolute_slug}
                  className="c-button c-button--border c-button--wide"
                  role="button"
                >
                  Discover more
                </Link>
              ) : null
              }
            </div>
          </div>
        )}
      />
    );
  }
}

DestinationCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DestinationCard;
