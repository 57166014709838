import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ModalVideo from 'react-modal-video';

import { parseVideo } from 'utilities';
import SVGVideoPlay from '../../../public/svg/video-play.svg';


class VideoEmbed extends PureComponent {
  /**
   * Constructor
   */
  constructor() {
    super();

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      isOpen: false,
    };
  }


  /**
   * Open the video modal
   */
  openModal() {
    this.setState({ isOpen: true });
  }


  /**
   * Close the video modal
   */

  closeModal() {
    this.setState({ isOpen: false });
  }


  /**
   * Render
   */
  render() {
    const { isOpen } = this.state;
    const {
      videoUrl,
    } = this.props;

    const { channel, videoId, previewUrl } = parseVideo(videoUrl);

    return (
      <section className="l-video-embed-intro">
        <div className="l-container">

          <div
            className="l-video-intro__thumbnail"
            style={{ backgroundImage: `url('${previewUrl}')` }}
          >
            <button
              type="button"
              className="c-video-play-button"
              onClick={this.openModal}
            >
              <SVGVideoPlay />
            </button>
          </div>

          <ModalVideo
            channel={channel}
            videoId={videoId}
            isOpen={isOpen}
            onClose={this.closeModal}
          />
        </div>
      </section>
    );
  }
}

VideoEmbed.propTypes = {
  videoUrl: PropTypes.string.isRequired,
};

export default VideoEmbed;
