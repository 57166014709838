import React, { PureComponent } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import { ReviewSummary, Modal } from 'components';
import { ClipLoader } from 'react-spinners';
import {
  TOUR_PAGE_NAV,
} from 'config';
import { GET_REVIEW_DETAILS, GET_IMPORTED_REVIEW_DETAILS } from 'actions/tours';

class GuestReviews extends PureComponent {
  constructor() {
    super();
    this.state = {
      list: [],
      modalOpen: false,
      totalPage: 0,
      currentPage: 1,
      currentPageNext: 0,
      loadingMore: false,
      loading: false,
      totalPageNext: 0,
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.loadMoreItems = this.loadMoreItems.bind(this);
  }

  componentWillMount() {
    const { tourId } = this.props;
    // const { list } = this.state;
    this.setState({
      loading: true,
    }, async () => {
      const IMPORTED_REVIEWS = await GET_IMPORTED_REVIEW_DETAILS(tourId, null, 'most-recent', 1);
      const REVIEWS = await GET_REVIEW_DETAILS(tourId, null, 'most-recent', 1);
      if (REVIEWS) {
        this.setState({
          totalPage: REVIEWS.summary.meta.pages,
          currentPage: REVIEWS.summary.meta.current_page,
          list: REVIEWS.reviews,
        });
      }
      if (IMPORTED_REVIEWS) {
        this.setState({
          totalPageNext: IMPORTED_REVIEWS.summary.meta.pages,
          loading: false,
        });
        if (REVIEWS.reviews.length < 3) {
          const newList = [...REVIEWS.reviews];
          this.setState({ list: newList.concat(IMPORTED_REVIEWS.reviews) });
        }
      }
    });
  }

  openModal() {
    this.setState({
      modalOpen: true,
    });
  }

  loadMoreItems() {
    const { tourId } = this.props;
    const {
      currentPage,
      list,
      totalPage,
      currentPageNext,
    } = this.state;
    this.setState({
      loadingMore: true,
    }, async () => {
      if (currentPage < totalPage) {
        const REVIEWS = await GET_REVIEW_DETAILS(tourId, null, 'most-recent', currentPage + 1);
        if (REVIEWS) {
          this.setState({
            totalPage: REVIEWS.summary.meta.pages,
            currentPage: REVIEWS.summary.meta.current_page,
            loadingMore: false,
          });
          const newList = [...list];
          this.setState({ list: newList.concat(REVIEWS.reviews) });
        }
      } else {
        const IMPORTED_REVIEWS = await GET_IMPORTED_REVIEW_DETAILS(tourId, null, 'most-recent', currentPageNext + 1);
        if (IMPORTED_REVIEWS) {
          this.setState({
            totalPageNext: IMPORTED_REVIEWS.summary.meta.pages,
            currentPageNext: IMPORTED_REVIEWS.summary.meta.current_page,
            loadingMore: false,
          });
          const newList = [...list];
          this.setState({ list: newList.concat(IMPORTED_REVIEWS.reviews) });
        }
      }
    });
  }

  closeModal() {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    const {
      list,
      modalOpen,
      totalPage,
      currentPage,
      loadingMore,
      loading,
      totalPageNext,
      currentPageNext,
    } = this.state;
    const totalReviewPage = totalPage + totalPageNext;
    // remove duplicate reviews
    const newList = list.filter((el, index, self) => (
      index === self.findIndex(t => (
        t.products[0].id === el.products[0].id
      ))
    ));
    return (
      <Element
        name={TOUR_PAGE_NAV.REVIEWS.NAME}
        className="l-tour-addons-upgrades l-tour-page__section"
      >
        <h3 className="c-heading c-heading--h3"> GUEST REVIEWS </h3>
        {!loading && newList.length > 0 && (
          <>
            {newList.slice(0, 3).map(item => (
              <div className="review-section" key={item.products[0].id}>
                <ReviewSummary item={item} />
              </div>
            ))}
            <div className="all-review-button">
              <button
                type="button"
                onClick={this.openModal}
                className="c-button c-button--primary c-button--wide"
              >
                View all reviews
              </button>
            </div>
            {modalOpen && (
              <Modal open={modalOpen} closeHandler={this.closeModal}>
                <div className="modalPage">
                  {newList.map(item => (
                    <div className="review-section" key={item.products[0].id}>
                      <ReviewSummary item={item} />
                    </div>
                  ))}
                  {loadingMore && (
                    <div className="loading-screen">
                      <ClipLoader
                        sizeUnit="px"
                        size={50}
                        color="#123abc"
                      />
                    </div>
                  )}
                  {!loadingMore && list.length > 0 && (currentPage + currentPageNext) < totalReviewPage && (
                    <div className="guest-review-loadMore">
                      <button
                        type="button"
                        className="c-button c-button--border c-button--small"
                        onClick={this.loadMoreItems}
                      >
                        Load More
                      </button>
                    </div>
                  )}
                </div>
              </Modal>
            )}
          </>
        )}
        {!loading && list.length === 0 && (
          <Link
            to="/about-us/reviews/"
          >
            Be the first to review this tour! Click here.
          </Link>
        )}
        {loading && (
          <div className="loading-screen">
            <ClipLoader
              sizeUnit="px"
              size={50}
              color="#123abc"
            />
          </div>
        )}
      </Element>
    );
  }
}

GuestReviews.propTypes = {
  tourId: PropTypes.string.isRequired,
};

export default GuestReviews;
