import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const NoResultsFound = ({ isDate }) => (

  <div>
    <h3>No results found</h3>
    {isDate ? (
      <React.Fragment>
        <p>We couldn’t find exactly what you’re looking for.</p>
        <p>You can try:</p>
        <ul className="u-margin-bottom--2">
          <li>Using a different search term</li>
          <li>Removing or changing your filters</li>
        </ul>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <p>
          Unfortunately we don’t have any dates in that time frame.
          Please check later dates or <Link to="/contact-us">contact the team for more information</Link>
        </p>
      </React.Fragment>
    )}
  </div>
);

NoResultsFound.propTypes = {
  isDate: PropTypes.bool.isRequired,
};

export default NoResultsFound;
