import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';


const OtherTypes = ({ list, title }) => {
  if (!list || !list.length) return null;

  return (
    <section className="l-other-types">
      <div className="l-container">
        <div className="l-other-types__header">
          <h2 className="c-heading c-heading--h2">{title}</h2>
        </div>

        <ul className="l-other-types__list">
          {list.map(item => (
            <li key={item.id}>
              <Link to={item.absolute_slug}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

OtherTypes.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
};

OtherTypes.defaultProps = {
  list: null,
  title: 'Other Activity Types',
};

export default OtherTypes;
