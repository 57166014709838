import React from 'react';
import PropTypes from 'prop-types';

const Banner = ({
  children,
  backgroundImage,
  backgroundPosition,
  modifier,
  topUpLogo,
}) => (
  <section
    className={`c-banner ${modifier}`}
    style={{ backgroundImage: `url('${backgroundImage}')`, backgroundPosition: `${backgroundPosition}` }}
  >
    <div className="l-container">
      <div className="c-banner__inner">
        {children}
      </div>
    </div>
    {topUpLogo && (
      <img className="c-banner__topUpLogo" alt="mons royale merino" src="https://static-hakatours.imgix.net/mons-royale-merino-logo.png?auto=format&fit=crop" />
    )}
  </section>
);

Banner.propTypes = {
  children: PropTypes.node,
  backgroundImage: PropTypes.string,
  backgroundPosition: PropTypes.string,
  topUpLogo: PropTypes.string,
  modifier: PropTypes.string,
};

Banner.defaultProps = {
  children: null,
  backgroundImage: '',
  backgroundPosition: '',
  topUpLogo: null,
  modifier: '',
};

export default Banner;
